<template>
        <TransitionGroup 
                name="fade" 
                tag="ul"
                class="notification-container"
                >
        <div v-for="notification in notifications" :key="notification.id" :class="`notification notification--${notification.type}`">
            <div class="notification__content">
                <i :class="`icon ${notification.type === 'success' ? 'icon-check-circle' : notification.type === 'info' ? 'icon-info-circle' : 'icon-alert-circle'}`"></i>
                <div class="notification__text">
                <div class="notification__title">{{ notification?.title }}</div>
                <div v-if="notification.message" class="notification__message" v-html="notification.message"></div>
                </div>
            </div>
            <button type="button" class="btn btn-toggle" @click="removeNotification(notification.id)">
                <i class="icon icon-close"></i>
                <!-- <svg v-if="notification.type === 'success'"
                  :key="notification.id"
                  class="circular-progress" 
                  width="28"
                  height="28"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke="#fff" 
                    stroke-width="8"
                  />
                  <circle
                    id="progressCircle"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke-width="6"
                    stroke-linecap="round"
                    :style="{ stroke: getCSSVariableValue('--color-success-primary'), strokeDasharray: circleDashArray(notification.id), strokeDashoffset: -188 }"
                  >
                    <animate
                      attributeName="stroke-dasharray"
                      from="0, 251.2"
                      to="251.2, 0"
                      dur="4s" 
                      repeatCount="1"
                      fill="freeze"
                    />
                  </circle>
                </svg> -->
            </button>
        </div>
    </TransitionGroup>
  </template>
  
  <script>
  import { mapState, mapMutations } from 'vuex'
  
  export default {
  name: 'Notifications',
  computed: {
    ...mapState(['notifications']),
    circleDashArray() {
      return (notificationId) => {
        const notification = this.notifications.find(
          (notification) => notification.id === notificationId
        );

        if (!notification) return "0, 251.2";

        const circumference = 2 * Math.PI * 40; // Radius of 40 as defined in the SVG
        const progress = (notification.animationProgress || 0) % 1; // Keep animation progress between 0 and 1
        const dashArray = (1 - progress) * circumference + ", " + progress * circumference;
        return dashArray;
      };
    },
  },
  methods: {
    ...mapMutations(['removeNotification']),
    getCSSVariableValue(variableName) {
      const root = document.documentElement;
      return getComputedStyle(root).getPropertyValue(variableName).trim();
    },
    },
  }
  </script>
  
  <style>
/* 1. declare transition */
.fade-move {
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

/* 3. ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
  </style>
  