<template>
    <div class="right-sidebar__section-item">
        <div class="right-sidebar__section-subtitle">API services</div>

        <div class="dropdown-wrapper">
            <div :class="'dropdown__select-box' 
                        + (componentHasElements ? ' dropdown__select-box--disabled' : '')
                        + (isDropdownActive ? ' js-btn-active' : '')" 
                        @click="toggleDropdown"
            >
                <ul>
                    <li class="dropdown__item--title">
                        {{ selectedApiService?.name }}
                    </li>
                </ul>
                <div><i class="icon icon-chevron-down"></i></div>
            </div>
            <ul v-if="apiServices" class="dropdown__list" :class="{ 'js-show-dropdown': isDropdownActive }">
                <li v-for="service in apiServices" :key="service.id"
                    :class="dropdownItemClass(service)"
                    @click="setSelectedApiService(service)">
                    <ul>
                        <li class="dropdown__item--title">{{ service?.name }}</li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

const API_SERVICES_URL = '/api_services.php'

export default {
    name: 'UserApiServices',
    computed: {
        ...mapState([
            'apiServices', 
            'selectedApiService',
            'selectedUiComponentId', 
            'dropdowns',
            'userUiElements'
        ]),
        isDropdownActive() {
            return this.dropdowns[this.$options.name]
        },
        dropdownItemClass() {
            return (service) => {
            const isSelected = this.isServiceSelected(service.connection_id)
            const isDisabled = this.apiServiceOfSelectedComponent && service.connection_id !== this.apiServiceOfSelectedComponent.connection_id
            return `dropdown__item ${isSelected ? 'dropdown__item--selected' : ''} ${isDisabled ? 'dropdown__item--disabled' : ''}`
            }
        },
        apiServiceOfSelectedComponent() {
            if(!this.userUiElements || !this.userUiElements[this.selectedUiComponentId] || !this.userUiElements[this.selectedUiComponentId][0]) {
                return null
            }
            // console.log(this.userUiElements)
            const connectionId = this.userUiElements[this.selectedUiComponentId][0]['connection_id']
            return this.apiServices.find(service => service.connection_id === connectionId)
        },
        componentHasElements() {
            return this.userUiElements[this.selectedUiComponentId]?.length > 0
        }
    },
    watch: {
        selectedUiComponentId(oldValue, newValue) {
            if(oldValue === newValue) return
            // console.log('selectedUiComponentId changed (call from API services)')
                if (this.apiServiceOfSelectedComponent) {
                    // console.log('this.apiServiceOfSelectedComponent', this.apiServiceOfSelectedComponent)
                    this.setSelectedApiService(this.apiServiceOfSelectedComponent);
                }
        }
        
    },
    methods: {
        isServiceSelected(id) {
            return this.selectedApiService?.connection_id === id
        },
        closeAllDropdowns() {
            for (const key in this.dropdowns) {
                if (key !== this.$options.name) {
                    this.$store.commit('setDropdownState', { id: key, value: false })
                }
            }
        },
        toggleDropdown(event) {
            event.stopPropagation()
            this.closeAllDropdowns()
            const state = this.isDropdownActive
            this.$store.commit('setDropdownState', { id: this.$options.name, value: !state })
            if (!state) {
                document.addEventListener('click', this.closeDropdown)
            }
        },
        closeDropdown() {
            this.$store.commit('setDropdownState', { id: this.$options.name, value: false })
            document.removeEventListener('click', this.closeDropdown)
        },
        setSelectedApiService(service) {
            this.$store.commit('setSelectedApiService', service)
        },
        async fetchApiServices() {
            try {
                const response = await axios.get(API_SERVICES_URL)
                if (response.status === 200) {
                    // console.log(response)
                    this.$store.commit('setApiServices', response.data)

                    // Set the first element as the selectedApiService on initial load
                    if (!this.selectedApiService) {
                        this.$store.commit('setSelectedApiService', this.apiServices[0])
                    }
                }
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data)
                } else {
                    console.error(error)
                }
            }
        },
        async loadAllDependecies() {
                const allDataLoaded = await Promise.all([
                    this.selectedUiComponentId,
                    this.apiServices,
                    this.userUiElements,
                    this.setSelectedApiService
                ].map(data => {
                    if (Array.isArray(data)) {
                        return data.length > 0;
                    } else {
                        return Boolean(data);
                    }
                }));

                if (allDataLoaded.every(Boolean)) {
                    // console.log('allDataLoaded', allDataLoaded)
                    if (this.apiServiceOfSelectedComponent) {
                        // console.log('this.apiServiceOfSelectedComponent', this.apiServiceOfSelectedComponent)
                        this.setSelectedApiService(this.apiServiceOfSelectedComponent);
                        // console.log('this.selectedApiService', this.selectedApiService)
                    }
                }
        }

    },
    created() {
        if (!this.selectedApiService) {
            this.fetchApiServices()
        }
    },
    updated() {
        this.loadAllDependecies()
    }


}

</script>