<template>
    <div class="modal">
        <div class="modal__content modal-width" ref="modalContent">
            <div class="modal__header">
                <h2 class="modal__title">{{ targetPageId ? 'Rename' : 'Create' }} page</h2>
                <button @click="closeModal" type="button" class="btn btn-toggle">
                    <i class="icon icon-close"></i>
                </button>
            </div>

            <form @submit.prevent="handleSubmit">
                <div class="modal__body modal-form">

                    <div :class="{ 'error': fieldValidationMessage.pageName }" class="textfield textfield-large textfield-filled">
                        <div class="textfield-header">
                            <label>Page name</label>
                            <span class="textfield-counter">
                                {{ maxPageNameLength - pageName.length }}
                            </span>
                        </div>
                        <input
                            v-model="pageName"
                            @input="removeMultipleSpaces(pageName), convertToSlug(pageName), cleanSlug()"
                            ref="pageNameInput"
                            @change="validatePageName()"
                            @blur="pageName = pageName.trim()"
                            :maxlength="maxPageNameLength"
                            type="text"
                        >
                        <div  v-if="fieldValidationMessage.pageName" class="error-msg">
                            {{ fieldValidationMessage.pageName }}
                        </div>
                    </div>

                    <div style="display: none;" :class="{ 'error': fieldValidationMessage.slug }" class="textfield textfield-large textfield-filled">
                        <div class="textfield-header">
                            <label>Slug</label>
                            <span class="textfield-counter">
                                {{ maxSlugLenth - slug.length }}
                            </span>
                        </div>
                        <input
                            v-model="slug"
                            @input="convertToSlug(slug)"
                            ref="slugInput"
                            @change="validateSlug()"
                            @blur="cleanSlug()"
                            :maxlength="maxSlugLenth"
                            type="text"
                        >
                        <div v-if="fieldValidationMessage.slug" class="error-msg">
                            {{ fieldValidationMessage.slug }}
                        </div>
                    </div>

                    <div v-if="showAlert" class="alert alert-error">
                        <span>{{ apiErrorMessage }}</span>
                        <button @click="closeAlert()" type="button" class="btn btn-toggle">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                </div>

                <div class="modal__action-buttons">
                    <button @click="closeModal" type="button" class="btn btn-large btn-text-subtle">
                        <span>Cancel</span>
                    </button>
                    <button type="submit" class="btn btn-large btn-text-primary">
                        <span>{{ targetPageId ? 'Save' : 'Create' }} </span>
                    </button>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import modalMixin from '@/mixins/modalMixin.js';

export default {
    name: 'NavigationModal',
    mixins: [modalMixin],
    data() {
        return {
            pageName: '',
            slug: '',
            maxSlugLenth: 20,
            maxPageNameLength: 20,
            fieldValidationMessage: {},
            showAlert: false,
            apiErrorMessage: '',
            targetPageId: this.$store.state.modalTargetPageId
        };
    },
    methods: {
        setSelectedPage(page) {
            this.$store.commit('setSelectedPage', page);
        },
        keydownHandler(event) {
            if (event.key === "Escape") {
                this.closeModal();
            }
        },
        closeModal() {
            this.$emit('closeModalAddRenamePage');
            this.pageName = ''
            this.slug = ''
            this.fieldValidationMessage = {}
            this.showAlert = false
            this.apiErrorMessage = ''
        },
        convertToSlug(str) {
            this.slug = str
                .toString()
                .toLowerCase()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+$/g, '');
        },
        appendSlug() {
            // Generate random string. Temporary solution while Slug is required, should be unique, and not user-editable.
            const randomString = Math.random().toString(36).substring(2, 8);
            this.slug = randomString;
        },
        cleanSlug() {
            this.slug = this.slug.startsWith('-') ? this.slug.slice(1) : this.slug;
            this.slug = this.slug.endsWith('-') ? this.slug.slice(0, -1) : this.slug;
        },
        removeMultipleSpaces(str) {
            this.pageName = str
                .replace(/\s+/g, ' ')
        },
        validatePageName() {
            if (!this.pageName.length) {
                this.fieldValidationMessage.pageName = 'Enter a value';
            } else if (this.pageName.length < 3) {
                this.fieldValidationMessage.pageName = 'Enter minimum 3 characters';
            } else {
                this.fieldValidationMessage.pageName = ''
            }

            if (this.slug.length >= 3) {
                this.fieldValidationMessage.slug = ''
            }
        },
        validateSlug() {
            if (!this.slug.length) {
                this.fieldValidationMessage.slug = 'Enter a value';
            } else if (this.slug.length < 3) {
                this.fieldValidationMessage.slug = 'Enter minimum 3 characters';
            } else {
                this.fieldValidationMessage.slug = ''
            }
        },
        closeAlert() {
            this.showAlert = false
        },
        handleSubmit() {
            this.validatePageName();
            this.appendSlug(); // Temporary solution. See comments from method.
            this.validateSlug();
            if (this.fieldValidationMessage.pageName || this.fieldValidationMessage.slug) {
                return;
            }
            if (this.targetPageId) {
                this.updatePage();
            } else {
                this.createPage();
            }
        },
        async createPage() {
            // console.log('requesting createPage with name: ' + this.pageName + ' and slug: ' + this.slug);
            try {
                const response = await axios.post('/pages.php', {
                    name: this.pageName,
                    slug: this.slug
                }
                );

                if (response.status === 201) {
                    // console.log(response)
                    this.showAlert = false
                    this.closeModal()
                    this.$store.commit('addNotification', { 
                        componentId: 0,
                        type: 'success',
                        title: `Page "${response.data.name}" created`
                    });
                    await this.$store.dispatch('fetchPages')
                    this.setSelectedPage(response.data)
                }

            } catch (error) {
                console.log(error.response);
                this.showAlert = true
                this.apiErrorMessage = error.response.data
            }
        },
        async getPageData() {
            // console.log('requesting getPageData');
            await axios.get('/pages.php?id=' + this.targetPageId)
            .then(response => {
                if (response.status === 200) {
                        // console.log(response)
                        this.pageName = response.data.name
                        this.slug = response.data.slug
                    }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response.data);
                } else {
                    console.error(error);
                }
            });
        },
        async updatePage() {
            try {
                const response = await axios.put('/pages.php', {
                    id: this.targetPageId,
                    name: this.pageName,
                    slug: this.slug
                }
                );

                if (response.status === 200) {
                    console.log('Page updated', response)
                    this.showAlert = false
                    this.closeModal()
                    this.$store.commit('addNotification', { 
                        componentId: 0,
                        type: 'success',
                        title: `Page renamed to "${response.data.name}"`
                    });
                    await this.$store.dispatch('fetchPages')
                    this.setSelectedPage(response.data)
                }

            } catch (error) {
                console.log(error.response);
                this.showAlert = true
                this.apiErrorMessage = error.response.data
            }
        }
    },
    mounted() {
        if (this.targetPageId) {
            this.getPageData();
        }

        this.$nextTick(() => {
            this.$refs.pageNameInput.focus();
        });
        
        this.centerModal();
        window.addEventListener('resize', this.handleResize);  // call mixin method
        window.addEventListener("keydown", this.keydownHandler);

    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);  // call mixin method
        window.removeEventListener("keydown", this.keydownHandler);
    }
};

</script>

<style scoped>

.modal-width {
    width: 288px;
}
.modal-form {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */  /* temporarily disabled due to hidden Slug field */
    /* padding-bottom: 24px; */
}

.error-msg {
    display: block;
}
</style>