<template>
    <div class="right-sidebar__section">
        <div class="right-sidebar__section-header">Components</div>
        <div class="right-sidebar__section-item">
            <ul class="draggable-components">
                <li v-for="(element, index) in uiComponents" :key="element.name" draggable="true" :data-index="index"
                    @dragstart="handleDragStart" class="draggable-components__item">
                    <i :class="element.icon" class="icon"></i>
                    <span>{{ element.name }}</span>
                    <i class="icon icon-drag"></i>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Components',
    data() {
        return {
            uiComponents: [
                {
                    name: 'Data table',
                    type: 'data-table',
                    icon: 'icon-data-table'
                },
                {
                    name: 'Form',
                    type: 'form-item',
                    icon: 'icon-form'
                },
                {
                    name: 'Single item view',
                    type: 'view-entry',
                    icon: 'icon-single-item-view'
                }
            ]
        }
    },
    computed: {
        ...mapState(['userUiComponents', 'selectedUiComponentId'])
    },
    methods: {
        handleDragStart(event) {
            event.dataTransfer.setData('text/plain', event.target.dataset.index);
        }

    }

};

</script>
