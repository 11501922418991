<template>
    <!-- <pre v-if="selectedUiElement">{{ selectedUiElement }}</pre> -->

    <div v-if="selectedUiElement && isCustomizable" class="right-sidebar__section">
        <div class="right-sidebar__section-header">Selected element</div>
        <div class="right-sidebar__section-item">

            <div class="select select-large select-filled">
                <label for="select">Display form element as</label>

                <select 
                        v-if="selectedUiElement.data_type === 'string'" 
                        @change="updateUiElement($event.target.value)"
                    >
                    <option value="textfield" 
                            :selected="selectedUiElement.user_display_format in ['textfield', null]"
                            >Text field
                    </option>
                    <option value="textarea" 
                            :selected="selectedUiElement.user_display_format === 'textarea'"
                            >Text area
                    </option>
                    <option value="rich_editor" 
                            :selected="selectedUiElement.user_display_format === 'rich_editor'"
                            >Rich editor
                    </option>
                </select>

                <select 
                        v-if="selectedUiElement.data_type === 'boolean'" 
                        @change="updateUiElement($event.target.value)"
                    >
                    <option value="switch" 
                            :selected="selectedUiElement.user_display_format in ['switch', null]"
                            >Toggle switch
                    </option>
                    <option value="radio" 
                            :selected="selectedUiElement.user_display_format === 'radio'"
                            >Radio buttons
                    </option>
                    <option value="select" 
                            :selected="selectedUiElement.user_display_format === 'select'"
                            >Dropdown
                    </option>
                </select>

                <select 
                        v-if="selectedUiElement.data_type === 'array'" 
                        @change="updateUiElement($event.target.value)"
                    >
                    <option value="select" 
                            :selected="selectedUiElement.user_display_format in ['select', null]"
                            >Dropdown
                    </option>
                    <option v-if="(selectedUiElement.validation_rule?.max_items >1)" 
                            value="checkbox_group" 
                            :selected="selectedUiElement.user_display_format === 'checkbox_group'"
                            >Checkboxes
                    </option>
                    <option v-if="selectedUiElement.validation_rule?.max_items === 1" 
                            value="radio" 
                            :selected="selectedUiElement.user_display_format === 'radio'"
                            >Radio buttons
                    </option>
                </select>


            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import axios from 'axios'

export default {
    name: 'SelectedElement',
    // data() {
    //     return {
    //         uiComponents: this.userUiElements ? [this.userUiElements[this.selectedUiComponentId] || []
    //     }
    // },
    computed: {
        ...mapState([
            'selectedUiElement',
        ]),
        isCustomizable() {
            const allowedMethods = ['post', 'put', 'patch'];
            const allowedDataTypes = ['string', 'boolean', 'array'];
            const notAllowedOperationTypes = ['input-number'];
            return this.selectedUiElement 
                && allowedMethods.includes(this.selectedUiElement.resource_method)
                && allowedDataTypes.includes(this.selectedUiElement.data_type)
                && !notAllowedOperationTypes.includes(this.selectedUiElement.operation);
        }

    },
    methods:
    {
        updateUiElement(type) {
            this.$store.dispatch('updateUiElement', {
                userDisplayFormat: type
            })
        }
    }
};

</script>