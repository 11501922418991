<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
}
</script>

<style>
@import "@/assets/styles/reset.css";
@import "@/assets/styles/common.css";
</style>