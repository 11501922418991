<template>
    <div class="dropdown-wrapper dropdown--login-as">
        <div :class="'dropdown__select-box' 
                    + (isDropdownActive ? ' js-btn-active' : '')" 
                    @click="toggleDropdown"
        >
            <ul>
                <li class="dropdown__item--title">
                    Login as
                </li>
            </ul>
            <div><i class="icon icon-chevron-down"></i></div>
        </div>
        <ul v-if="filteredUsers" class="dropdown__list" :class="{ 'js-show-dropdown': isDropdownActive }">
            <li v-for="user in filteredUsers" :key="user.id"
            class="dropdown__item "
                @click="handleLoginAs(user.id)">
                <ul>
                    <li class="dropdown__item--title">{{ user.company_name }} ({{ user.username }})</li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import axios from 'axios';

export default {
    name: 'LoginAs',
    data() {
        return {
            usersList: null
        }
    },
    computed: {
        ...mapState([
            'me',
            'dropdowns'
        ]),
        isDropdownActive() {
            return this.dropdowns[this.$options.name]
        },
        filteredUsers() {
            if (!this.usersList) {
                return null;
            }
            return this.usersList.filter(user => user.username !== this.me.username);
        }
    },
    methods: {
        ...mapActions(['loginAsUser']),
        handleLoginAs(userId) {
            this.loginAsUser(userId) 
        },
        toggleDropdown(event) {
            event.stopPropagation()
            if (this.me.role !== 'admin') {
                return;
            }
            this.fetchUsersList();
            const state = this.isDropdownActive
            this.$store.commit('setDropdownState', { id: this.$options.name, value: !state })
            if (!state) {
                document.addEventListener('click', this.closeDropdown)
            }
        },
        closeDropdown() {
            this.$store.commit('setDropdownState', { id: this.$options.name, value: false })
            document.removeEventListener('click', this.closeDropdown)
        },
        async fetchUsersList() {
            if (this.usersList) {
                return;
            }
            try {
                const response = await axios.get('/users.php');
                this.usersList = response.data;
            } catch (error) {
                console.error(error.response);
            }
        }
    }

}
</script>

<style scoped>
.dropdown--login-as {
    position: absolute;
    width: 280px;
    right:120px;
}
</style>