<template>
    <div class="right-sidebar__section">
        <div class="right-sidebar__section-header">Data</div>
        <SubsectionApiServices />
        <SubsectionResources />
        <SubsectionProperties />
    </div>
</template>

<script>
import SubsectionApiServices from './subsections/ApiServices.vue'
import SubsectionResources from './subsections/Resources.vue'
import SubsectionProperties from './subsections/Properties.vue'


export default {
    name: 'Data',
    components: {
        SubsectionApiServices,
        SubsectionResources,
        SubsectionProperties,
    }
}

</script>