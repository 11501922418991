<template v-if="elementsCurrentComponent && componentData"><!-- <div v-if="selectedUiElement">{{ selectedUiElement.id }}</div> -->

<!-- <pre style="height: 200px; overflow-y: scroll;">externalApiLookupData:  -->
    <!-- {{ externalApiLookupData[2958] }} -->
    <!-- <pre v-for="(value, key) in externalApiLookupData" :key="key">{{ key }}</pre> -->
<!-- </pre> -->
<!-- <pre>componentData: {{ componentData }}</pre> -->
<!-- <pre>totalPages: {{ totalPages }}</pre> -->
<!-- <pre>selectedElementId: {{ selectedElementId }}</pre> -->
<!-- <pre style="height: 150px; overflow-y: scroll;">nestedInComponent:
    {{ nestedInComponent }}
</pre>  -->
<!-- <pre style="height: 200px; overflow-y: scroll;">binding:
    {{ binding }}
</pre> -->
<!-- <pre style="height: 200px; overflow-y: scroll;">deleteExternalApiEntryItem:
    {{ deleteExternalApiEntryItem }}
</pre> -->
<!-- <pre style="height: 200px; overflow-y: scroll;">tableRowBindingView:
    {{ tableRowBindingView }}
</pre> -->

<!-- <pre>isLoadingExternalData: {{ isLoadingExternalData }}</pre> -->
<!-- <pre>isLoadingExternalApiLookup: {{ isLoadingExternalApiLookup }}</pre> -->

<!-- <pre style="height: 200px; overflow-y: scroll;">externalApiData:
    {{ externalApiData }}
</pre>  -->
<!-- <pre style="height: 200px; overflow-y: scroll;">tableData:
    {{ tableData }}
</pre>  -->

<!-- <pre>paginationProperties: {{ paginationProperties }}</pre>
<pre>filterParams: {{ filterParams }}</pre> -->
<!-- <pre>formNewValues: {{ formNewValues }}</pre> -->
<!-- <pre>formValidationErrors: {{ formValidationErrors }}</pre> -->
<!-- <pre>showModalPropertyDetails: {{ showModalPropertyDetails }}</pre> -->
<!-- <pre>modalData: {{ modalData }}</pre> -->
<!-- <pre>modalComponentData: {{ modalComponentData }}</pre> -->
<!-- <pre>maxHeight: {{ maxHeight }}</pre> -->
<!-- <div v-if="tableColumnPurposeElements">tableColumnPurposeElements: 
    <pre v-for="(element, index) in tableColumnPurposeElements" :key="index">{{ element.id }}</pre>
</div> -->
<!-- <pre>selectedRowIndex: {{ selectedRowIndex }}</pre> -->
<!-- <pre>{{ tableColumnPurposeElements }}</pre> -->
<!-- <pre v-if="elementsCurrentComponent">{{ elementsCurrentComponent }}</pre> -->
<!-- <pre>externalApiData:  {{ externalApiData }}</pre> -->
<!-- <pre v-if="externalApiHeaders">{{ externalApiHeaders }}</pre> -->
<!-- <pre>linkedComponentFormEdit: {{ linkedComponentFormEdit }}</pre> -->
<!-- <pre>modalViewEnabled: {{ linkedComponentFormEdit?.settings?.modalViewEnabled }}</pre> -->
<!-- <pre>userUiElements: {{ userUiElements }}</pre> -->
<!-- <pre>userUiComponents: {{ userUiComponents }}</pre> -->
<!-- <pre>deleteEntryWithResource: {{ deleteEntryWithResource }}</pre> -->
<!-- <pre>deleteEntryWithProperty: {{ deleteEntryWithProperty }}</pre> -->
<!-- <pre v-if="userApiProperties">{{ userApiProperties }}</pre> -->
<!-- <pre>resourceId: {{ resourceId }}</pre> -->
<!-- <pre>userApiResources: {{ userApiResources }}</pre> -->
<!-- <pre>selectedApiService {{ selectedApiService }}</pre> -->
<!-- <pre>selectedUiElement.id: {{ selectedUiElement?.id  }}</pre> -->
<!-- <pre>prevPageNumber: {{ prevPageNumber  }}</pre>
<pre>currentPageNumber: {{ currentPageNumber  }}</pre>
<pre>firstPageNumber: {{ firstPageNumber  }}</pre>
<pre>nextPageNumber: {{ nextPageNumber  }}</pre>
<pre>displayedItemsPerPage: {{ displayedItemsPerPage  }}</pre>
<pre>totalItems: {{ totalItems  }}</pre>
<pre>lastPageNumber: {{ lastPageNumber  }}</pre>
<pre>totalPages: {{ totalPages  }}</pre> -->
<!-- <pre v-show="filterPurposeElements">{{ filterPurposeElements  }}</pre> -->
<!-- <pre>paginationPurposeElements: {{ paginationPurposeElements  }}</pre> -->
<!-- <pre>{{ filterParams  }}</pre> -->
<!-- <pre>isInternalMethod: {{ isInternalMethod  }}</pre> -->
<!-- <pre>refreshExternalDataInComponent: {{ refreshExternalDataInComponent  }}</pre> -->
<!-- <pre>isDeletingExternalApiEntry: {{ isDeletingExternalApiEntry  }}</pre> -->

<!-- Data table filters -->
<div v-if="filterPurposeElements.length > 0" :class="(parentNotSelected ? 'disabled' : '')">

    <draggable 
        v-model="filterPurposeElements" 
        ghost-class="ghost" 
        animation="300" 
        filter=".js-no-drag"
        @change="handleReorder"
        :disabled="!editMode" 
        class="table-columns data-table__filters"
        >
        <transition-group type="transition" name="flip-list">

            <div v-for="element in filterPurposeElements" :key="element.id"
                @click="handleItemSelection($event, element)"
                :class="'js-data-table-filters-item '
                    + (element.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : 'js-no-drag')
                    + (element.data_type === 'string' ? ' textfield textfield-small textfield--filter textfield-outlined js-data-table-filters-item' + (isFilterActive(element) ? ' textfield--active' : '') : '')
                    + (element.operation === 'search' ? ' textfield-search textfield-search--fixed-width' : '')
                    + (['input-date', 'input-number'].includes(element.operation) ? ' textfield textfield-small textfield-outlined js-data-table-filters-item textfield-date' : '')
                    + (['select', 'boolean'].includes(element.operation) ? ' select select-small select-outlined' : '')"
                    >

                <div v-if="element.operation !== 'multi-select'" class="textfield-header">
                    <label for="select" :title="element?.description">{{ element.display_name }} {{ element.id }}</label>
                </div>

                <div v-if="element.operation === 'input-number'" class="data-table__filters-field">
                    <input type="text" 
                           v-maska 
                           :data-maska="getDataMaska(element)"
                           data-maska-tokens="-:-:optional|0:\d:multiple|9:\d:optional"
                           @change="handleInput(element)" 
                           v-model="filterParams[element.property_path]"
                           :placeholder="element.example_value"
                           :ref="'filterInputNumber-' + element.id"
                    >
                    <div v-if="isFilterActive(element)">
                        <button @click="clearFilter(element)" class="textfield__btn btn clear-filter btn-toggle">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                    <div class="error-msg js-hide-input-error">Error message</div>
                </div>

                <div v-else-if="element.operation === 'input-date'" class="data-table__filters-field">
                    <input type="text"
                           v-maska 
                           :data-maska="getDataMaska(element) || '####-##-##'" 
                           data-maska-eager
                           @change="updateFormValues($event.target.value, element); handleFilterChange($event.target.value, element)" 
                           :placeholder="element.validation_rule?.user_input_format ? element.validation_rule?.user_input_format.toLowerCase() : 'yyyy-mm-dd'"
                           v-model="formNewValues[element.id]"
                           :ref="'filterInputDate-' + element.id"
                    >
                    <div v-if="isFilterActive(element)">
                        <button @click="clearFilter(element)" class="textfield__btn btn clear-filter btn-toggle">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                    <div class="error-msg js-hide-input-error">Error message</div>
                </div>

                <div v-else-if="element.data_type === 'string'" class="data-table__filters-field">
                    <input 
                        @change="handleInput(element)" 
                        v-model="filterParams[element.property_path]"
                        :placeholder="element.example_value"
                        type="text"
                        :ref="'filterInputText-' + element.id"
                    >
                    <div v-if="isFilterActive(element)">
                        <button @click="clearFilter(element)" class="textfield__btn btn clear-filter btn-toggle">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                    <div class="error-msg js-hide-input-error">Error message</div>
                </div>

                <div v-else-if="element.operation === 'select'">
                    <select name="select" class="select--filter" @change="handleFilterChange($event.target.value, element)" :ref="'filterInputSelect-' + element.id">
                        <option v-for="(option, index) in element.validation_rule?.enum" :key="index" :value="index"
                            :selected="index === element.validation_rule?.default_value">{{ option }}
                        </option>
                    </select>
                    <div class="error-msg js-hide-input-error">Error message</div>
                </div>

                <div v-else-if="element.operation === 'boolean'">
                    <select name="select" class="select--filter" @change="handleFilterChange($event.target.value, element)" :ref="'filterInputSelect-' + element.id">
                        <option value="1" :selected="element.default_value === 1">Yes</option>
                        <option value="0" :selected="element.default_value === 0 || !element.default_value">No</option>
                    </select>
                    <div class="error-msg js-hide-input-error">Error message</div>
                </div>


                 <!-- Multiselect -->
                <div v-else-if="element.operation === 'multi-select'" class="form-components__item js-form-components-item">
                    <div :getCurrValues="currValues = setFormValue(element)" 
                         :getPlaceholder = "placeholder = getMultiselectPlaceholder(element, currValues)"
                         :getType="componentType = element.validation_rule?.max_items > 1 ? 'checkbox' : 'radio'"
                         class="multiselect multiselect-small multiselect-wrapper"
                         :class="' multiselect-wrapper__width--' + (element.validation_rule?.width || 'stretch') 
                                + (formValidationErrors[element.id] ? ' error' : '') 
                                + (isFilterActive(element) ? ' multiselect--active' : '')
                                "
                         :ref="'filterMultiSelect-' + element.id"
                    >
                        <div class="multiselect__label" :title="element?.description">
                            {{ element.display_name }} {{ element.id }}
                        </div>
                        <div @click="toggleMultiselectDropdown($event, element)" 
                            class="multiselect__select-box"
                            :class="{'js-btn-active': multiselectDrodpownOpen === element.id}"
                        >
                            <div class="multiselect__select-value" 
                                :class="['Select', 'Loading...'].includes(placeholder) ? 'multiselect__no-selection' : ''"
                            >
                                    {{ placeholder }}
                            </div>
                            <div v-if="isFilterActive(element)">
                                <button @click="clearFilter(element)" class="textfield__btn btn clear-filter btn-toggle">
                                    <i class="icon icon-close"></i>
                                </button>
                            </div>
                            <div v-else><i class="icon icon-chevron-down"></i></div>
                        </div>
                        <div v-if="multiselectDrodpownOpen === element.id" class="multiselect__menu">
                            <div v-if="shouldDisplaySearchBar(element)" class="multiselect__search">
                                <div class="multiselect__textfield textfield textfield-small textfield-outlined textfield-search">
                                    <button @click="multiselectFilterSearchTerm[element.id] = ''" class="textfield__btn btn btn-toggle">
                                        <i class="icon icon-close"></i>
                                    </button>
                                    <input
                                    placeholder="Search" type="text" v-model="multiselectFilterSearchTerm[element.id]" @click="selectAllInputText($event); multiselectFilterSearchTerm[element.id] = ''">
                                </div>
                            </div>
                            <div v-if="(element.validation_rule?.show_select_controls || element.validation_rule?.show_display_controls) && element.validation_rule?.max_items > 1" class="multiselect__ops">
                                <div class="multiselect__ops--controls">
                                    <div v-if="element.validation_rule?.show_select_controls" class="multiselect__ops--links">
                                        <a @click="selectAllOptionsMultiselect(element)">Select all</a>
                                        <span class="multiselect__ops-block--sep">|</span>
                                        <a @click="clearAllOptionsMultiselect(element)">Clear all</a>
                                    </div>
                                    <div v-if="element.validation_rule?.show_display_controls 
                                                && formNewValues[element.id] 
                                                && formNewValues[element.id].length" 
                                                class="multiselect__ops--block"
                                    >
                                        <div class="multiselect__ops--links">
                                            <a @click="multiselectDisplaySelectedOnly[element.id] = false"
                                                :class="{'multiselect__ops--links-active': !multiselectDisplaySelectedOnly[element.id]}"
                                            >Show all</a>
                                            <span class="multiselect__ops-block--sep">|</span>
                                            <a @click="multiselectDisplaySelectedOnly[element.id] = true"
                                                :class="{'multiselect__ops--links-active': multiselectDisplaySelectedOnly[element.id]}"
                                            >
                                                Selected 
                                                ({{ formNewValues[element.id] && formNewValues[element.id].length || 0 }})
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else></div>
                                </div>
                            </div>
                            <div v-if="element.validation_rule?.hierarchical && !multiselectHasNoResults(element)" class="multiselect__list__curr-path">
                                {{ currentPath || (element.validation_rule?.none_option?.name || (element.validation_rule?.max_items === 1 ? 'None' : '') )  }}
                            </div>
                            <div class="multiselect__list" :get-items="items = filterLookupItems(element)">
                                    <div v-if="element.validation_rule?.none_option && !multiselectHasNoResults(element)  && !this.multiselectFilterSearchTerm[element.id]" 
                                        :class="componentType + ' multiselect__item'"
                                        @click="handleFilterChange($event.target.value, element); removeElementFromFormValues(element); closeDropdown()" 
                                        @mouseover="element.validation_rule?.hierarchical ? delayedCall(() => currentPath = (element.validation_rule?.none_option?.name || 'None' ), 350) : ''"
                                        > 
                                        <input :type="componentType" 
                                            :id="element.id + '-unselected'" 
                                            :name="element.id" 
                                            :value="element.validation_rule?.none_option?.value || ''"
                                            :checked="!formNewValues[element.id] || formNewValues[element.id]?.length === 0">
                                        <label :for="element.id + '-unselected'" @click.stop>{{ element.validation_rule?.none_option?.name || 'None' }}</label>
                                    </div>
                                    <div v-for="(item, index) in items" 
                                                :key="index" 
                                                :class="componentType + ' multiselect__item'"
                                                @click="updateFormValues(getMultiselectItemValue(item, index, element), element, item); validateFormInput(element); handleFilterChange(getMultiselectItemValue(item, index, element), element); element.validation_rule?.max_items === 1 ? closeDropdown() : '' "


                                                
                                    >
                                        <input :type="componentType" 
                                            :id="element.id + '-' + index" 
                                            :name="element.id" 
                                            :value="getMultiselectItemValue(item, index, element)"
                                            :checked="checkIsValueSelected(item, index, element, currValues)"
                                            :style="{ 'margin-left': item.depth ? (item.depth * 8) + 'px' : '0px'}"
                                        >
                                        <label :for="element.id + '-' + index" @click.stop>{{ getDisplayFormat(element, item) }}</label>
                                    </div>
                                
                                <div v-if="this.isLoadingExternalApiLookup[element.id]" class="multiselect__list--empty-state">
                                    Loading...
                                </div>
                                <div v-else-if="multiselectHasNoResults(element)" class="multiselect__list--empty-state">
                                    No results
                                </div>
                            </div>
                        </div>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                </div>
                <!-- / Multiselect -->
            </div>
            <div class="data-table__filters--btn-reset-wrapper">
                <button 
                    v-if="isAnyFilterActive()" 
                    @click="clearAllFilters()" 
                    type="button" 
                    class="btn btn-small btn-text-primary"
                >
                    <span>Reset filters</span>
                </button>
            </div>
        </transition-group>
    </draggable>

</div>
<!-- / Data table filter -->

<!-- Data table -->
<div v-if="tableColumnPurposeElements.length > 0" class="data-table__wrapper--shadows">
    <div :id="componentData ? 'data-table-wrapper-' + componentData.id : ''"
        @scroll="handleScrollable"
        class="data-table__wrapper js-data-table-wrapper">
        <div class="data-table__wrapper--shadow-left js-shadow-left"></div>
        <div class="data-table__wrapper--shadow-right js-shadow-right"></div>
        <div class="data-table js-data-table">
            <draggable v-model="tableColumnPurposeElements" ghost-class="ghost" animation="300" filter=".js-no-drag"
                        :disabled="!editMode" @change="handleReorder"
                        class="data-table__row data-table__head js-data-table-head"
                        :move="handleMove"
                        >
                <transition-group type="transition" name="flip-list" >
                    <div v-for="(element, index) in tableColumnPurposeElements" scope="col"
                        :key="index" class="data-table__cell"  
                        :class="(element.id === selectedUiElement?.id ? 'js-data-table-col-selected' : 'js-no-drag')"
                        @click="handleItemSelection($event, element)"
                        :title="element?.description"
                    > 
                    {{ element.display_name || element.property_path }} {{ element.id }}
                    </div>
                    <div v-if="linkedComponentFormEdit || componentSettings?.deleteEntryButtonEabled" class="data-table__cell js-no-drag"></div>
                </transition-group>
            </draggable>
            
            <div v-for="(row, index) in tableData" 
                :key="index" 
                @click="handleRowSelection($event, row, index, 'view')"
                @dblclick="linkedComponentSingleItemView?.settings?.modalViewEnabled ? $store.commit('setModalComponentData', linkedComponentSingleItemView) : ''"
                v-no-auto-select="modalViewEnabled"
                @keyup.enter="linkedComponentSingleItemView?.settings?.modalViewEnabled ? $store.commit('setModalComponentData', linkedComponentSingleItemView) : ''"
                :class="'data-table__row' + (index === selectedRowIndex ? ' js-data-table-cell-selected' : '')"
                :id = "componentData ? 'row-' + componentData.id + '-'  + index : ''"
                :ref="'row-' + componentData.id + '-'  + index"
            >
                
                <div v-for="(element, index) in tableColumnPurposeElements" :key="'cell-' + index"  class="data-table__cell">
                    <template v-if="externalApiData && !isLoadingExternalData">
                        <div v-if="element.data_type === 'object-array'" :get-values="valuesArray = getPropertyTypeArrayValue(row, element.property_path) ">
                            <span v-if="Array.isArray(valuesArray) && valuesArray.length && areAllValuesInArrayNotEmpty(valuesArray)" 
                                :get-preceded-value = "preceededValue = element?.validation_rule?.preceded_property_path ? getPropertyTypeArrayValue(row, element?.validation_rule?.preceded_property_path) : null"
                                >
                                <span v-for="(valueLevel1, index) in valuesArray" :key="index">
                                    <span v-if="preceededValue" class="data-table__cell--preced-value">
                                            {{ Array.isArray(preceededValue) ? preceededValue[index] + ': ' : '' }}
                                    </span>
                                    <span v-if="Array.isArray(valueLevel1)">
                                        <span v-for="(valueLevel2, index) in valueLevel1" :key="index" :get-value = "value = getHtmlElement(element?.validation_rule, valueLevel2)">
                                            <span v-if="value" v-html="value"></span>
                                            <span v-if="value && index < valueLevel1.length - 1" v-html="getDisplayListSeparator(element)"></span>
                                        </span><br />
                                    </span>
                                    <span v-else :get-value = "value = getHtmlElement(element?.validation_rule, valueLevel1)">
                                        <span v-if="value && element?.validation_rule?.format === 'image'" 
                                                @click="openModalPropertyDetails('image-gallery', element, getPropertyTypeArrayValue(row, element.property_path), index)" 
                                                v-html="value" 
                                                style="display:inline-block;"></span>
                                        <span v-else-if="shouldDisplayValue(value)" v-html="value" style="display:inline-block;"></span>
                                        <span v-if="shouldDisplayValue(value) && index < valuesArray.length - 1" v-html="getDisplayListSeparator(element)"></span>
                                    </span>
                                </span>
                            </span>
                            <span v-else class="data-table__cell--empty">-</span>
                        </div>
                        <div v-else-if="Array.isArray(row[element.property_path])">
                            <span v-if="row[element.property_path].length" 
                                @click="openModalPropertyDetails('object-list', element, getPropData(element, row, 'return-objects'))" 
                                class="link-open-modal"
                            > 
                                {{ row[element.property_path].length }} {{ row[element.property_path].length === 1 ? 'item' : 'items' }} 
                            </span>
                            <span v-else class="data-table__cell--empty">-</span>
                        </div>

                        <!-- Render object type data-->
                        <div v-else-if="element.data_type === 'object' && row[element.property_path] &&  Object.keys(row[element.property_path]).length">
                            <template v-if="!areAllValuesInObjectEmpty(row[element.property_path])">
                                <template v-for="(value, key, index) in row[element.property_path]" :key="key">
                                    <span v-if="typeof getPropertyValueFromObject(element, key) === 'object'">
                                        <span v-if="name = getPropertyValueFromObject(element, key)?.name" class="data-table__cell--index-value">{{ name }}: </span> 
                                        <span v-if="value">
                                            <span v-if="getPropertyValueFromObject(element, key)?.format === 'image'" 
                                                @click="openModalPropertyDetails('image-gallery', element, value)" 
                                                v-html="getHtmlElement(getPropertyValueFromObject(element, key), value)"></span>
                                            <span v-else v-html="getHtmlElement(getPropertyValueFromObject(element, key), value)"></span>
                                        </span>
                                        <span v-else class="data-table__cell--empty">-</span>
                                        <span v-if="index < Object.keys(row[element.property_path]).length - 1"><br /></span>
                                    </span>
                                    
                                    <!-- Remove this block and change validation_rule in all 'object' type elements-->
                                    <!-- <span v-else-if="value 
                                        && typeof getPropertyValueFromObject(element, key) !== 'object'
                                        && (formattedKey = getPropertyValueFromObject(element, key))"
                                         class="single-item-view__item__value__content__object"
                                    >
                                        <span class="data-table__cell--index-value">{{ formattedKey }}:</span> 
                                        {{ value }}
                                        <span v-if="index < Object.keys(row[element.property_path]).length - 1"><br /></span>
                                    </span> -->
                                    <!-- / Remove this block -->

                                </template>
                            </template>
                            <span v-else class="data-table__cell--empty">-</span>
                        </div>
                        <!-- / Render object type data -->

                        <!-- <span v-if="getPropertyValue(element, null, row)?.format === 'image'" 
                                @click="openModalPropertyDetails('image-gallery', element, row)" 
                                v-html="getHtmlElement(getPropertyValue(element, null, row))"></span>
                            <span v-else v-html="getHtmlElement(getPropertyValue(element, null, row))"></span> -->

                            
                        <div v-else-if="element.validation_rule?.format === 'image'"
                            @click="openModalPropertyDetails('image-gallery', element, getPropertyValue(element, null, row))" 
                            v-html="getHtmlElement(element.validation_rule, getPropertyValue(element, null, row))"
                        ></div>
                        <div v-else-if="shouldDisplayValue(getPropertyValue(element, null, row))" v-html="getHtmlElement(element.validation_rule, getPropertyValue(element, null, row))"></div>
                        <div v-else class="data-table__cell--empty">-</div>
                    </template>
                    <template v-else-if="!externalApiData || isLoadingExternalData">
                        <div class="data-table__placeholder data-table__placeholder--loading">
                            <div class="shimmer"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="data-table__placeholder"></div>
                    </template>
                </div>


                <div v-if="linkedComponentFormEdit || componentSettings?.deleteEntryButtonEabled"
                    :key="index" 
                    @click="handleRowSelection($event, row, index, 'view')"
                    :class="'data-table__cell data-table__cell--actions ' + (index === selectedRowIndex ? ' js-data-table-cell-selected' : '')"
                    :id = "componentData ? 'row-' + componentData.id + '-'  + index : ''"
                    :ref="componentData ? 'row-' + componentData.id + '-' + index : null"
                >
                    <template v-if="tableData?.length > 0">
                        <b class="data-table__cell--buttons">
                            <button v-if="linkedComponentFormEdit && linkedComponentFormEdit?.settings?.modalViewEnabled" 
                                    @click.stop="handleRowSelection($event, row, index, 'edit'); $store.commit('setModalComponentData', linkedComponentFormEdit)" 
                                    type="button" 
                                    class="btn btn-table-action">
                                <i class="icon icon-rename"></i>
                            </button>
                            <button v-else-if="linkedComponentFormEdit" 
                                    @click.stop="handleRowSelection($event, row, index, 'edit')" 
                                    type="button" 
                                    class="btn btn-table-action">
                                <i class="icon icon-rename"></i>
                            </button>
                            <button v-if="componentSettings?.deleteEntryButtonEabled" @click.stop="handleDeleteApiEntry($event, row)" type="button" class="btn btn-table-action">
                                <i class="icon icon-trash"></i>
                            </button>
                        </b>
                    </template>
                    <template v-else-if="!tableData || isLoadingExternalData">
                        <div  class="data-table__placeholder data-table__placeholder--loading">
                            <div class="shimmer"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="data-table__placeholder"></div>
                    </template>
                </div>

            </div>
        </div>

         <div v-if="!selectedElementId && !editMode && componentData?.linked_component_id" class="card__empty">
            <div class="card__empty__first-row"> 
                <span>Click on a row in</span>
                <b>{{ this.sourceComponentTitle }}</b>
                <span>table to load corresponding items here</span>
            </div>
        </div>
        <div v-else-if="!tableData?.length && !isLoadingExternalData" class="data-table__emptystate">
            No entries
         </div>

    </div>
</div>
<!--/Data table-->

<!-- Pagination -->
<div v-if="tableData?.length && paginationPurposeElements.length " class="data-table__pagination">
    <div v-if="displayedItemRange" class="data-table__pagination--left">
        Items {{ displayedItemRange }}
        <span v-if="elementTotalItems = getPaginationElementByOperation('total_items')"
            @click="handleItemSelection($event, elementTotalItems)"
            :class="'js-pagination-item' + (elementTotalItems.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : ' js-no-drag')">of
            {{ totalItems ? totalItems : "..." }}</span>
    </div>

    <div class="data-table__pagination--center">

        <div v-if="elementPaginate = getPaginationElementByOperation('paginate')"
            @click="handleItemSelection($event, elementPaginate)"
            :class="'data-table__pagination--wrapper js-pagination-item' + (elementPaginate.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : ' js-no-drag')">
            <button type="button" class="btn btn-small btn-contained-subtle"
                @click="handlePagination(elementPaginate, firstPageNumber, 'first')" :disabled="currentPageNumber === firstPageNumber">
                <i class="icon icon-chevron-double-left"></i>
            </button>
            <button type="button" class="btn btn-small btn-contained-subtle"
                @click="handlePagination(elementPaginate, prevPageNumber, 'prev')" :disabled="currentPageNumber === firstPageNumber">
                <i class="icon icon-chevron-left"></i>
            </button>
        </div>

        <div class="data-table__pagination--wrapper">
            <div v-if="elementPaginate = getPaginationElementByOperation('paginate')"
                @click="handleItemSelection($event, elementPaginate)"
                :class="'js-pagination-item' + (elementPaginate.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : ' js-no-drag')">
                <div>Page</div>
                <div v-if="elementPaginate?.validation_rule?.jump_page !== null" class="textfield textfield-small textfield-outlined">
                    <input type="text" class="textfield-pagination" v-model="currentPageNumberDisplay" v-maska data-maska="####" 
                        @change="handlePagination(elementPaginate, $event.target.value, 'jump')" @click="selectInputText($event)">
                </div>
                <div v-else>{{ currentPageNumberDisplay }}</div>
            </div>
            <div v-if="elementTotalPages = getPaginationElementByOperation('total_pages')"
                @click="handleItemSelection($event, elementTotalPages)"
                :class="'js-pagination-item' + (elementTotalPages.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : ' js-no-drag')">
                <span v-if="elementPaginate">of</span>
                <span v-else>Pages</span> {{ totalPages ? totalPages : "..." }}
            </div>
        </div>

        <div v-if="elementPaginate = getPaginationElementByOperation('paginate')"
            @click="handleItemSelection($event, elementPaginate)"
            :class="'data-table__pagination--wrapper js-pagination-item' + (elementPaginate.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : ' js-no-drag')">
            <button type="button" class="btn btn-small btn-contained-subtle"
                @click="handlePagination(elementPaginate, nextPageNumber, 'next')"
                :disabled="!nextPageNumber || currentPageNumber === lastPageNumber">
                <i class="icon icon-chevron-right"></i>
            </button>
            <button v-if="lastPageNumber" type="button" class="btn btn-small btn-contained-subtle"
                @click="handlePagination(elementPaginate, lastPageNumber, 'last')"
                :disabled="currentPageNumber === lastPageNumber">
                <i class="icon icon-chevron-double-right"></i>
            </button>
        </div>

    </div>

    <div v-if="elementItemsPerPage = getPaginationElementByOperation('items_per_page')"
        @click="handleItemSelection($event, elementItemsPerPage)"
        :class="'data-table__pagination--right js-pagination-item' + (elementItemsPerPage.id === selectedUiElement?.id ? ' js-data-table-filter-selected' : ' js-no-drag')">
        <div>Items per page</div>
        <div class="select select-small select-outlined">
            <select name="select" @change="handleFilterChange($event.target.value, elementItemsPerPage)">
                <option v-for="(option, index) in elementItemsPerPage?.validation_rule?.enum" :key="index" :value="option"
                    :selected="option === displayedItemsPerPage">{{ option }}
                </option>
            </select>
        </div>
    </div>
    
</div>
<!-- /Pagination -->

<ModalPropertyDetails v-if="showModalPropertyDetails[modalData?.element?.id]" 
    @closeModalPropertyDetails="this.$store.commit('setShowModalPropertyDetails', {elementId: modalData?.element?.id, stateValue: false} );" 
    :modal-data="modalData" 
/>

<ModalConfirmAction 
    v-if="openModalConfirmDeleteEntry" 
    :modal-content=" {
       'title': 'Delete entry?',
        // 'text': 'Are you sure you want to delete this entry?',
        'confirmText': 'Delete',
        'cancelText': 'Cancel'
    }" 
    @confirm-action="(answer) => confirmDeleteApiEntry = answer" 
/>

</template>

<script>
import { mapState } from 'vuex'
import { VueDraggableNext } from 'vue-draggable-next'
// import debounce from 'lodash.debounce';
import axios from 'axios'
import GenericMixin from '@/mixins/genericMixin.js'
import FormUtilsMixin from '@/mixins/formUtilsMixin.js'
import TextFormattingMixin from '@/mixins/textFormattingMixin.js'
import ModalPropertyDetails from '@/components/PageContent/modals/PropertyDetailsModal.vue'
import ModalConfirmAction from '@/components/PageContent/modals/ConfirmActionModal.vue'

// const API_SERVICES_URL = '/api_services.php'

export default {
    name: 'CardDataTable',
    mixins: [GenericMixin, FormUtilsMixin, TextFormattingMixin],
    components: {
        draggable: VueDraggableNext,
        ModalPropertyDetails,
        ModalConfirmAction,
    },
    props: {
        componentData: {
            type: Object,
            required: true
        }
    },
    directives: {
        'no-auto-select': {
            mounted(el, binding) {
                el.addEventListener('mousedown', (event) => {
                if (event.detail > 1 && binding.value) {
                    event.preventDefault();
                }
                });
            }
        }
    },
    data() {
        return {
            externalApiData: null,
            externalApiHeaders: null,
            filterParams: {},
            modalData: {
                title: null,
                contentType: null,
                currItemIndex: null,
                data: null,
                element: null,
                component: null
            },
            selectedRowIndex: null,
            selectedElementId: null,
            isInternalMethod: false,
            confirmDeleteApiEntry: null,
            deleteExternalApiEntryItem: null,
            openModalConfirmDeleteEntry: false,
            typingTimer: null,
            currentPath: '',
            isLoadingExternalData: false,
            modalViewEnabled: false

        }
    },
    watch: {
        editMode: {
            immediate: true,
            handler: function (newVal) {
                if (!newVal) {
                    this.$store.commit('setSelectedUiElement', null);
                    document.removeEventListener('keydown', this.handleDeleteKeyDown);
                    document.removeEventListener('click', this.handleOutsideClick);
                    document.removeEventListener('resize', this.handleScrollable);
                }
            }
        },
        confirmDeleteApiEntry(newVal) {
                if (newVal === true) {
                    this.deleteExternalApiEntry();
                }
            this.confirmDeleteApiEntry = null;
            this.deleteExternalApiEntryItem = null;
            this.openModalConfirmDeleteEntry = false;
        },
        elementsCurrentComponent(newVal) {
            if (newVal.length > 0) {
                // If the table had to load data based on a binding, then the exeternalApiData will be set to empty to avoid displaying of loaders on page load before an item from parent component is selected
                if(this.componentData?.link_with_property_path) {
                    this.externalApiData = [];
                    return;
                }
                this.fetchDataFromExternalApiList();
            }
        },
        refreshExternalDataInComponent(newVal) {
            if (newVal && newVal[this.componentData.id] && !this.isInternalMethod) {
            this.fetchDataFromExternalApiList();
            }
        },
        connectionId: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.fetchUserApiResources();
                }
            }
        },
        userUiElements: {
            handler() {
            // trigger the recomputation of linkedComponentFormEdit when userUiElements is loaded
            this.linkedComponentFormEdit;
            },
            immediate: true // compute the linkedComponentFormEdit property immediately when the component is mounted
        },
        binding: {
            immediate: true,
            handler: function () {
                // console.log('SingleItemView: tableRowBindingView changed')
                if (this.binding) {
                    // Assiging temporary data albeit incomplete from binding until external api data is fetched, though add exception to this rule if the component is linked to a property
                    if(!this.componentData?.link_with_property_path) {
                        this.externalApiData = this.binding.linkedElementData;
                    }

                    // console.log('SingleItemView: tableRowBindingView changed: binding found')
                    // console.log('this.externalApiData', this.externalApiData)
                    
                    this.selectedElementId = this.binding.linkedElementData[this.componentData?.link_with_property_path];
                    // console.log('this.selectedElementId', this.selectedElementId)  
                } 
            },
            deep: true
        },
        selectedElementId() {
            this.lastSelectedElementIdUpdate = Date.now();

            // This will make the edit form to switch to empty state if the selected element is of nested type and a different parent element has been selected
            this.$store.commit('removeTableRowBindingView', this.componentData.id);

            // Wait for 1 second before fetching external API data
            setTimeout(() => {
            // Only fetch external API data if the selectedElementId hasn't been updated in the last 1 second
                if (this.lastSelectedElementIdUpdate && Date.now() - this.lastSelectedElementIdUpdate >= 1000) {
                    this.fetchDataFromExternalApiList();
                }
            }, 1000);
        },
        linkedComponentSingleItemView: {
            handler(value) {
                this.modalViewEnabled = value?.settings?.modalViewEnabled || false;
            },
            immediate: true
        }
    },
    computed: {
        ...mapState([
            'userUiElements',
            'selectedUiElement',
            'userUiComponents',
            'tableRowBindingView',
            'refreshExternalDataInComponent',
            'isDeletingExternalApiEntry',
            'keyListenerComponentId',
            'editMode',
            'modalComponentData',
            'showModalPropertyDetails'
        ]),
        elementsCurrentComponent() {
            return this.userUiElements[this.componentData.id] || [];
        },
        filterPurposeElements() {
            if (this.elementsCurrentComponent && this.elementsCurrentComponent.length > 0) {
                return this.elementsCurrentComponent.filter(item => item.purpose === 'filter')
            }
            return []
        },
        tableColumnPurposeElements() {
            if (this.elementsCurrentComponent && this.elementsCurrentComponent.length > 0) {
                return this.elementsCurrentComponent.filter(item => item.purpose === 'item_info')
            }
            return [];
        },
        paginationPurposeElements() {
            if (this.elementsCurrentComponent && this.elementsCurrentComponent.length > 0) {
                return this.elementsCurrentComponent.filter(item => item.purpose === 'pagination')
            }
            return []
        },
        currentPageNumber() {
            const paginateElement = this.getPaginationElementByOperation('paginate');
            const paginatePropertyPath = paginateElement?.property_path;
            const pageValue = this.externalApiData?.[paginatePropertyPath];
            return !isNaN(parseInt(pageValue)) ? parseInt(pageValue) : parseInt(this.filterParams[paginatePropertyPath]) || 1;
        },
        currentPageNumberDisplay() {
            if(this.firstPageNumber === 1) {
                return this.currentPageNumber;
            } else {
                return this.currentPageNumber - this.firstPageNumber + 1;
            }
        },
        paginationProperties() {
            const validationRule = this.getPaginationElementByOperation('paginate')?.validation_rule;
            const nextPageProperties = validationRule?.next_page;
            const prevPageProperties = validationRule?.prev_page;
            const firstPageProperties = validationRule?.first_page;
            const startIndex = firstPageProperties?.start_index;

            const getPageParameters = (pageProps, pageUri) => {
                const params = {};
                
                if (pageProps) {
                const { query_param, token_param } = pageProps;
                if (pageUri) {
                    const urlSearchParams = new URLSearchParams(pageUri);
                    if (query_param && urlSearchParams.has(query_param)) {
                    const pageNumberValue = urlSearchParams.get(query_param);
                    params.urlParams = {
                        pageNumber: {
                        param: query_param,
                        value: pageNumberValue,
                        },
                    };
                    if (!isNaN(pageNumberValue)) {
                        params.urlParams.pageNumber.value = parseInt(pageNumberValue, 10);
                    }
                    }
                    if (token_param && urlSearchParams.has(token_param)) {
                    if (!params.urlParams) {
                        params.urlParams = {};
                    }
                    params.urlParams.token = {
                        param: token_param,
                        value: urlSearchParams.get(token_param),
                    };
                    }
                }
                }
                return params;
            };

            return {        
                nextPage: getPageParameters(nextPageProperties, this.externalApiData?.[nextPageProperties?.source_property]),
                prevPage: getPageParameters(prevPageProperties, this.externalApiData?.[prevPageProperties?.source_property]),
                firstPage: getPageParameters(firstPageProperties, this.externalApiData?.[firstPageProperties?.source_property]),
                startIndex: startIndex
            };
        },
        nextPageNumber() {

            if(this.paginationProperties && this.paginationProperties?.nextPage?.urlParams?.pageNumber?.value) {
                return this.paginationProperties?.nextPage?.urlParams?.pageNumber?.value;
            }

            const totalPages = parseInt(this.totalPages);
            const currPageNumber = this.currentPageNumber;
            if (totalPages > currPageNumber) {
                return currPageNumber + 1;
            }
            return null;
        },
        firstPageNumber() {
            // Check if the paginationProperties startIndex is set, if so, return the startIndex, else return 1
            if(this.paginationProperties && this.paginationProperties?.startIndex !== undefined) {
                return this.paginationProperties?.startIndex;
            } else {
                return 1;
            }
        },
        prevPageNumber() {
            if(this.paginationProperties && this.paginationProperties?.prevPage?.urlParams?.pageNumber?.value !== undefined) {
                return this.paginationProperties?.prevPage?.urlParams?.pageNumber?.value;
            }

            const currPageNumber = parseInt(this.currentPageNumber);
            if (currPageNumber > 1) {
                return currPageNumber - 1;
            }
            return null;
        },
        lastPageNumber() {
            const totalPages = parseInt(this.totalPages);
            if (totalPages) {
                return totalPages;
            }
            return null;
        },
        totalPages() {
            const totalPagesValue = this.getPaginationItemData(this.getPaginationElementByOperation('total_pages'));

            if (totalPagesValue !== null && totalPagesValue !== undefined) {
                const parsedTotalPages = parseInt(totalPagesValue, 10);

                if (!isNaN(parsedTotalPages) && Number.isInteger(parsedTotalPages)) {
                return parsedTotalPages;
                }
            }

            return null;
        },
        totalItems() {
            const totalItems = this.getPaginationItemData(this.getPaginationElementByOperation('total_items'));
            return totalItems 
        },
        displayedItemsPerPage() {
            if (this.externalApiData === null) {
                return;
            }
            const property = this.getPaginationElementByOperation('items_per_page');
            if(!property) return;
            const defaultValue = property?.validation_rule?.default_value || 10;
            const queryParam = property?.validation_rule?.query_param || property?.property_path
            const itemsPerPage = parseInt(this.filterParams[queryParam]) || parseInt(defaultValue);
            if (isNaN(itemsPerPage)) {
                throw new Error('Invalid items per page value');
            }
            return itemsPerPage;
        },
        displayedItemRange() {
            if (this.externalApiData === null) {
                return;
            }
            const itemsOnPage = this.tableData?.length;
            const itemsPerPage = this.displayedItemsPerPage;
            const totalItems = this.totalItems;

            if (!Number.isInteger(itemsPerPage) || !Number.isInteger(this.currentPageNumberDisplay)) {
                return '[Invalid input]';
            }

            const from = (this.currentPageNumberDisplay - 1) * itemsPerPage + 1;
            let to = this.currentPageNumberDisplay * itemsPerPage;

            if (totalItems && to > totalItems) {
                to = totalItems;
            }

            if(itemsOnPage !== itemsPerPage) {
                to = from + itemsOnPage - 1;
            } 

            if (from > to) {
                return '0'; // [Invalid range]
            }

            return `${from}-${to}`;
        },
        deleteEntryWithResource() {
            return this.userApiResources?.find(item => item.link_with_resource_id == this.resourceId && item.method === 'delete');
        },
        deleteEntryWithProperty() {
            return this.userApiProperties?.find(item => item.id == this.deleteEntryWithResource.link_with_property_id);
        },
        linkedComponentFormEdit() {
            if (this.userUiComponents?.length === 0 || !this.userUiElements || !Object.keys(this.userUiElements).length === 0 || !this.userUiElements[this.componentData.id]?.length) return null;
            const linkedFormTypeComponents = this.userUiComponents?.filter(item => item.linked_component_id == this.componentData.id && item.type === 'form-item');
            for (const component of linkedFormTypeComponents) {
                const elementsInLinkedComponent = this.userUiElements[component.id] || [];
                const firstElement = elementsInLinkedComponent[0] || null;
                // console.log('component.id: ', component.id, ', firstElement', firstElement)
                if (firstElement && (firstElement.resource_method === 'put' || firstElement.resource_method === 'patch')) {
                let settings = component.settings ? JSON.parse(component.settings) : null;
                return {...component, settings}
                }
            }
                return null;
        },
        linkedComponentSingleItemView() {
            if (this.userUiComponents?.length === 0 || !this.userUiElements || !Object.keys(this.userUiElements).length === 0 || !this.userUiElements[this.componentData.id]?.length) return null;
            const linkedFormTypeComponents = this.userUiComponents?.filter(item => item.linked_component_id == this.componentData.id && item.type === 'view-entry') || [];
            if(linkedFormTypeComponents.length === 0) return null;
            for (const component of linkedFormTypeComponents) {
                const elementsInLinkedComponent = this.userUiElements[component.id] || [];
                const firstElement = elementsInLinkedComponent[0] || null;
                // console.log('component.id: ', component.id, ', firstElement', firstElement)
                if (firstElement && (firstElement.resource_method === 'get')) {
                let settings = component.settings ? JSON.parse(component.settings) : null;
                return {...component, settings}
                }
            }

                return null;
        },
        componentSettings() {
            return this.getComponentSettings(this.componentData) // call sharedMixin.js function
        },
        binding() {
            // console.log('this.componentData?.linked_component_id: ', this.componentData?.linked_component_id)
            return this.tableRowBindingView.find(component => 
            component.sourceComponentId == this.componentData?.linked_component_id
            && component.operation === 'view'
            );
        },
        nestedInComponent() {
            const linkedComponentId = this.componentData?.linked_component_id;
            console.log('linkedComponentId: ', linkedComponentId)
            // const linkedComponent = linkedComponentId && this.userUiComponents?.find(component => component.id == linkedComponentId);
            return linkedComponentId ? this.userUiComponents?.find(component => component.id == linkedComponentId) : null;
        },
        parentNotSelected() {
            return !this.selectedElementId && this.componentData?.linked_component_id
        },
        sourceComponentTitle() {
            const component = this.userUiComponents.find(component => component.id === this.componentData?.linked_component_id);
            return component.title;
        },
        tableData() {
            if(this.isLoadingExternalData) return this.externalApiData?.length || 3;
            if(this.externalApiData?.length === 0) return 0;

            let tableData = this.externalApiData;

            if(this.componentData?.content_path && this.externalApiData) {
                tableData = this.externalApiData[this.componentData.content_path];
            }

            return tableData || Array.from(Array(this.displayedItemsPerPage).keys()) || 10;
        }

        
    },
    methods: {
        handlePagination(property, value, action) {
            const paginationProperties = this.paginationProperties;

            const nextPageParams = paginationProperties?.nextPage?.urlParams;
            const prevPageParams =  paginationProperties?.prevPage?.urlParams;
            const firstPageParams =  paginationProperties?.firstPage?.urlParams;

            if (property && action) {
                const urlParams = action === 'next'
                ? nextPageParams
                : action === 'prev'
                ? prevPageParams
                : action === 'first'
                ? firstPageParams
                : null;

                if (urlParams) {
                for (const param in urlParams) {
                    const isLastParam = param === Object.keys(urlParams).pop();

                    // If prevPageParams or nextPageParams contains `token` param, but firstPageParams doesn't, then we need to remove the token param from the filterParams
                    if (action === 'first' && ("token" in prevPageParams || "token" in nextPageParams) && !("token" in firstPageParams)) {
                    const tokenParam = prevPageParams.token.param || nextPageParams.token.param;
                    this.handleFilterChange(null, null, null, tokenParam, false);
                }

                    this.handleFilterChange(null, null, urlParams[param].value, urlParams[param].param, isLastParam);
                }
                
                } else if (property && value) {
                this.handleFilterChange(null, property, value);
                } else {
                throw new Error('Invalid input for handlePagination method');
                }
            }
        },
        showAlert() { // temporary for debugging
            window.alert('Alert message here!');
            },
        handleKeyDown(event) {
            if(!this.selectedRowIndex && !this.externalApiData) return;

            if(this.modalComponentData) return; // if modal is open, don't handle keydown

            if (this.keyListenerComponentId !== this.componentData.id) return;

            if (event.key === 'ArrowDown') {
                const nextRowIndex = this.selectedRowIndex + 1;
                if (nextRowIndex >= this.externalApiData.length) return;
                this.selectedRowIndex = nextRowIndex;
                const nextRow = this.externalApiData[nextRowIndex];
                this.setComponentBinding(nextRow, 'view')
                event.preventDefault();

                } else if (event.key === 'ArrowUp') {

                const prevRowIndex = this.selectedRowIndex - 1;
                if (prevRowIndex < 0) return;
                this.selectedRowIndex = prevRowIndex;
                const prevRow = this.externalApiData[prevRowIndex];
                this.setComponentBinding(prevRow, 'view')
                event.preventDefault();
                }
        },
        handleRowSelection(event, element, index, operation) {
            // console.log('handleRowSelection', event, element, index, operation)
            event.preventDefault();

            // Handle click on link
            if (event.target.tagName.toLowerCase() === 'a') {
                const linkUrl = event.target.getAttribute('href');
                window.open(linkUrl, '_blank');
            }

            const rowRefs = this.$refs['row-' + this.componentData.id + '-'  + index];
            const rowRef = Array.isArray(rowRefs) ? rowRefs[0] : rowRefs;
            rowRef.setAttribute('tabindex', 0);
            // console.log('tabindex:', rowRef.getAttribute('tabindex')); // <-- add this line
            // rowRef.focus(); // if focus enabled it will scroll horizontally to the right end of the table

            this.selectedRowIndex = index;
            this.setComponentBinding(element, operation)
            this.$store.commit('setKeyListenerComponentId', this.componentData.id);
            // if(operation === 'edit') {
            //     this.$store.commit('requestRefreshExternalDataInComponent', { componentId: this.componentData.id, value: true });
            // }

            // listen to double click event

            //$store.commit('setModalComponentData', linkedComponentFormEdit)
        },
        setComponentBinding(element, operation) {
            this.$store.commit('setTableRowBindingView', {
                sourceComponentId: this.componentData.id,
                parentItemVariables: this.getParentItemVariables(),
                operation: operation,
                linkedElementData: element
            });
        },
        getParentItemVariables() {
            if (this.binding) {
                let parentItemVariables = this.binding.parentItemVariables;
                if (this.selectedElementId && !parentItemVariables.includes(this.selectedElementId)) {
                    parentItemVariables.push(this.selectedElementId);
                }
                return parentItemVariables;
            }
            return [];
        },
        openModalPropertyDetails(contentType, element, data, currItemIndex) {
            // console.log('openModal', contentType, title, data, currItemIndex)
            this.$store.commit('setShowModalPropertyDetails', {elementId: element.id, stateValue: true} );
            this.modalData.contentType = contentType;
            this.modalData.title = element.display_name;
            this.modalData.data = data;
            this.modalData.currItemIndex = currItemIndex;
            this.modalData.element = element;
            this.modalData.component = this.componentData;
        },
        selectInputText(event) {
            event.target.select();
        },
        getPaginationItemData(property) {
            if (this.externalApiData) {
                const data = (property?.in_headers && this.externalApiHeaders) ? this.externalApiHeaders : this.externalApiData;
                const value = data[property?.property_path];

                if (typeof value === 'number') {
                // If value is an integer, return it as is
                return value;
                } else if (typeof value === 'string') {
                // If value is a string, try to parse it as a number
                const parsedValue = parseFloat(value);

                if (!isNaN(parsedValue) && Number.isInteger(parsedValue)) {
                    // If parsedValue is a valid integer, return it
                    return parsedValue;
                }
                } else if (Array.isArray(value)) {
                if (value.length > 0) {
                    // If value is an array and the first element is a string, try to parse it as a number
                    const firstElement = value[0];
                    if (typeof firstElement === 'string') {
                    const parsedFirstElement = parseFloat(firstElement);
                    if (!isNaN(parsedFirstElement) && Number.isInteger(parsedFirstElement)) {
                        // If parsedFirstElement is a valid integer, return it
                        return parsedFirstElement;
                    }
                    }
                }
                }

                // If none of the conditions are met, return null
                return null;
            }

            return null;
            },
        getPaginationElementByOperation(operation) {
            if (!this.paginationPurposeElements || this.paginationPurposeElements.length === 0) {
                return null;
            }
            const paginationElement = this.paginationPurposeElements.find(item => {
                if (item.operation === operation) {
                    return true;
                }
            });
            return paginationElement || null;
        },
        handleInput(element) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                const value = this.filterParams[element.property_path];
                this.handleFilterChange(value, element);
                this.updateFormValues(value, element);
            }, 500);
        },
        startTypingTimer(element) {
            this.typingTimer = setTimeout(() => {
                const value = this.filterParams[element.property_path];
                this.handleFilterChange(value, element);
                this.updateFormValues(value, element);
            }, 500);
        },
        clearTypingTimer() {
            clearTimeout(this.typingTimer);
        },
        handleFilterChange(eventValue, property, value, queryParam, fetchExternalData = true) {
            // console.log('handleFilterChange: eventValue: ', eventValue, ', property: ', property, ', value: ', value, ', queryParam: ', queryParam, ', fetchExternalData: ', fetchExternalData)
            if (property || queryParam) {
                const param = queryParam || property?.validation_rule?.query_param || property.property_path;
                const operation = property?.operation || 'paginate';

                if (eventValue || value) {
                    let pageNumber;
                    let itemsPerPage;
                    let elemenetPaginate;
                    const selectedOptions = property ? this.formNewValues[property.id] : null;
                    switch (operation) {
                        case "input-date":
                            if (operation === "input-date") {
                                this.filterParams[param] = this.formData[param];
                                if (this.currentPageNumber > 1) {
                                    this.filterParams['page'] = 1;
                                }
                            }
                            break;
                        case "paginate":
                            pageNumber = value || parseInt(eventValue, 10);
                            this.filterParams[param] = pageNumber;
                            break;
                        case "items_per_page":
                            itemsPerPage = parseInt(eventValue, 10);
                            if (isNaN(itemsPerPage)) {
                                throw new Error('Invalid items per page value');
                            }
                            this.filterParams[param] = itemsPerPage;
                            if (this.currentPageNumber > this.firstPageNumber) {
                                elemenetPaginate = this.getPaginationElementByOperation('paginate');
                                if (elemenetPaginate)
                                this.handlePagination(elemenetPaginate, this.firstPageNumber, 'first');
                            }
                            break;
                        case "multi-select":
                            if (property.data_type === 'array' && property.validation_rule?.max_items === 1) {
                                this.filterParams[param] = eventValue;
                            }
                            else if (property.data_type === 'array' || (property.data_type === 'api' && property.purpose === 'filter' && property.validation_rule?.max_items > 1)) {
                                if(selectedOptions && selectedOptions.length > 0) {
                                    this.filterParams[param + '[]'] = selectedOptions.map(encodeURIComponent).join('&' + property.property_path + '[]=');
                                } else {
                                    delete this.filterParams[param + '[]']
                                }
                               
                            } 
                            else if (property.data_type === 'api' && property.purpose === 'filter') {
                                if(eventValue && eventValue !== "") {
                                    this.filterParams[param] = eventValue;
                                } else {
                                    delete this.filterParams[param];
                                }
                            }  
                            // generate a link that will look like ?status[]=draft&?status[]=published
                            
                            break;
                        default:
                            this.filterParams[param] = eventValue;
                            if (this.currentPageNumber > 1) {
                                this.filterParams['page'] = 1;
                            }
                    }
                } else {
                    delete this.filterParams[param];
                }
                if (fetchExternalData) {
                    this.fetchDataFromExternalApiList();
                }
            }
        },
        handleItemSelection(event, item) {
            if (!this.$store.state.editMode) {
                return;
            }
            // console.log('handleItemSelection - Data Table', item.id)
            this.$store.commit('setSelectedUiElement', item);
            //set focus on the selected element
            setTimeout(() => {
                let element = null;
                element = event.target.closest('.js-data-table-filter-selected') || null;
                element = element || event.target.closest('.js-data-table-col-selected') || null;
                element?.setAttribute('tabindex', '0');
                element?.focus();
            }, 10);
            this.selectedRowIndex = null;
            document.addEventListener('keydown', this.handleDeleteKeyDown);
        },
        isFilterActive(element) {
            if (element) {
                // const defaultValue = element.validation_rule?.default_value;
                // const dataType = element.data_type;
                return this.filterParams[element.property_path] !== undefined
                        || (this.filterParams[element.property_path + '[]'] !== undefined)
                        // || (this.filterParams[element.property_path] === defaultValue && dataType === 'array')
            }
            return false;
        },
        clearFilter(element) {
            if (element) {
                delete this.filterParams[element.property_path];
                delete this.filterParams[element.property_path + '[]'];
                delete this.formNewValues[element.id];
                delete this.formValidationErrors[element.id];
                this.fetchDataFromExternalApiList();
            }
        },
        // isAnyFilterActive() {
        //     return Object.keys(this.filterParams).length > 0;
        // },
        isAnyFilterActive() {
            const filterKeys = Object.keys(this.filterParams);
            return (
                filterKeys.length > 0 &&
                !filterKeys.every((key) =>
                this.paginationPurposeElements.some((element) =>
                    element.property_path === key
                )
                )
            );
        },
        clearAllFilters() {
            this.filterParams = {};
            this.formNewValues = {};
            this.formValidationErrors = {};
            this.fetchDataFromExternalApiList();
        },
        handleDeleteApiEntry(event, item) {
            this.deleteExternalApiEntryItem = item;
            this.setComponentBinding(item, 'delete')
            // console.log('handleDeleteApiEntry - Data Table', item.id)
            this.openModalConfirmDeleteEntry = true;
        },
        async deleteExternalApiEntry() {
            const item = this.deleteExternalApiEntryItem;
            // console.log('deleteExternalApiEntry', item, this.componentData, this.deleteEntryWithProperty)

            if (!item || !this.componentData || !this.deleteEntryWithProperty) {
                return;
            }
        
            this.$store.commit('setIsDeletingExternalApiEntry', { componentId: this.componentData.id, value: true });

            const itemId = item[this.deleteEntryWithProperty.property_path];
            const resourceId = this.deleteEntryWithResource.id;

            // Build a list of item ids to fetch from the external API
            let itemIds = '';
            let itemVariables = [...(this.binding?.parentItemVariables || [])]; // Create a copy of parentItemVariables
            itemVariables.push(itemId);
            itemIds = itemVariables.join(',');

            console.log('itemIds', itemIds)


            await axios.delete('/api_data.php?component_id=' + this.componentData.id + '&resource_id=' + resourceId + '&item_id=' + itemIds)
                .then(response => {
                if (response.status === 204) {
                    console.log('Entry deleted');
                    this.fetchDataFromExternalApiList();
                    // If the form is of nested component, refresh the data in the parent component
                    if(this.nestedInComponent) {
                        this.$store.commit('requestRefreshExternalDataInComponent', { componentId: this.nestedInComponent?.id, value: true });
                    }
                    this.$store.commit('setIsDeletingExternalApiEntry', { componentId: this.componentData.id, value: false });
                    this.$store.commit('addNotification', { 
                        componentId: this.componentData.id,
                        type: 'success',
                        title: `Entry deleted from "${this.componentData.title}"`
                    });
                } 
                })
                .catch(error => {
                    console.error(error.response.data);
                    const message = error.response.data?.message || error.response.data;
                    this.$store.commit('isDeletingExternalApiEntry', { componentId: this.componentData.id, value: false });
                    this.$store.commit('addNotification', { 
                        componentId: this.componentData.id,
                        type: 'error',
                        title: 'Entry deletion failed',
                        message: message
                    });
                });
            
            this.deleteExternalApiEntryItem = null;
        },
        openModalToConfirmDeletion(event, item_id) {
            if (this.selectedUiElement) {
                this.$store.commit('showModalDeleteUserUiElement', true);
            } else if (item_id) {
                this.$store.commit('showModalDeleteExternalApiEntry', true);
            }
        },
        handleDeleteKeyDown(event) {
            const keys = ['Backspace', 'Delete'];
            if (keys.includes(event.key)) {
                this.openModalToConfirmDeletion();
            }
        },
        async fetchDataFromExternalApiList() {

            // Assign component based on whether the component is linked to another component or not. If linked, means the table is a child table
            // const component = this.selectedElementId 
            //     ? this.userUiComponents.find(item => item.id == this.componentData?.linked_component_id) 
            //     : this.componentData;

            const component = this.componentData;

            // console.log(`this.selectedElementId: ${this.selectedElementId}`)
            // console.log(`querable compnent id for ${this.componentData.id} is ${component.id}`)
            // console.log(`querable compnent id for ${this.componentData.id} is ${component.id}`)

            // const urlParams = new URLSearchParams();
            // for (const [key, value] of Object.entries(this.filterParams)) {
            //     if (value) {
            //         urlParams.append(key, value);
            //     }
            // }

            let urlParams = '';
            
            if(this.selectedElementId) {
                urlParams += '&item_id=' + this.selectedElementId;
                // this.externalApiData = [];
                this.isLoadingExternalData = true;
            }

            for (const [key, value] of Object.entries(this.filterParams)) {
                if (value) {
                    urlParams += '&' + key + '=' + value;
                }
            }

            const apiUrl = `/api_data.php?resource_id=${this.resourceId}&component_id=${component.id}${urlParams.toString()}`;
            // console.log(`request by component ID ${component.id} apiUrl ${apiUrl}`);

            if(!this.$store.state.refreshExternalDataInComponent[component.id]) {
                this.$store.commit('requestRefreshExternalDataInComponent', { componentId : component.id, value: true });
                this.isInternalMethod = true;
            }
            await axios.get(apiUrl)
                .then(response => {
                    if (response.status === 200) {
                        // console.log(response.data.in_headers);
                        this.externalApiHeaders = response.data.header;
                        // this.$store.commit('setExternalApiData', response.data.body);
                        // console.log('externalApiData', response.data.body)
                        this.externalApiData = response.data.body;

                        this.$store.commit('requestRefreshExternalDataInComponent', { componentId : component.id, value: false });
                        this.isInternalMethod = false;
                        if(this.selectedElementId) {
                            this.isLoadingExternalData = false;
                        }
                        this.handleScrollable();
                    
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response.data);
                        let message = null;

                        if (typeof error.response.data.message === 'string' && error.response.data.message) {
                            message = error.response.data.message;
                        } 
                        // else if (typeof error.response.data === 'string' && error.response.data.trim() !== '') {
                        //     message = error.response.data;
                        // }
                        
                        this.$store.commit('addNotification', { 
                        componentId: component.id,
                        type: 'error',
                        title: `Unable to load data in "${component.title}"`,
                        message: message
                        });
                    } else {
                        console.error(error);
                    }
                })

        },
        handleReorder(event) {
            let position = event.moved.newIndex + 1
            this.$store.dispatch('updateUiElement', { position: position});
        },
        handleOutsideClick(event) {
            if (!this.editMode) {
                return;
            }
            const dataTable = event.target.closest('.js-data-table-head');
            const filter = event.target.closest('.js-data-table-filters-item');
            const paginationItem = event.target.closest('.js-pagination-item');
            const container = event.target.closest('.js-card-data-table');

            if(!dataTable && !filter && !paginationItem && container) {
                this.$store.commit('setSelectedUiElement', null);
            }

            if (!dataTable && !filter && !paginationItem) {
                // this.$store.commit('setSelectedUiElement', null);
                document.removeEventListener('keydown', this.handleDeleteKeyDown);
            }
        },
        handleScrollable() {
            let tableWrappers;
            const tableWrapperId = 'data-table-wrapper-' + this.componentData?.id;

            if (tableWrapperId && typeof tableWrapperId === "string" && tableWrapperId.includes("data-table-wrapper")) {
                tableWrappers = [document.getElementById(tableWrapperId)];
            } else {
                tableWrappers = document.querySelectorAll(".js-data-table-wrapper");
            }

            if (tableWrappers.length > 0) {
                for (let i = 0; i < tableWrappers.length; i++) {
                    let wrapper = tableWrappers[i];
                    if (!wrapper) {
                        continue;
                    }
                    let shadowLeft = wrapper.querySelector(".js-shadow-left");
                    let shadowRight = wrapper.querySelector(".js-shadow-right");

                    // console.log('wrapper.scrollWidth', wrapper.scrollWidth, ', wrapper.clientWidth', wrapper.clientWidth, ', wrapper.scrollLeft', wrapper.scrollLeft)

                    if (wrapper.scrollWidth > wrapper.clientWidth) {
                        if (wrapper.scrollLeft === 0) {
                            shadowLeft.style.opacity = "0";
                            shadowRight.style.opacity = "1";
                        } else if (wrapper.scrollLeft + wrapper.clientWidth >= wrapper.scrollWidth - 1) {
                            shadowLeft.style.opacity = "1";
                            shadowRight.style.opacity = "0";
                        } else {
                            shadowLeft.style.opacity = "1";
                            shadowRight.style.opacity = "1";
                        }
                    } else {
                        shadowLeft.style.opacity = "0";
                        shadowRight.style.opacity = "0";
                    }
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);

        // const debouncedHandleScrollable = debounce(this.handleScrollable, 1000);
        window.addEventListener("resize", this.handleScrollable);

        if (this.componentData) {
            this.$store.dispatch('fetchUserUiElements', { componentId: this.componentData.id });
        }

        window.addEventListener('keydown', this.handleKeyDown);

    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
        document.removeEventListener('resize', this.handleScrollable);
        window.removeEventListener('keydown', this.handleKeyDown);
        
    }
}


</script>

<style scoped>

.select--filter {
    text-transform: capitalize;
}

/* .flip-list-enter-active,
.flip-list-leave-active,
.flip-list-move {
    transition: all 0.1s linear;
}

.flip-list-enter,
.flip-list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

.flip-list-move {
    transform: translateY(0);
} */
</style>
