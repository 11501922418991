<template>
    <div class="modal" tabindex="0">
        <div :class="'modal__content modal-width-' + componentData?.size" ref="modalContent">
            <div class="modal__header">
                <h2 class="modal__title">{{ componentData?.title }}</h2>
                <!-- <i v-if="componentOperationType === 'edit' && refreshExternalDataInComponent[componentData.id]" 
                   class="icon icon-loader-circle"></i> -->
                <button type="button" class="btn btn-toggle" @click="closeModal">
                    <i class="icon icon-close"></i>
                </button>
            </div>

            <div class="modal__body">
                <Form v-if="componentData?.type === 'form-item'" :component-data="componentData" />
                <SingleItemView v-if="componentData?.type === 'view-entry'" :component-data="componentData" />
                
                <div v-if="componentOperationType === 'edit' && refreshExternalDataInComponent[componentData.id]" class="modal__loader">
                    <i class="icon icon-loader-circle"></i>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import GenericMixin from '@/mixins/genericMixin.js'
import ModalMixin from '@/mixins/modalMixin.js'
import Form from '@/components/PageContent/card-types/Form.vue'
import SingleItemView from '@/components/PageContent/card-types/SingleItemView.vue'

export default {
    mixins: [ModalMixin, GenericMixin],
    components: {
        Form,
        SingleItemView
    },
    
    data() {
        return {
            // confirmAction: null,
            // singleComponentData: null,
            // prevHeight: 0,
        };
    },
    props: {
        componentData: {
            type: Object,
            required: true,
            default: () => ({})
        }

    },
    watch: {
        prevHeight(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.handleResize();
        }
        },
    },
    computed: {
        ...mapState([
            'refreshExternalDataInComponent',
            'userUiElements'
        ]),
    componentOperationType() {
        return this.getComponentOperationType(this.componentData?.id); // call 'getComponentOperationType' method from GenericMixin
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        keydownHandler(event) {
           if (event.key === "Escape") {
                    this.closeModal();
            }
        },
  },
  mounted() {
    this.centerModal();
    window.addEventListener('resize', this.handleResize);  // call 'handleResize' method from ModalMixin
    window.addEventListener("keydown", this.keydownHandler);
    this.handleResize();

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);  // call 'handleResize' method from ModalMixin
    window.removeEventListener("keydown", this.keydownHandler);
  },
};

</script>

<style scoped>

/* .modal__content {
    min-height: 240px;
} */
.error-msg {
    display: block;
}
.alert-error {
    margin-top:24px;
    margin-bottom: 24px;
}
</style>