export default {
  data() {
    return {
        prevHeight: 0
    }
  },
  methods: {
    centerModal() {
        const modalContent = this.$refs.modalContent;
        const screenHeight = window.innerHeight;
        // console.log('screenHeight:', screenHeight)
        const modalContentHeight = modalContent.offsetHeight;
        // console.log('modalContentHeight:', modalContentHeight)
        let marginTopBottom = (screenHeight - modalContentHeight) / 2;
        marginTopBottom = Math.max(marginTopBottom, 20); // check if marginTopBottom is smaller than 20, set it to 20
        modalContent.style.marginTop = `${marginTopBottom}px`;
        modalContent.style.marginBottom = `${marginTopBottom}px`;
    },
    handleResize() {
        this.centerModal();
    }
  },
  mounted() {
    const ro = new ResizeObserver((entries) => {
        if (entries[0].contentRect.height !== this.prevHeight) {
            this.prevHeight = entries[0].contentRect.height;
        }
    });
    ro.observe(this.$refs.modalContent);
}
}
