<template>
    <div class="modal">
        <div class="modal__content modal-width" ref="modalContent">
            <div class="modal__header">
                <h2 class="modal__title">Delete "{{pageName}}" page?</h2>
                <button @click="closeModal" type="button" class="btn btn-toggle">
                    <i class="icon icon-close"></i>
                </button>
            </div>

            <div class="modal__body modal__text-prompt">
                <p>Deleting this page will remove the components it contains (tables, forms, views). External data remains unaffected.</p>
            </div>

            <div v-if="showAlert" class="alert alert-error">
                <span>{{ apiErrorMessage }}</span>
                <button @click="closeAlert()" type="button" class="btn btn-toggle">
                    <i class="icon icon-close"></i>
                </button>
            </div>

            <div class="modal__action-buttons">
                <button @click="closeModal" type="button" class="btn btn-large btn-text-primary">
                    <span>Cancel</span>
                </button>
                <button @click="deletePage" type="submit" class="btn btn-large btn-text-subtle">
                    <span>Delete</span>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import modalMixin from '@/mixins/modalMixin.js'

export default {
    name: 'NavigationModal',
    mixins: [modalMixin],
    data() {
        return {
            showAlert: false,
            apiErrorMessage: '',
        };
    },
    computed: {
    ...mapState([
        'selectedPage',
        'appPages'
    ]),
    pageName() {
        return this.appPages.find(page => page.id === this.$store.state.modalTargetPageId).name || ''
    },
    },
    methods: {
        keydownHandler(event) {
            switch (event.key) {
                case "Escape":
                    this.closeModal();
                    break;
                // case "Enter":
                //     this.deletePage();
                //     break;
            }
        },
        closeModal() {
            this.$store.commit('showModalDeletePage', false);
            this.$store.commit('modalTargetPageId', null) // reset modalTargetPageId
            this.showAlert = false
        },
        closeAlert() {
            this.showAlert = false
        },
        async deletePage() {
            const targetPageId = this.$store.state.modalTargetPageId
            // console.log('request deletePage...')
            // console.log('modalTargetPageId', this.$store.state.modalTargetPageId)
            // console.log('selectedPageId', this.$store.state.selectedPage.id)
            try {
                const response = await axios.delete(`/pages.php`, {
                    params: {
                        id: targetPageId
                    }
                });

                // console.log(response)

                if (response.status === 204) {
                    this.showAlert = false

                    this.$store.commit('addNotification', { 
                        componentId: 0,
                        type: 'success',
                        title: `Page "${this.pageName}" deleted`
                    });

                    this.closeModal()
                    // console.log('targetPageId', targetPageId)
                    // console.log('selectedPageId', this.$store.state.selectedPage.id)

                    if (targetPageId === this.$store.state.selectedPage.id) {
                        this.$store.commit('setSelectedPage', this.appPages[0])
                        // console.log('selectedPageId reset to null', this.$store.state.selectedPage.id)
                    }
                    await this.$store.dispatch('fetchPages')
                }

            } catch (error) {
                console.log(error.response);
                this.showAlert = true
                this.apiErrorMessage = error.response.data
            }
        }
    },
    mounted() {
        this.centerModal();
        window.addEventListener('resize', this.handleResize);  // call mixin method
        window.addEventListener("keydown", this.keydownHandler);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);  // call mixin method
        window.removeEventListener("keydown", this.keydownHandler);
    }
};

</script>

<style scoped>
.modal-width {
    width: 528px;
}
.error-msg {
    display: block;
}
.alert-error {
    margin-top:24px;
    margin-bottom: 24px;
}
</style>