<template>
    <!-- Modal -->
    <div class="modal" ref="modalPropertyDetails">
        <div class="modal__content modal-width" ref="modalContent">
            <div class="modal__header">
                <div class="modal__header__content">
                    <h2 class="modal__title">{{ localModalData.title }}</h2>
                    <button type="button" class="btn btn-toggle" @click="closeModal">
                        <i class="icon icon-close"></i>
                    </button>
                </div>
            </div>

            <!-- <pre>{{ lookupData }}</pre>-->
            <!-- <pre>{{ modalData.data }}</pre>  -->

            <div v-if="localModalData.contentType === 'image-gallery'" class="modal__body modal__imageview-container">
                <button v-if="Array.isArray(data)" type="button" title="Close" class="btn btn-small btn-contained-subtle"
                    @click="localModalData.currItemIndex = localModalData.currItemIndex - 1"
                    :disabled="localModalData.currItemIndex === 0"
                >
                    <i class="icon icon-chevron-left"></i>
                </button>
                <div class="modal__imageview-image">
                    <img v-if="Array.isArray(data) && data.length > 0" :src="data && data[localModalData.currItemIndex] ? data[localModalData.currItemIndex] : ''" />
                    <img v-else-if="data" :src="data" />
                    <span class="loader"></span>
                </div>
                
                <button v-if="Array.isArray(data)" type="button" class="btn btn-small btn-contained-subtle"
                        @click="localModalData.currItemIndex = localModalData.currItemIndex + 1"
                        :disabled="localModalData.currItemIndex === data?.length - 1"
                >
                    <i class="icon icon-chevron-right"></i>
                </button>
            </div>

            <div v-else-if="data?.length && !isArrayPlain" class="modal__body modal__items-list">
                <div v-for="(entry, index) in data" :key="index" class="modal__items-list-item">
                    <div v-if="data.length > 1" class="modal__items-list-item--name">#{{ index + 1 }}</div>
                    <div class="single-item-view">
                        <div v-for="(value, key) in entry" :key="key" class="single-item-view__item">
                            <div class="single-item-view__item__name">
                                <div class="single-item-view__item__value__content">
                                    {{ capitalizeFirstLetter(key) }}
                                </div>
                            </div>
                            <div class="single-item-view__item__value">
                                <div v-if="Array.isArray(value)" class="single-item-view__item__value__content">
                                        <span v-for="(item, index) in value" :key="index">
                                            <span v-html="getHtmlElement(element?.validation_rule, item)"></span><span v-if="index < value.length - 1"> · </span>
                                        </span><br />
                                </div>
                                <div v-else class="single-item-view__item__value__content" v-html="getHtmlElement(element?.validation_rule, value)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="data?.length && isArrayPlain && element?.validation_rule?.batch_request" class="modal__body modal__items-list">
                <div class="single-item-view">
                    <div v-for="item in data" 
                         :key="item" 
                         class="single-item-view__item"
                    >
                        <div class="single-item-view__item__name">
                                <div class="single-item-view__item__value__content">
                                    {{ item }}
                                </div>
                        </div>
                        <div class="single-item-view__item__value">
                            <div v-if="isFetchingExternal" class="single-item-view__item__value__content" >
                                <span class="loader-dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </div>
                            <div v-else class="single-item-view__item__value__content" v-html="getItemValue(item) || '-'"></div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="data?.length && isArrayPlain" class="modal__body modal__items-list">
                <div class="single-item-view">
                    <div v-for="value in data" :key="value" class="single-item-view__item">
                        
                        <div class="single-item-view__item__value">
                            <div class="single-item-view__item__value__content" v-html="getHtmlElement(element?.validation_rule, value)"></div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import modalMixin from '@/mixins/modalMixin.js'
import TextFormattingMixin from '@/mixins/textFormattingMixin.js'
import FormUtilsMixin from '@/mixins/formUtilsMixin.js'

export default {
    name: 'PropertyDetails',
    mixins: [modalMixin, TextFormattingMixin, FormUtilsMixin],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        modalData: {
            type: Object,
            required: true,
            default: () => ({})
        },

    },
    data() {
        return {
            localModalData: this.modalData,
            componentData: this.modalData?.component,
            element: this.modalData?.element,
            data: this.modalData?.data,
            isFetchingExternal: true
        }
    },
    computed: {
        ...mapState([
            'userUiElements'
        ]),
        isArrayPlain() {
            if(!this.data?.length) return;
            const array = this.data;
            for (let i = 0; i < array.length; i++) {
                if (typeof array[i] === 'object') {
                return false;
                }
            }
            return true;
        },
        lookupData() {
            return this.externalApiLookupData[this.element.id] || null;
        }
    },
    watch: {
        lookupData(newLookupData) {
                this.isFetchingExternal = newLookupData === null;
            }
    },
    methods: {
    closeModal() {
            this.$emit('closeModalPropertyDetails');
        },
    getItemValue(value) {
        const keyProperty = this.element?.validation_rule?.key_property;
        const itemDisplayProperty = this.element?.validation_rule?.item_display_property;
        if(this.lookupData && keyProperty) {
            const item = this.lookupData.find(item => item[keyProperty] == value)
            return item ? item[itemDisplayProperty] : value;
        } else
        return this.getHtmlElement(this.element?.validation_rule, value)
    },
    handleModalKeyDowns(event) {
            if(event.key === 'Escape') {
                this.closeModal();
            } else if(event.key === 'ArrowLeft' && this.localModalData.contentType === 'image-gallery' && this.localModalData.currItemIndex > 0) {
                this.localModalData.currItemIndex = this.localModalData.currItemIndex - 1;
            } else if(event.key === 'ArrowRight' && this.localModalData.contentType === 'image-gallery' && this.localModalData.currItemIndex < this.data.length - 1) {
                this.localModalData.currItemIndex = this.localModalData.currItemIndex + 1;
            }
        }
    },
    mounted() {
        this.centerModal();
        window.addEventListener('resize', this.handleResize);  // call mixin method
        window.addEventListener("keydown", this.handleModalKeyDowns);
        // this.addNoSelectClassToCards();

        const batchRequest = this.element?.validation_rule?.batch_request;
        if(batchRequest && Array.isArray(this.data) && this.data.length) {
            const batchIdList = this.data;
            this.fetchExternalApiLookup(this.element, batchIdList)
        }


    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);  // call mixin method
        window.removeEventListener("keydown", this.handleModalKeyDowns);
    },
//     addNoSelectClassToCards() {
//         const divElements = Object.values(this.$refs)
//         .flat()
//         .filter(ref => ref instanceof HTMLElement && ref.tagName.toLowerCase() === 'div' && ref.getAttribute('ref').startsWith('uiComponent-'));

//         divElements.forEach(element => {
//         element.classList.add('js-no-select');
//         });
//   }
};

</script>

<style scoped>
.modal-width {
    width:400px;
}

.disable-select,
.disable-select * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* :not(.modal__content),
:not(.modal__content) * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal__content {
    color:red !important;
} */

.modal__imageview-image {
    /* Disable selection */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.modal__imageview-image img {
    /* Prevent images from being dragged off the page */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.modal__items-list {
    display: flex;
    flex-direction: column;
    gap:32px;
    margin-top:12px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    /* width: fit-content;
    min-width: 360px;
    max-width: 400px; */

}

.modal__items-list-item {
    /* width: fit-content; */
    width: 100%;
}

.modal .single-item-view {
    width: 100%;


}


.modal .single-item-view__item:first-of-type > div {
    border-top: var(--border-width-small) solid var(--color-neutral-5);
    /* width: 100%; */
}

.modal .single-item-view__item > div {
    padding: 0;
}

.single-item-view__item__value__content:nth-of-type(2) {
    padding-left: 8px;
}

.modal__items-list-item--name {
    font: var(--font-headline-medium);
    color: var(--color-neutral-1);
    /* border-bottom: var(--border-width-small) solid var(--color-neutral-5); */
    padding-bottom: 12px;
    margin-right: 12px;
}

.modal .single-item-view__item__name {
 width: 33%;
 overflow: hidden;
}

</style>
