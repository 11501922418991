<template>
    <div class="page-nav" :class="'js-page-nav--' + (isCollapsed && !editMode ? 'collapsed' : 'expanded')" ref="nav">
        <button v-if="!editMode" type="button" class="page-nav__btn-expand" @click="isCollapsed = !isCollapsed">
            <i class="icon icon-chevron-left"></i>
        </button>
        <div class="page-nav__wrapper">
            <ul id="page-nav-container" class="page-nav__container js-page-naPage Av">
                <draggable 
                    v-model="appPages"
                    :options="{handle: '.js-page-nav-drag-handle'}" 
                    @change="handleReorder"
                    animation="300"
                    :disabled="!editMode"
                >
                    <transition-group type="transition" name="flip-list">
                        <li v-for="(page, index) in appPages" :key="page.id" :class="pageNavClass(page.id)">
                            <b class="page_nav__drag js-page-nav-drag-handle">
                                <i class="icon icon-reorder"></i>
                            </b>
                            <a @click="handleClickOnPage(page)">{{ page.name }}</a>
                            <!-- <div class="contextual-menu-placeholder"></div> -->
                            <div class="contextual-menu-wrapper">
                                <div id="contextual-menu-container" class="contextual-menu-container">
                                    <button @click="openContextMenu(index)" :class="btnToggleClass(index)" type="button">
                                        <i class="icon icon-dots-horizontal"></i>
                                    </button>
                                <ContextMenu 
                                    v-if="this.$store.state.showContextMenu && currentIndex == index"
                                    :page-id="page.id"
                                    @openModalRenamePage="openModalRenamePage" 
                                />
                                </div>
                            </div>
                        </li>
                    </transition-group>
                </draggable>
            </ul>
            <div class="page_nav__add-page-button-wrapper">
                <button @click="openModalAddRenamePage" type="button" class="btn btn-large btn-text-primary">
                    <i class="icon icon-plus"></i>
                    <span>Add page</span>
                </button>
            </div>
        </div>
    </div>

<ModalAddRenamePage v-if="this.$store.state.showModalAddRenamePage" @closeModalAddRenamePage="closeModalAddRenamePage" />
<ModalDelete v-if="this.$store.state.showModalDeletePage" />

</template>

<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import ModalAddRenamePage from './modals/AddRenamePageModal.vue'
import ContextMenu from './context-menu/ContextMenu.vue'
import ModalDelete from './modals/DeletePageModal.vue'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
    name: 'Navigation',
    components: {
        ModalAddRenamePage,
        ContextMenu,
        ModalDelete,
        draggable: VueDraggableNext,
    },
    data() {
        return {
            currentIndex: null,
            isCollapsed: localStorage.getItem('prefs.navbar-collapsed') === 'true' || false
        };
    },
    watch: {
    appPages: {
        immediate: true,
        handler() {
        this.updateNavWidth();
        },
    },
    editMode: {
        immediate: true,
        handler() {
        this.updateNavWidth();
        },
    },
    isCollapsed: {
        immediate: true,
        handler() {
        this.updateNavWidth();
        localStorage.setItem('prefs.navbar-collapsed', this.isCollapsed);
        },
    },
    },
    computed: {
        ...mapState([
            'editMode', 
            'appPages', 
            'selectedPage', 
            'showContextMenu', 
            'showModalAddRenamePage', 
            'showModalDeletePage', 
            'userUiComponents',
            'formData',
            'refreshExternalDataInComponent'
        ])
    },
    methods: {
        ...mapActions(['setSelectedPage']),
        updateNavWidth() {
            this.$nextTick(() => {
                const navWidth = this.$refs.nav.offsetWidth;
                this.$store.commit('setNavWidth', navWidth);
            });
        },
        handleClickOnPage(page) {
            this.$store.commit('setSelectedPage', page)
            this.$store.commit('setUserUiComponents', null)
            this.$store.commit('resetTableRowBindingView')
        },
        pageNavClass(pageId) {
            return pageId == this.$store.state.selectedPage?.id ?
                'page-nav__item page-nav__item--active js-page-nav-item' :
                'page-nav__item js-page-nav-item';
        },
        btnToggleClass(index) {
            return this.currentIndex == index && this.$store.state.showContextMenu ?
                'btn btn-toggle btn-toggle--active' :
                'btn btn-toggle';
        },
        openModalAddRenamePage() {
            this.$store.state.showModalAddRenamePage = true
        },
        closeModalAddRenamePage() {
            this.$store.state.showModalAddRenamePage = false
            this.$store.commit('modalTargetPageId', null) // reset modalTargetPageId
        },
        openContextMenu(index) {
            if (this.$store.state.showContextMenu && this.currentIndex == index) {
                this.$store.commit('showContextMenu', false)
                return
            }
            this.$store.commit('showContextMenu', true)
            this.currentIndex = index
        },
        handleReorder(event) { 
            this.updatePagePosition(event.moved.newIndex, event.moved.oldIndex)
        },
        updatePagePosition(newIndex, oldIndex) {
        // reposition the page immediately without waiting for the response from the API
        this.appPages.splice(newIndex, 0, this.appPages.splice(oldIndex, 1)[0])

        // console.log("Updating page position", this.appPages[newIndex].id, "to", newIndex + 1)
        
            axios.put('/pages.php', {
                id: this.appPages[newIndex].id,
                position: newIndex + 1
            }).then(() => { 
                // console.log('Page reordered', response);
                // refresh the list with updated positions once the API request is successful
                this.$store.dispatch('fetchPages')
            }).catch(error => {
                console.error(error.response);
            });
        }
    }

}

</script>

<style scoped>
.js-page-nav-item {
    transform: none !important;
}
.js-page-nav-drag-handle {
    /* cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; */
    cursor: move;
}
/* .js-page-nav-drag-handle:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
} */

.page-nav__item--active a {
    pointer-events: none;
}

.sortable-drag {
    opacity: 0;
}
.flip-list-move {
    transition: transform .5s;
}
.ghost {
    opacity: 0.5;
}
</style>


