import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import './axios'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'

// Load sensitive data from environment variables
const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vue3GoogleLogin, {
    clientId: googleClientId,
    buttonConfig: {
      color: 'white',
      width: 263,
      type: 'standard',
      size: 'medium',
      logo_alignment: 'center',
      text: 'signin_with',
      theme: 'outline',
      auto_select: true,
    },
  });

// Enable Vue Devtools in development
if (process.env.NODE_ENV === 'development') {
  app.config.devtools = true;
}

app.mount('#app');
