import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import logoutMixin from '@/mixins/logoutMixin.js';

// const { handleLogout } = logoutMixin;

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
      meta: { requiresAuth: true },
      mixins: [logoutMixin]
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
    }
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // console.log('Before: Navigated from:', from)
  // console.log('Before: Navigated to:', to)

  if (requiresAuth && !isLoggedIn) {
    logoutMixin.methods.handleLogout();
    next({ name: 'Login' });
  } else {
    next();

  }
});

// router.afterEach((to, from) => {
//   console.log('After: Navigated from:', from)
//   console.log('After: Navigated to:', to)

// })
