<template>
    <!-- <pre style="height: 200px; overflow-y: scroll;">externalApiLookupData: -->
        <!-- {{ externalApiLookupData }} -->
        <!-- <pre v-for="(value, key) in externalApiLookupData" :key="key" :get-element="el = elementsCurrentComponent.find(item => item.id == key)">{{ key }} {{ el.display_name }}</pre> -->
    <!-- </pre> -->
    <!-- <pre style="height: 150px; overflow-y: scroll;">nestedInComponent:
        {{ nestedInComponent }}
    </pre>  -->
    <!-- <pre>linkingPropPath: {{ linkingPropPath }}</pre> -->
    <!-- <pre>editMode: {{ editMode }}</pre> -->
    <!-- <pre>selectedElementId: {{ selectedElementId }}</pre> -->
    <!-- <pre>formType: {{ formType }}</pre> -->
    <!-- <pre>modalViewEnabled: {{ modalViewEnabled }}</pre> -->
    <!-- <pre>isFetchingExternalData: {{ isFetchingExternalData }}</pre> -->
    <!-- <pre style="height: 150px; overflow-y: scroll;">componentData:
        {{ componentData }}
    </pre>  -->
    <!-- <pre style="height: 150px; overflow-y: scroll;">binding:
        {{ binding }}
    </pre>  -->
    <!-- <pre style="height: 150px; overflow-y: scroll;">tableRowBindingView:
        {{ tableRowBindingView }}
    </pre>  -->
    <!-- <pre>externalApiData: {{ externalApiData }}</pre> -->
    <!-- <pre v-if="externalApiData && Object.keys(externalApiData).length">externalApiData.length: {{ Object.keys(externalApiData).length}}</pre> -->
 
    <!-- <pre>externalApiLookupData: {{ externalApiLookupData }}</pre> -->
            <!-- <pre style="font-size:0.85em; max-height: 200px; overflow: scroll;">externalApiLookupData[2248]: {{ externalApiLookupData[2248] }}</pre> -->
            <!-- <pre style="font-size:0.85em">formNewValues: {{ formNewValues }}</pre> -->
            <!-- <pre style="font-size:0.85em">formData: {{ formData }}</pre> -->
            <!-- <pre>externalApiLookupData: {{ externalApiLookupData }}</pre> -->
            <!-- <pre>formValidationErrors: {{ formValidationErrors }}</pre> -->
            <!-- <pre>multiselectDrodpownOpen: {{ multiselectDrodpownOpen }}</pre> -->
            <!-- <pre>multiselectFilterSearchTerm: {{ multiselectFilterSearchTerm }}</pre> -->
    <!-- <pre v-if="userUiElements">{{ userUiElements }}</pre> -->
    <!-- <pre>{{ elementsCurrentComponent }}</pre> -->
    <!-- <pre>{{ elementsCurrentComponent.filter(item => item.data_type === 'api') }}</pre> -->
    <!-- <pre v-if="selectedUiElement">{{ selectedUiElement?.id }}</pre> -->
    <!-- <pre>tableRowBindingView: {{ tableRowBindingView }}</pre> -->
    <!-- <pre v-if="formSubmissionStatus">formSubmissionStatus: {{ formSubmissionStatus }}</pre> -->
    <!-- <pre>refreshExternalDataInComponent: {{ refreshExternalDataInComponent }}</pre> -->
    <!-- <pre>selectedPage: {{ selectedPage }}</pre> -->
<div v-if="!!editMode || (selectedElementId && formType === 'edit') || (formType === 'create' && !componentData.resource_is_nested) || (selectedElementId && formType === 'create' && componentData.resource_is_nested)">
    <draggable 
        v-if="(formType === 'edit' && !editMode && externalApiData && Object.keys(externalApiData).length)
              || (formType === 'edit' && editMode)
              || formType === 'create'"
        v-model="elementsCurrentComponent" 
        ghost-class="ghost" 
        animation="300" 
        filter=".js-no-drag"
        :disabled="!editMode" 
        @change="handleReorder" 
        class="form-components"
        :class="{ 'form-components--disabled': refreshExternalDataInComponent[componentData.id] || formSubmissionStatus?.status === 'submitting'}"
    >
        <transition-group type="transition" name="flip-list">
            <div v-for="element in elementsCurrentComponent" :key="element.id" @click="handleItemSelection($event, element)"
                :class="'form-components__item js-form-components-item' +
                    (element.id === selectedUiElement?.id ? ' js-form-components-item-selected' : ' js-no-drag')"
                ref="formView">

                <!-- Texfield Date -->
                <div v-if="element?.operation === 'input-date'" 
                    class="textfield textfield-large textfield-outlined"
                    :class="{'error' : formValidationErrors[element.id]}"
                >
                    <div class="textfield-header">
                        <label :title="element?.description">{{ element.display_name }} {{ element.id }}</label>
                    </div>
                    <input 
                        type="text" 
                        v-maska 
                        :data-maska="getDataMaska(element) || '####-##-##'" 
                        data-maska-eager
                        @change="updateFormValues($event.target.value, element)"
                        :placeholder="element.validation_rule?.user_input_format ? element.validation_rule?.user_input_format.replace(/'/g, '') : 'yyyy-MM-dd'"
                        @blur="validateFormInput(element)"
                        :value="setFormValue(element)" 

                    >
                    <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                </div>
                <!-- / Texfield Date -->

                <!-- Texfield Number -->
                <div v-else-if="element?.operation === 'input-number'" 
                     class="textfield textfield-large textfield-outlined"
                     :class="{'error' : formValidationErrors[element.id]}"
                >
                    <div class="textfield-header">
                        <label :title="element?.description">{{ element.display_name }} {{ element.id }}</label>
                    </div>
                    <input 
                        type="text" 
                        v-maska 
                        :data-maska="getDataMaska(element)"
                        data-maska-tokens="-:-:optional|0:\d:multiple|9:\d:optional"
                        :placeholder="element.example_value"
                        @change="updateFormValues($event.target.value, element)"
                        @blur="validateFormInput(element)"
                        :value="setFormValue(element)" 

                    >
                    <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                </div>
                <!-- / Texfield Number-->

                <!-- Texfield -->
                <div v-else-if="getElementFormat(element) === 'textfield' && element?.data_type === 'string'" 
                     class="textfield textfield-large textfield-outlined"
                     :class="{'error' : formValidationErrors[element.id]}"
                >
                    <div class="textfield-header">
                        <label :title="element?.description">{{ element.display_name }} {{ element.id }}</label>
                    </div>
                    <input 
                        type="text" 
                        :placeholder="element.example_value"
                        @change="updateFormValues($event.target.value, element)"
                        @blur="validateFormInput(element)"
                        :value="setFormValue(element)" 

                    >
                    <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                </div>
                <!-- / Texfield -->

                <!-- Textarea -->
                <div v-else-if="getElementFormat(element) === 'textarea'" 
                    class="textarea textarea-outlined"
                    :class="{'error' : formValidationErrors[element.id]}"
                    :content="content = getPropertyValue(element)"
                >
                    <div class="textarea-header">
                        <label :title="element?.description">{{ element.display_name }} {{ element.id }}</label>
                        <span class="textarea-counter" style="display:none">0/1000</span>
                    </div>

                    <textarea 
                        :placeholder="element.example_value"
                        @change="updateFormValues($event.target.value, element)"
                        @blur="validateFormInput(element)"
                        :value="setFormValue(element)" 
                    >
                    </textarea>

                    <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                </div>
                <!-- / Textarea -->

                <!-- Rich Editor -->
                <div v-else-if="getElementFormat(element) === 'rich_editor'" 
                    class="rich-editor"
                    :class="{'error' : formValidationErrors[element.id]}"
                    :content="content = getPropertyValue(element)"
                >
                <div class="rich-editor-header">
                    <label :title="element?.description">{{ element.display_name }}</label>
                </div>

                    <QuillEditor 
                            contentType="html" 
                            ref="quillEditor"
                            :class="'quill-editor-' + componentData.id"
                            :placeholder="element.example_value"
                            :options="editorOptions"
                            :toolbar="[['bold', 'italic'], [{ 'list': 'ordered'}, { 'list': 'bullet' }, 'link'],  ['clean']]"
                            :content="setFormValue(element) ? setFormValue(element).trim() : ''"
                            @blur="validateFormInput(element)"
                            @update:content="updateFormValues(removeEmptyTags($event), element)"
                    />
            
                    <div v-if="formValidationErrors[element.id]" class="error-msg error-msg-quill">{{ formValidationErrors[element.id] }}</div>
                </div>
                <!-- / Rich Editor -->

                <!-- ## SELECTS ## -->

                <!-- Checkbox Group -->
                <div v-else-if="element.data_type === 'array' && getElementFormat(element) === 'checkbox_group'"
                    :getCurrValues="currValues = setFormValue(element)"
                    class="form-components__item js-form-components-item">
                    <div class="checkbox-group">
                        <fieldset>
                            <legend :title="element?.description">{{ element.display_name }} {{ element.id }}</legend>
                            <div v-for="(item, index) in element.validation_rule?.enum" :key="index" class="checkbox">
                                <input type="checkbox" 
                                    :id="index" 
                                    :name="index" 
                                    :value="index"
                                    @change="updateFormValues($event.target.value, element);  validateFormInput(element)"
                                    :checked="checkIsValueSelected(index, element, currValues)"
                                >
                                <label :for="index"> {{ item }}</label>
                            </div>
                        </fieldset>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                </div>
                <!-- / Checkbox Group -->

                <!-- Radio -->
                <div v-else-if="element.data_type === 'array' && getElementFormat(element) === 'radio'" 
                    class="form-components__item js-form-components-item">
                    <div class="radio-group">
                        <fieldset>
                            <legend :title="element?.description">{{ element.display_name }} {{  getPropertyValue(element) }}</legend>
                            <div 
                                v-for="(item, index) in element.validation_rule?.enum" 
                                class="radio"
                                :key="index"
                            >
                            <input 
                                type="radio" 
                                :id="element.id + '-' + index" 
                                :name="element.id"
                                :value="index" 
                                @change="updateFormValues($event.target.value, element); validateFormInput(element)"
                                :checked="setFormValue(element) === index"
                                >

                                <label :for="element.id + '-' + index">{{ item }}</label>
                            </div>
                        </fieldset>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                </div>
                <!-- / Radio -->

                <!-- Select -->
                <div v-else-if="element.data_type === 'array' && element.operation !== 'multi-select' && (element.validation_rule?.max_items === 1 || !element.validation_rule?.max_items)"
                    class="form-components__item js-form-components-item"
                    >
                    <div class="select select-large select-outlined select-native" :class="{'error' : formValidationErrors[element.id]}">
                        <label :title="element?.description">{{ element.display_name }} {{ element.id }}</label>
                        <select @change="updateFormValues($event.target.value, element); validateFormInput(element)"
                                :class="{'disabled-selected': !(element.id in formNewValues || getPropertyValue(element))}">
                        <!-- <select @change="updateFormValues($event.target.value, element); validateFormInput(element)"
                                :class="{'disabled-selected': !(element.id in formNewValues || getPropertyValue(element))}"> -->
                            <option value="" :selected="!(element.id in formNewValues)" disabled>Select</option>
                            <option v-for="(item, index) in element.validation_rule?.enum" 
                                    :key="index" 
                                    :value="index"
                                    :selected="setFormValue(element) === index"
                            >
                            {{ item }}
                            </option>
                        </select>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                </div>
                <!-- / Select -->

                <!-- ## API TYPE ELEMENTS  ## -->
                
                <!-- Multiselect -->
                <div v-else-if="element.operation === 'multi-select'" class="form-components__item js-form-components-item">
                    <div :getCurrValues="currValues = setFormValue(element)" 
                         :getPlaceholder = "placeholder = getMultiselectPlaceholder(element, currValues)"
                         :getType="componentType = element.validation_rule?.max_items > 1 ? 'checkbox' : 'radio'"
                         class="multiselect multiselect-large multiselect-wrapper"
                         :class="' multiselect-wrapper__width--' + (element.validation_rule?.width || 'stretch') 
                                + (formValidationErrors[element.id] ? ' error' : '')"
                    >
                        <div class="multiselect__label" :title="element?.description">
                            {{ element.display_name }} {{ element.id }} 
                        </div>
                        <div @click="toggleMultiselectDropdown($event, element)" 
                            class="multiselect__select-box"
                            :class="{'js-btn-active': multiselectDrodpownOpen === element.id}"
                        >
                        <div class="multiselect__select-value" 
                                :class="['Select', 'Loading...'].includes(placeholder) ? 'multiselect__no-selection' : ''"
                            >
                                    {{ placeholder }}
                            </div>
                            <div><i class="icon icon-chevron-down"></i></div>
                        </div>
                        <div v-if="multiselectDrodpownOpen === element.id" class="multiselect__menu">
                            <div v-if="shouldDisplaySearchBar(element)" class="multiselect__search">
                                <div class="multiselect__textfield textfield textfield-small textfield-outlined textfield-search">
                                    <button @click="multiselectFilterSearchTerm[element.id] = ''" class="textfield__btn btn btn-toggle">
                                        <i class="icon icon-close"></i>
                                    </button>
                                    <input
                                    placeholder="Search" type="text" v-model="multiselectFilterSearchTerm[element.id]" @click="selectAllInputText($event); multiselectFilterSearchTerm[element.id] = ''">
                                </div>
                            </div>
                            <div v-if="(element.validation_rule?.show_select_controls || element.validation_rule?.show_display_controls) && element.validation_rule?.max_items > 1" class="multiselect__ops">
                                <div class="multiselect__ops--controls">
                                    <div v-if="element.validation_rule?.show_select_controls" class="multiselect__ops--links">
                                        <a @click="selectAllOptionsMultiselect(element)">Select all</a>
                                        <span class="multiselect__ops-block--sep">|</span>
                                        <a @click="clearAllOptionsMultiselect(element)">Clear all</a>
                                    </div>
                                    <div v-if="element.validation_rule?.show_display_controls 
                                            && ((formNewValues[element.id] && formNewValues[element.id].length)
                                                || (currValues && currValues.length))" class="multiselect__ops--block"
                                    >
                                        
                                        <div class="multiselect__ops--links">
                                            <a @click="multiselectDisplaySelectedOnly[element.id] = false"
                                                :class="{'multiselect__ops--links-active': !multiselectDisplaySelectedOnly[element.id]}"
                                            >Show all</a>
                                            <span class="multiselect__ops-block--sep">|</span>
                                            <a @click="multiselectDisplaySelectedOnly[element.id] = true"
                                                :class="{'multiselect__ops--links-active': multiselectDisplaySelectedOnly[element.id]}"
                                            >
                                                Selected 
                                                ({{ formNewValues[element.id] && formNewValues[element.id].length || 0 }})
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else></div>
                                </div>
                            </div>
                            <div v-if="element.validation_rule?.hierarchical && !multiselectHasNoResults(element)" class="multiselect__list__curr-path">
                                {{ currentPath || (element.validation_rule?.none_option?.name || (element.validation_rule?.max_items === 1 ? 'None' : '') )  }}
                            </div>
                            <div class="multiselect__list" :get-items="items = filterLookupItems(element, currValues)">
                                <div v-if="element.validation_rule?.none_option && !multiselectHasNoResults(element)  && !this.multiselectFilterSearchTerm[element.id]" 
                                        :class="componentType + ' multiselect__item'"
                                        @click="removeElementFromFormValues(element); closeDropdown()" 
                                        @mouseover="element.validation_rule?.hierarchical ? delayedCall(() => currentPath = (element.validation_rule?.none_option?.name || 'None' ), 350) : ''"
                                        >
                                    <input :type="componentType" 
                                        :id="element.id + '-unselected'" 
                                        :name="element.id" 
                                        :value="element.validation_rule?.none_option?.value || ''"
                                        :checked="!formNewValues[element.id] || formNewValues[element.id]?.length === 0">
                                    <label :for="element.id + '-unselected'" @click.stop>{{ element.validation_rule?.none_option?.name || 'None' }}</label>
                                </div>
                                <div v-for="(item, index) in items" 
                                            :key="index"
                                            :class="componentType + ' multiselect__item'"
                                            @click="updateFormValues(getMultiselectItemValue(item, index, element), element, item); validateFormInput(element); componentType === 'radio' ? closeDropdown() : ''" 
                                            @mouseover="element.validation_rule?.hierarchical ? delayedCall(() => getPath(item, element, items), 350) : ''"
                                >
                                    <input :type="componentType" 
                                        :id="element.id + '-' + index" 
                                        :name="element.id" 
                                        :value="getMultiselectItemValue(item, index, element)"
                                        :checked="checkIsValueSelected(item, index, element, currValues)"
                                        :style="{ 'margin-left': item.depth ? (item.depth * 8) + 'px' : '0px' }"
                                    >
                                    <label :for="element.id + '-' + index" @click.stop>{{ getDisplayFormat(element, item) }}</label>
                                </div>
                                <div v-if="multiselectHasNoResults(element)" class="multiselect__list--empty-state">
                                    No results
                                </div>
                            </div>
                        </div>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                </div>
                <!-- / Multiselect -->

                <!-- ## BOOLEAN TYPE ELEMENTS  ## -->

                <!-- Boolean: Radio Group -->
                <div v-else-if="element.data_type === 'boolean' && getElementFormat(element) === 'radio'" 
                    class="form-components__item js-form-components-item"
                >
                    <div class="radio-group">
                        <fieldset>
                            <legend :title="element?.description">{{ element.display_name }} {{ element.id }}</legend>
                            <div class="radio">
                                <input 
                                    type="radio" 
                                    :name="element.id"
                                    :id="element.id + 'true'" 
                                    value="true" 
                                    :checked="setFormValue(element) === true"
                                    @change="updateFormValues(true, element); validateFormInput(element)"

                                >
                                <label :for="element.id + 'true'">Yes</label>
                            </div>
                            <div class="radio">
                                <input 
                                    type="radio" 
                                    :name="element.id"
                                    :id="element.id + 'false'" 
                                    value="false" 
                                    :checked="setFormValue(element) === false"
                                    @change="updateFormValues(false, element); validateFormInput(element)"

                                >
                                <label :for="element.id + 'false'">No</label>
                            </div>
                        </fieldset>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                </div>
                <!-- / Boolean: Radio Group -->

                <!-- Boolean: Select -->
                    <div v-else-if="element.data_type === 'boolean' && getElementFormat(element) === 'select'"  
                        class="form-components__item js-form-components-item"
                    >
                    <div class="select select-large select-outlined" :class="{'error' : formValidationErrors[element.id]}">
                        <label for="select" :title="element?.description">{{ element.display_name }}</label>
                        <select @change="updateFormValues(($event.target.value === 'true' ? true : false), element); validateFormInput(element)">
                        <!-- <select @change="updateFormValues(($event.target.value === 'true' ? true : false), element); validateFormInput(element)"
                            :class="{'disabled-selected': !(element.id in formNewValues)}"> -->
                            <option value="" :selected="!(element.id in formNewValues)" disabled>Select</option>
                            <option value="true" :selected="setFormValue(element) === true">Yes</option>
                            <option value="false" :selected="setFormValue(element) === false">No</option>
                        </select>
                        <div v-if="formValidationErrors[element.id]" class="error-msg">{{ formValidationErrors[element.id] }}</div>
                    </div>
                    </div>
                <!-- / Boolean: Select -->

                <!-- Boolean: Switch -->
                <div v-else-if="getElementFormat(element) === 'switch'" 
                    class="switch switch-outlined"
                >
                    <span class="switch-label" :title="element?.description">{{ element.display_name }} {{ element.id }}</span>
                    <label class="switch-controller">
                        <input 
                            type="checkbox"
                            @change="updateFormValues($event.target.checked, element); validateFormInput(element)"
                            :checked="setFormValue(element)"
                        >
                        <span class="switch-slider"></span>
                    </label>
                </div>
                <!-- / Boolean: Switch -->

                <div v-else class="alert-error">Unsupported element (ID: {{ element.id }})</div>
                <!-- <div v-if="index == elementsCurrentComponent.length - 1" :emit="this.$emit('render-complete')">
                    <div>{{ index }}</div>
                </div> -->
            </div>
        </transition-group>
    </draggable>

        <div v-if="elementsCurrentComponent?.length > 0 
                && ((externalApiData && Object.keys(externalApiData).length)
                || formType === 'create')
                " class="form-components__submit-container">

            <!-- Alert -->
            <div v-if="showAlert && Object.keys(formValidationErrors).length" :class="'alert alert-' + (formSubmissionStatus?.status === 'error' ? 'error' : 'success') ">
                <span>{{ formSubmissionStatus.message }}</span>
                <button @click="closeAlert()" type="button" class="btn btn-toggle">
                    <i class="icon icon-close"></i>
                </button>
            </div>
            <!-- / Alert -->

            <!-- Button Submit -->
            <div class="form-components__submit-container">
                <div class="form-components__submit-btns">
                    <button 
                        @click="submitForm()" 
                        :class="'btn btn-large btn-contained-primary' + 
                        (formSubmissionStatus?.status === 'submitting' ? ' btn-loading' : '')"
                        :disabled="!isFormDataNotEmpty"
                        >
                        <i v-if="formSubmissionStatus?.status === 'submitting'" class="icon icon-loader-circle"></i>
                        <span>{{ formSubmitButtonLabel }}</span>
                    </button>
                    <button v-if="formType === 'create'"
                        @click="clearForm()" 
                        type="button" 
                        class="btn btn-large btn-contained-subtle"
                        >
                        <span>Reset</span>
                    </button>
                </div>
                <!-- <div v-if="showSuccessMessage" class="form-components__submit-status">
                    <i class="icon icon-check"></i>
                    <span>Entry {{ formType === 'edit' ? 'updated' : 'created' }}</span>
                </div> -->
            </div>
            <!-- / Button Submit -->

        </div>
    </div>

    <div v-if="!selectedElementId && !editMode && formType === 'edit' && componentData?.linked_component_id" class="card__empty">
            <div class="card__empty__first-row"> 
                <span>Click</span>
                <i class="icon icon-rename"></i>
                <span>button on a row in</span>
                <b v-if="sourceComponentTitle">{{ sourceComponentTitle }}</b>
                <span>to edit entry here</span>
            </div>
    </div>

    <div v-if="!selectedElementId && !editMode && formType === 'create' && nestedInComponent" class="card__empty">
            <div class="card__empty__first-row"> 
                <span>Click on a row in</span>
                <b v-if="sourceComponentTitle">{{ nestedInComponent.title }}</b>
                <span>table to indicate the parent item</span>
            </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { VueDraggableNext } from 'vue-draggable-next'
import GenericMixin from '@/mixins/genericMixin.js'
import FormUtilsMixin from '@/mixins/formUtilsMixin.js'
import { QuillEditor } from '@vueup/vue-quill'
import 'quill-paste-smart'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import { vMaska } from "maska"

export default {
    name: 'Form',
    mixins: [GenericMixin, FormUtilsMixin],
    components: {
        draggable: VueDraggableNext,
        QuillEditor
    },
    directives: { maska: vMaska },
    props: {
        componentData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            externalApiData: null,
            lastSelectedElementIdUpdate: null,
            showAlert: false,
            showSuccessMessage: false,
            formData: {},
            formNewValues: {},
            formValidationErrors: {},
            multiselectFilterSearchTerm: {},
            multiselectDisplaySelectedOnly: {},
            multiselectDrodpownOpen: null,
            formSubmissionStatus: {
                status: '',
                message: ''
            },
            isFetchingExternalData: false,
            editorOptions: {
                modules: {
                    matchVisual: false, // Disable matchVisual option,
                    trimOnInitialize: true // Set trimOnInitialize to true
                },
                preserveWhitespace: false,
                // debug: 'info',
            },
            currentPath: ''
        }
    },

    computed: {
        ...mapState([
            'userUiElements',
            'userUiComponents',
            'selectedUiElement',
            'tableRowBindingView',
            'refreshExternalDataInComponent',
            'editMode',
            'selectedPage',
            'modalComponentData'
        ]),
        componentSettings() {
            return this.getComponentSettings(this.componentData); // call Generic mixin
        },
        modalViewEnabled() {
            return (this.componentSettings && this.componentSettings.modalViewEnabled) ? true : false;
        },
        nestedInComponent() {
            const linkedComponentId = this.componentData?.linked_component_id;
            const linkedComponent = linkedComponentId && this.userUiComponents?.find(component => component.id === linkedComponentId);
            const nestedInComponentId = linkedComponent?.linked_component_id;
            return nestedInComponentId ? this.userUiComponents?.find(component => component.id === nestedInComponentId) : null;
        },
        binding() {
            const resourceIsNested = this.componentData?.resource_is_nested;

            if (this.formType === 'edit' && this.tableRowBindingView) {
            return this.tableRowBindingView.find(component =>
                component.sourceComponentId == this.componentData?.linked_component_id
                && component.operation === 'edit'
            );
            }

            if (this.formType === 'create' && resourceIsNested && this.nestedInComponent && this.tableRowBindingView) {
            return this.tableRowBindingView.find(component =>
                component.sourceComponentId == this.nestedInComponent.id
                && component.operation === 'view'
            );
            }

            return null;
        },
        selectedElementId() {
            return this.binding?.linkedElementData[this.linkingPropPath];
        },
        linkingPropPath() {
            return this.componentData?.link_with_property_path;
        },
        sourceComponentTitle() {
            const component = this.userUiComponents?.find(component => component.id === this.componentData?.linked_component_id);
            return component?.title;
        },
        isFormDataNotEmpty() {
            return Object.keys(this.formData).length;
        },
        formSubmitButtonLabel() {
            if (this.formSubmissionStatus?.status === 'submitting') {
                return this.formType === 'edit' ? 'Updating...' : 'Submitting...';
            }
            return `${this.formType === 'edit' ? 'Update' : 'Create'}`;
        },
        requestMethod() {
            if(this.elementsCurrentComponent.length === 0) return null; 
            return this.elementsCurrentComponent[0].resource_request_method || this.elementsCurrentComponent[0].resource_method
        },
    },
    watch: {
        editMode: {
            immediate: true,
            handler: function (newVal) {
                if (!newVal) {
                    this.$store.commit('setSelectedUiElement', null);
                    document.removeEventListener('keydown', this.handleDeleteKeyDown);
                    document.removeEventListener('click', this.handleOutsideClick);
                }
            }
        },
        externalApiData() {
                this.formData = {}
        },
        selectedElementId() {
            if (!this.modalViewEnabled) {         
                this.fetchExternalApiData(); 
            }
        },
        showSuccessMessage(newVal) {
            if (newVal && newVal === true) {
                setTimeout(() => {
                    this.showSuccessMessage = false;
                }, 5000);
            }
        },
        formData: {
            immediate: true,
            handler: function () {
                this.$store.commit('setFormData', this.formData);
            },
            deep: true
        },
    },
    methods: {
        clearForm() {
            Promise.resolve().then(() => {
                this.clearQuillEditor();
            }).then(() => {
                // Object.keys(this.formData).forEach(key => delete this.formData[key]);
                // Object.keys(this.formNewValues).forEach(key => delete this.formNewValues[key]);
                this.formData = {};
                this.formNewValues = {};
            })

            setTimeout(() => {
                this.formData = {};
                this.formNewValues = {};
            }, 100);
        },
        clearQuillEditor() {
            const quill = Array.from(document.getElementsByClassName('quill-editor-' + this.componentData.id));
            // console.log('quill', quill[0].__quill);
            quill.forEach(element => {
                element.__quill.root.innerHTML = '\n';
            });
        },
        async submitForm() {
            if(!this.isFormDataNotEmpty) {
                return;
            }

            for(const element of this.elementsCurrentComponent) {
                this.validateFormInput(element);
            }

            if(Object.keys(this.formValidationErrors).length) {
                this.formSubmissionStatus = {
                    status: 'error',
                    message: 'Empty or invalid fields found. Check and try again.'
                }
                this.showAlert = true;
                return;
            } 

            this.showAlert = false;

            this.formSubmissionStatus = {
                    status: 'submitting',
                    message: '',
                }
            this.showSuccessMessage = false;

            // Build a list of item ids to fetch from the external API
            let itemIds = '';
            let itemVariables = [...(this.binding?.parentItemVariables || [])]; // Create a copy of parentItemVariables
            itemVariables.push(this.selectedElementId);
            itemIds = itemVariables.join(',');

            try {

                let payload;
                if (this.formType === 'edit') {
                    payload = {
                        component_id: this.componentData.id,
                        item_id: itemIds, // this.selectedElementId,
                        data: this.formData
                    };
                } else {
                    payload = {
                        resource_id : this.resourceId,
                        component_id: this.componentData.id,
                        ...(itemIds && { item_id: itemIds }), // Include item_id only if itemIds is not empty
                        data: this.formData
                    };
                }

                // console.log('payload', payload);

                const response = await axios({
                    method: this.requestMethod,
                    url: '/api_data.php',
                    data: payload
                });

                if (response.status === 200 || response.status === 201) {
                    // console.log('Entry ' + (response.status === 201 ? 'created' : 'updated'), response)
                    this.formSubmissionStatus = {
                        status: '',
                        message: '',
                    }
                    this.showSuccessMessage = true;
                    this.formData = {};
                    this.$store.commit('setFormData', {});
                    this.$store.commit('requestRefreshExternalDataInComponent', { componentId: this.componentData?.linked_component_id, value: true });

                    // If the form is of nested component, refresh the data in the parent component
                    if(this.nestedInComponent) {
                        this.$store.commit('requestRefreshExternalDataInComponent', { componentId: this.nestedInComponent?.id, value: true });
                    }

                    this.fetchExternalApiData();
                    this.$store.commit('addNotification', { 
                        componentId: this.componentData.id,
                        type: 'success',
                        title: 'Entry ' + (response.status === 201 ? 'created' : 'updated') + ' in "' + this.componentData.title + '"',
                    });
                    this.$store.commit('setModalComponentData', null)
                }

            } catch (error) {
                console.log(error.response);
                const message = error.response.data?.message || error.response.data;
                this.formSubmissionStatus = {
                        status: 'error',
                        message: message
                    }
                this.$store.commit('addNotification', { 
                        componentId: this.componentData.id,
                        type: 'error',
                        title: `Unable to save data in "${this.componentData.title}"`,
                        message: message
                    });
                this.showAlert = true
            }
        },
        closeAlert() {
            this.showAlert = false
        },
        getElementFormat(element) {
            // console.log(element)
            const operation = element.operation;
            const userDisplayFormat = element.user_display_format;
            // const validationRule = element.validation_rule;

            switch (element.data_type) {
                case 'string':
                    if (operation === 'textarea' || userDisplayFormat === 'textarea') {
                        return 'textarea';
                    }
                    else if (operation === 'input-html' || userDisplayFormat === 'rich_editor') {
                        return 'rich_editor';
                    }
                     else {
                        return 'textfield';
                    }
                case 'boolean':
                    if (userDisplayFormat === 'radio') {
                        return 'radio';
                        }
                    else if (userDisplayFormat === 'select') {
                        return 'select';
                        }
                    else {
                        return 'switch';
                        }
                case 'array':
                    if (userDisplayFormat === 'checkbox_group') {
                        return 'checkbox_group';
                        }
                    else if (userDisplayFormat === 'radio') {
                        return 'radio';
                        }
                    else {
                        return 'select';
                        }
                default:
                    return null;
            }
        },
        handleItemSelection(event, item) {
            if (!this.$store.state.editMode) {
                return;
            }
            this.$store.commit('setSelectedUiElement', item);
            //set focus on the element
            const element = event.target.closest('.js-form-components-item');
            element.setAttribute('tabindex', '0');
            element.focus();
            // set focus again after a short delay to ensure focus is set
            setTimeout(() => {
                element.focus();
            }, 10);
            // console.log(document.activeElement)
            document.addEventListener('keydown', this.handleDeleteKeyDown);
        },
        openModalToConfirmDeletion() {
            if (this.selectedUiElement) {
                this.$store.commit('showModalDeleteUserUiElement', true);
            }
        },
        handleDeleteKeyDown(event) {
            const keys = ['Backspace', 'Delete'];
            const rightSidebar = event.target.closest('.right-sidebar');
            const elementInFocus = document.activeElement;
            // Check if the element in focus has the class
            if (elementInFocus.classList.contains('.right-sidebar')) return;
            // Check if the element in focus is a form element
            if (elementInFocus.tagName == "INPUT" 
                || elementInFocus.tagName == "SELECT" 
                || elementInFocus.tagName == "TEXTAREA") return;
                
            if (keys.includes(event.key) && !rightSidebar) {
                this.openModalToConfirmDeletion();
            }
        },
        handleOutsideClick(event) {
            if (!this.editMode) {
                return;
            }
            const element = event.target.closest('.js-form-components-item');
            const container = event.target.closest('.js-card-form-components');

            if (!element && container) {
                this.$store.commit('setSelectedUiElement', null);
            }

            if (!element) {
                document.removeEventListener('keydown', this.handleDeleteKeyDown);
            }
        },
        handleReorder(event) {
            let position = event.moved.newIndex + 1
            this.$store.dispatch('updateUiElement', { position: position });
        },
        mapKeys(data, mapping) {
            const result = {};
            
            for (const key in data) {
                const mappedKey = mapping[key] || `_${key}`;
                result[mappedKey] = data[key];
            }

            return result;
        },
        async fetchExternalApiData() {
            if (this.isFetchingExternalData) {
                return;
            }

            if (!this.componentData || !this.selectedElementId || this.formSubmissionStatus?.status === 'submitting') {
                return;
            }
            this.isFetchingExternalData = true;
            // this.externalApiData = null;
            this.clearForm();
            this.$store.commit('requestRefreshExternalDataInComponent', { componentId : this.componentData.id, value: true });

            // Build a list of item ids to fetch from the external API
            let itemIds = '';
            let itemVariables = [...(this.binding?.parentItemVariables || [])]; // Create a copy of parentItemVariables
            itemVariables.push(this.selectedElementId);
            itemIds = itemVariables.join(',');

            const apiUrl = `/api_data.php?resource_id=${this.resourceId}&component_id=${this.componentData.id}&item_id=${itemIds}`;
            // console.log('isFetchingExternalData: ', this.isFetchingExternalData);
            // console.log('Form: fetching external data from apiUrl', apiUrl, 'for component', this.componentData.id);
            await axios.get(apiUrl)
                .then(response => {
                    if (response.status === 200) {
                        // console.log("Form: externalApiData response for component ", this.componentData.id, ", ", response.data.body);
                        this.externalApiData = response.data.body;
                        this.$store.commit('requestRefreshExternalDataInComponent', { componentId : this.componentData.id, value: false });
                        this.populateApiLookupData();
                        if(this.componentData?.get_resource_property_mapping) {
                            this.externalApiData = this.mapKeys(this.externalApiData, this.componentData.get_resource_property_mapping);
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response.data);
                        // const message = error.response.data?.message || error.response.data;
                        let message = null;

                        if (typeof error.response.data.message === 'string' && error.response.data.message) {
                            message = error.response.data.message;
                        } 
                        this.$store.commit('addNotification', { 
                            componentId: this.componentData.id,
                            type: 'error',
                            title: `Unable to load data in "${this.componentData.title}"`,
                            message: message
                        });
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isFetchingExternalData = false;
                });

        },
    },
    async mounted() {
        document.addEventListener('click', this.handleOutsideClick);

        if(this.modalViewEnabled) {
            this.fetchExternalApiData();
        }

        if (this.componentData) {
            this.$store.dispatch('fetchUserUiElements', { componentId: this.componentData.id });
        }
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }
}

</script>

<style scoped>
</style>
