<template>
    <!-- <pre>{{ componentOperationType }}</pre> -->
    <!-- <pre v-if="userUiComponents">{{ userUiComponents }}</pre> -->
    <!-- <pre>settings: {{ settings }}</pre> -->
    <!-- <pre>selectedComponent: {{ selectedComponent }}</pre> -->
    <!-- <pre v-if="uiElements">{{ uiElements }}</pre> -->
    <!-- <pre>viewEntryCompatibleComponents: {{ viewEntryCompatibleComponents }}</pre> -->
    <!-- <pre>linkableResources: {{` linkableResources }}</pre> -->
    <!-- <pre style="font-size:0.85em">linkableComponents: {{linkableComponents }}</pre> -->
    <!-- <pre>resourceSelectedComponent: {{resourceSelectedComponent }}</pre> -->
    <!-- <pre v-if="userUiElements">{{ userUiElements }}</pre> -->
    <!-- <pre>userApiResources: {{ userApiResources }}</pre> -->
    <!-- <pre v-if="userUiElements">{{ userUiElements[selectedUiComponentId] }}</pre> -->

    <!-- <pre v-if="viewEntryCompatibleComponents"> viewEntryCompatibleComponents: {{ viewEntryCompatibleComponents }}</pre> -->
    <div v-if="selectedUiComponentId" class="right-sidebar__section">
        <div class="right-sidebar__section-header">Selected component</div>

        <div class="right-sidebar__section-item">
            <div class="textfield textfield-large textfield-filled">
                <div class="textfield-header">
                    <label for="select">Title</label>
                    <span class="textfield-counter">
                    </span>
                </div>
                <input v-model="selectedComponent.title" @blur="updateUiComponent({ title: $event.target.value.trim() })"
                    maxlength="50" type="text">
            </div>
        </div>

        <div class="right-sidebar__section-item">
            <div class="grouped-buttons">
                <div class="grouped-buttons--label">Width</div>
                <div class="grouped-buttons--content">
                        <button v-for="width in componentWidths" 
                                :key="width.size"
                                @click="updateUiComponent({ size: width.size })" 
                                :class="'btn btn-large btn-grouped-subtle ' + (selectedComponent.size === width.size ? 'btn-grouped-subtle--active' : '')"
                                type="button" 
                        >
                                {{ settings?.modalViewEnabled ? width.modal : width.card }}
                    </button>
                </div>
            </div>
        </div>

        <div v-if="linkableComponents?.length>0" class="select select-large select-filled">
            <label for="select"> {{ titleLinkWithComponent  }}</label>
            <select @change="updateUiComponent({ linked_component_id: parseInt($event.target.value) })">
                <option value="0" 
                        :selected="selectedComponent.linked_component_id == null"
                        >Unlinked
                </option>
                <option v-for="(item) in linkableComponents" 
                        :key="item.id" 
                        :value="item.id"
                        :selected="item.id == selectedComponent.linked_component_id"
                        >
                    [{{ item.id }}] {{ item.title }}
                </option>

            </select>
            <div class="error-msg js-hide">Error message</div>
        </div>

        <div v-if="selectedComponent?.type === 'data-table' && isLinkedWithDeleteEntryResource" class="switch switch-filled">
            <span class="switch-label">Show Delete Entry button</span>
            <label class="switch-controller">
                <input 
                    type="checkbox"
                    :checked="settings?.deleteEntryButtonEabled"
                    @change="updateSettings('deleteEntryButtonEabled', $event.target.checked)"
                >
                <span class="switch-slider"></span>
            </label>
        </div>

        <div v-if="selectedComponent?.type === 'form-item' || selectedComponent?.type === 'view-entry'" class="switch switch-filled">
            <span class="switch-label">View in modal</span>
            <label class="switch-controller">
                <input 
                    type="checkbox"
                    :checked="settings?.modalViewEnabled"
                    @change="updateSettings('modalViewEnabled', $event.target.checked)"
                >
                <span class="switch-slider"></span>
            </label>
        </div>



    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import GenericMixin from '@/mixins/genericMixin.js'

export default {
    name: 'SelectedComponent',
    mixins: [GenericMixin],
    data() {
        return {
            // uiComponents: this.userUiElements ? [this.userUiElements[this.selectedUiComponentId] || []
            componentWidths: [
                { 
                  size: 1,
                  modal: 'Auto',
                  card: 'Auto' 
                 },
                { 
                  size: 2,
                  modal: 'S',
                  card: '33%' 
                 },
                { 
                  size: 3,
                  modal: 'M',
                  card: '50%' 
                 },
                { 
                  size: 4,
                  modal: 'L',
                  card: '66%' 
                 },
                { 
                  size: 5,
                  modal: 'XL',
                  card: '75%' 
                 },
                { 
                  size: 6,
                  modal: 'Full',
                  card: 'Full' 
                 }
            ]
        }
    },
    computed: {
        ...mapState([
            'selectedPage', 
            'selectedUiComponentId', 
            'userUiComponents',
            'userApiResources',
            'userUiElements',
            'tableRowBindingView',
        ]),
        selectedComponent() {
            const component = this.userUiComponents?.find(component => component.id === this.selectedUiComponentId) || {};
            return component;
        },
        uiElements() {
            return this.userUiElements[this.selectedUiComponentId] || [];
        },
        resourceSelectedComponent() {
            const resourceId = this.uiElements[0]?.resource_id || null;
            return this.userApiResources?.find(resource => resource.id == resourceId) || {};
        },
        linkableResources() {
            return this.userApiResources?.filter(resource => resource?.link_with_resource_id == this.resourceIdSelectedComponent);
        },
        linkableComponents() {
            if(!this.userUiElements) return;

            const uniqueComponentIds = [];
            Object.values(this.userUiElements).forEach((items) => {
                if(!items) return;
            items.forEach((item) => {
                if (item.resource_id == this.resourceSelectedComponent?.link_with_resource_id
                    // && item.type == 'data-table' 
                    && !uniqueComponentIds.includes(item.component_id)) {
                    uniqueComponentIds.push(Number(item.component_id));
                }
                });
            });

            // Extract components with id in uniqueComponentIds
            const components = [];
            this.userUiComponents?.forEach((component) => {
                if (uniqueComponentIds.includes(component.id)) {
                    components.push(component);
                }
            });

            return components;
        },
        isLinkedWithDeleteEntryResource() {
            if(!this.selectedComponent || !this.resourceSelectedComponent) return false;
            return this.userApiResources?.find(item => item.link_with_resource_id == this.resourceSelectedComponent.id && item.method === 'delete') ? true : false;
        },
        settings() {
            return this.getComponentSettings(this.selectedComponent); // call 'getComponentSettings' from GenericMixin
        },
        titleLinkWithComponent() {
            if (this.componentOperationType === 'view') {
                return 'View entry from';
            } else if (this.componentOperationType === 'edit') {
                return 'Edit entry from';
            }  else if (this.componentOperationType === 'create' && this.settings?.modalViewEnabled) {
                return 'Add entry from';
            } else {
                return 'Auto-refresh with new entry';
            }
        },
        componentOperationType() {
            return this.getComponentOperationType(this.selectedComponent?.id); // call 'getComponentOperationType' from GenericMixin
        },
    },
    methods: {
        updateSettings(key, value) {
            let newSettings = {...this.settings};
            newSettings[key] = value;
            this.updateUiComponent({ settings: newSettings });
        },
        async updateUiComponent(updatedProps) {
            try {
                const data = {
                        id: this.selectedUiComponentId,
                        page_id: this.selectedPage.id,
                        ...updatedProps,
                    };
                // console.log('data', data)
                const response = await axios.put('/components.php', data);
                if (response.status === 200) {
                    // console.log(response);
                    await this.$store.dispatch('fetchUserUiComponents');
                } else {
                    console.log('Error: response status is not 200');
                }

            } catch (error) {
                console.log('Error:', error.message);
                console.log('Error:', error.respone);
                await this.$store.dispatch('fetchUserUiComponents');
            }
        }
    }
};

</script>