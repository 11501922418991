<template>
    <!-- <pre v-if="userApiResourcesFiltered">{{ userApiResourcesFiltered }}</pre> -->
    <!-- <pre v-if="resourceOfSelectedComponent">{{ resourceOfSelectedComponent }}</pre> -->
    <!-- <pre v-if="selectedApiService">{{ selectedApiService }}</pre> -->
    <!-- <pre v-if="selectedUserApiResource">{{ selectedUserApiResource }}</pre> -->
    <!-- <pre v-if="userApiResources">{{ userApiResources }}</pre> -->
    <!-- <pre>{{ userUiElements[selectedUiComponentId] }}</pre> -->
    <div class="right-sidebar__section-item"> 
        <div class="right-sidebar__section-subtitle">Resources</div>

        <div class="dropdown-wrapper">
            <div :class="'dropdown__select-box' 
                        + (componentHasElements ? ' dropdown__select-box--disabled' : '')
                        + (isDropdownActive ? ' js-btn-active' : '')" 
                        @click="toggleDropdown"
            >
                <ul>
                    <li v-if="selectedUserApiResource?.name" class="dropdown__item--title">
                        {{ selectedUserApiResource?.name }}
                    </li>
                    <li class="dropdown__item--line-2">
                        [<span class="capitalize">{{ selectedUserApiResource?.method }}</span>]
                        {{ selectedUserApiResource?.description }}
                    </li>
                    <li class="dropdown__item--line-3">/{{ selectedUserApiResource?.path }}</li>
                </ul>
                <div><i class="icon icon-chevron-down"></i></div>
            </div>
            <ul 
                v-if="userApiResourcesFiltered" 
                ref="dropdownList" 
                class="dropdown__list" 
                :class="{ 'js-show-dropdown': isDropdownActive }"
            >
                <li 
                    v-for="resource in userApiResourcesFiltered" 
                    :key="resource.id" 
                    :class="dropdownItemClass(resource)"
                    @click="setSelectedUserApiResource(resource)"
                >

                    <ul>
                        <li v-if="resource.name" class="dropdown__item--title">{{ resource.name }}</li>
                        <li class="dropdown__item--line-2">
                            [<span class="capitalize">{{ resource.method }}</span>]
                            {{ resource.description }}
                        </li>
                        <li class="dropdown__item--line-3">/{{ resource.path }}</li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

const USER_API_RESOURCES_URL = '/resources.php'

export default {
    name: 'UserApiResources',
    data() {
        return {
            resourceOfSelectedComponent: null
        }
    },
    watch: {
        selectedApiService(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    // console.log('selectedApiService changed from', oldValue, ' to ', newValue)
                    this.fetchUserApiResources()
                }
        },
        selectedUiComponentId: {
            immediate: true, // Run on component initialization
            handler(newValue, oldValue) {
                // console.log('selectedUiComponentId changed from', oldValue, ' to ', newValue)
                if (newValue && newValue !== oldValue) {
                    this.loadAllDependecies()
                    this.handleComponentTypeChange()
                }
            }
        },

    },
    computed: {
        ...mapState([
            'userApiResources',
            'userApiResourcesFiltered',
            'selectedApiService', 
            'selectedUserApiResource', 
            'selectedUiComponentId', 
            'userUiComponents', 
            'userUiElements',
            'dropdowns'
        ]),
        isDropdownActive() {
            return this.dropdowns[this.$options.name]
        },
        // resourceOfSelectedComponent() {
        //     if(!this.userUiElements || !this.userUiElements[this.selectedUiComponentId] || !this.userUiElements[this.selectedUiComponentId][0]) {
        //         return null
        //     }
        //     let $response = null;
        //         let $resource_id = this.userUiElements[this.selectedUiComponentId][0]['resource_id']
        //         if ($resource_id && this.userApiResourcesFiltered && this.userApiResourcesFiltered.length > 0) {
        //             $response = this.userApiResourcesFiltered.find(resource => resource.id == $resource_id)
        //         }
        //     return $response
        // },

        componentType() {
            if (!this.selectedUiComponentId) {
            return null;
            }
            const matchingObject = this.userUiComponents.find(obj => obj.id === this.selectedUiComponentId);
            if (matchingObject) {
            return matchingObject.type;
            } else {
            return null;
            }
        },
        resourcesFilteredByType() {
            const resources = this.$store.state.userApiResources;
            if (!resources || resources.length === 0 || !this.componentType) {
                return null;
            }
            let response = null;
            // console.log('resourcesFilteredByType', resources,'componentType', this.componentType)
            switch (this.componentType) {
                case 'data-table':
                response = resources.filter((obj) => obj.method === 'get' && obj.type === 'list');
                break;
                case 'form-item':
                response =  resources.filter((obj) => ['post', 'put', 'patch'].includes(obj.method));
                break;
                case 'view-entry':
                response =  resources.filter((obj) => obj.method === 'get' && obj.type === 'single');
                break;
            }
            // console.log('resourcesFilteredByType', response,'componentType', this.componentType)
            return response;
        },
        componentHasElements() {
            return this.userUiElements[this.selectedUiComponentId]?.length > 0
        },
        
    },
    methods: {
        async getResourceOfSelectedComponent() {
            await this.$nextTick()
            if(!this.userUiElements 
               || !this.userUiElements[this.selectedUiComponentId] 
               || !this.userUiElements[this.selectedUiComponentId][0] 
               || !this.userApiResourcesFiltered 
               || this.userApiResourcesFiltered.length === 0
            ) {
                return null
            }
            const resourceId = this.userUiElements[this.selectedUiComponentId][0]['resource_id']
            return this.resourceOfSelectedComponent = this.userApiResourcesFiltered.find(resource => resource.id == resourceId) || null
        },
        dropdownItemClass(resource) {
            if(!this.resourceOfSelectedComponent) {
                return `dropdown__item `;
            } else {
                const isSelected = this.isResourceSelected(resource.id)
                const isDisabled = this.resourceOfSelectedComponent 
                                   && resource.id !== this.resourceOfSelectedComponent.id 
                                   && this.userUiElements[this.selectedUiComponentId]?.length > 0;
                // const isDisabled = false;
                return `dropdown__item ${isSelected ? 'dropdown__item--selected' : ''} ${isDisabled ? 'dropdown__item--disabled' : ''}`
            }
        }, // userUiElements[selectedUiComponentId]
        isResourceSelected(id) {
            return this.selectedUserApiResource?.id === id
        },
        closeAllDropdowns() {
            for (const key in this.dropdowns) {
                if (key !== this.$options.name) {
                    this.$store.commit('setDropdownState', { id: key, value: false })
                }
            }
        },
        toggleDropdown(event) {
            event.stopPropagation()
            this.closeAllDropdowns()
            const state = this.isDropdownActive
            this.$store.commit('setDropdownState', { id: this.$options.name, value: !state })
            if (!state) {
                document.addEventListener('click', this.closeDropdown)
            }
            if (this.userApiResources.length>0) {
                this.$nextTick(() => {
                const selectedElement = this.$refs.dropdownList.querySelector('.dropdown__item--selected')
                if (selectedElement) {
                selectedElement.scrollIntoView({ block: 'nearest'})
                }
            })
            }
        },
        closeDropdown() {
            this.$store.commit('setDropdownState', { id: this.$options.name, value: false })
            document.removeEventListener('click', this.closeDropdown)
        },
        setSelectedUserApiResource(resource) {
            // console.trace()
            // console.log('setSelectedUserApiResource', resource)
            this.$store.commit('setSelectedUserApiResource', resource);
        },

        handleComponentTypeChange() {
            const resources = this.$store.state.userApiResources;
            if (resources && resources.length > 0) {
                    // console.log('selectedUiComponentId changed to', this.selectedUiComponentId, 'componentType', this.componentType)
                    // console.log(this.componentType)
                    if (this.componentType) {
                        // console.log('filtering resources by type', this.resourcesFilteredByType)
                        this.$store.commit('setUserApiResourcesFiltered', this.resourcesFilteredByType)
                        // console.log('filtered resources', this.$store.state.userApiResourcesFiltered)
                    }

                    this.$store.commit('setSelectedUserApiResource', this.$store.state.userApiResourcesFiltered[0])
                }
        },
        async fetchUserApiResources() {
            // console.log('fetching User Api Resources for connection_id', this.selectedApiService?.connection_id)
            try {
                const response = await axios.get(USER_API_RESOURCES_URL, {
                    params: {
                        connection_id: this.selectedApiService.connection_id
                    }
                });
                if (response.status === 200 && this.selectedApiService.connection_id === response.data[0].connection_id) {
                    // console.log('fetchUserApiResources response', response);
                    // console.trace()
                    // console.log('%c fetchUserApiResources response', 'color: yellow', response);
                    
                    this.$store.commit('setUserApiResources', response.data)

                    this.handleComponentTypeChange()
                }
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data)
                }
                // else {
                //     console.error(error)
                // }
            }
        },
        async loadAllDependecies() {
            const allDataLoaded = await Promise.all([
                this.selectedApiService,
                this.selectedUiComponentId,
                this.userApiResources,
                this.getResourceOfSelectedComponent(),
                this.userUiElements
            ].map(data => {
                if (Array.isArray(data)) {
                    return data.length > 0;
                } else {
                    return Boolean(data);
                }
            }));
            
            // console.log('DATA ALL', allDataLoaded)
            // console.log('resourceOfSelectedComponent', this.resourceOfSelectedComponent)

            if (allDataLoaded.every(Boolean)) {
                // console.log('allDataLoaded', allDataLoaded)
                if (this.resourceOfSelectedComponent && this.componentHasElements) {
                    this.setSelectedUserApiResource(this.resourceOfSelectedComponent);
                    // console.log('this.resourceOfSelectedComponent', this.resourceOfSelectedComponent)
                }
            }
        }
    },
    updated() {
        this.loadAllDependecies()
    },

}

</script>

<style scoped>
.capitalize {
    text-transform: uppercase;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>