<template>
    <div class="modal" tabindex="0">
        <div class="modal__content modal-width" ref="modalContent">
            <div class="modal__header">
                <h2 class="modal__title"> {{ modalContent.title }}</h2>
            </div>

            <div v-if="modalContent.text" class="modal__body modal__text-prompt">
                {{ modalContent.text }}
            </div>

            <div class="modal__action-buttons">
                <button @click="cancel" type="button" class="btn btn-large btn-text-subtle">
                    <span>{{ modalContent.cancelText }}</span>
                </button>
                <button @click="confirm" type="submit" class="btn btn-large btn-text-primary">
                    <span>{{ modalContent.confirmText }}</span>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import modalMixin from '@/mixins/modalMixin.js'

export default {
    mixins: [modalMixin],
    data() {
        return {
            // confirmAction: null,
        };
    },
    props: {
        modalContent: {
            type: Object,
            required: true,
            default: () => ({})
        },

    },
    methods: {
        keydownHandler(event) {
            switch (event.key) {
                case "Escape":
                    this.cancel();
                    break;
                // case "Enter":
                //     this.confirm();
                //     break;
            }
        },
        cancel() {
            this.$emit('confirm-action', false);
        },
        confirm() {
            this.$emit('confirm-action', true);
        }
    },
    mounted() {
        this.centerModal();
        window.addEventListener('resize', this.handleResize);  // call mixin method
        window.addEventListener("keydown", this.keydownHandler);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);  // call mixin method
        window.removeEventListener("keydown", this.keydownHandler);
    }
};

</script>

<style scoped>

.modal-width {
    width: 360px;
}
.error-msg {
    display: block;
}
.alert-error {
    margin-top:24px;
    margin-bottom: 24px;
}
</style>