import { mapState } from 'vuex'

export default {
    data() {
      return {
        //   prevHeight: 0
      }
    },
    computed: {
        ...mapState([
            'userUiElements',
        ]),
        },
    methods: {
        getComponentOperationType(componentId) {
            const elements = this.userUiElements[componentId];
            if (!elements || !elements.length) {
                return;
            }
            const requestMethod = elements[0]?.resource_method;
            switch (requestMethod) {
                case 'get':
                    return 'view';
                case 'put':
                    return 'edit';
                case 'post':
                    return 'create';
                default:
                    return null;
            }
        },
        getComponentSettings(component) {
            if (!component?.settings) return {};
            try {
              return JSON.parse(component.settings);
            } catch {
              return component.settings;
            }
          },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },          
    }
  }
  