<template>
  <div class="right-sidebar js-right-sidebar" ref="rightSidebar">

    <SectionSelectedElement />
    <SectionSelectedComponent />
    <SectionComponents v-if="!$store.state.selectedUiComponentId && $store.state.appPages.length" />
    <SectionData v-if="$store.state.selectedUiComponentId" />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import SectionSelectedElement from './sections/SelectedElement.vue'
import SectionSelectedComponent from './sections/SelectedComponent.vue'
import SectionComponents from './sections/Components.vue'
import SectionData from './sections/Data.vue'

export default {
  name: 'RightSidebar',
  components: {
    SectionSelectedElement,
    SectionSelectedComponent,
    SectionComponents,
    SectionData
  },
  computed: {
        ...mapState(['appPages'])
    },
}
</script>

<style>

</style>
