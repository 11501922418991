<template v-if="elementsCurrentComponent && componentData">
<!-- <pre v-if="componentData">componentData: {{ componentData }}</pre> -->
<!-- <pre v-if="userUiComponents">userUiComponents: {{ userUiComponents }}</pre> -->
<!-- <pre>externalApiData: {{ externalApiData }}</pre> -->
<!-- <pre v-if="tableRowBindingView">tableRowBindingView: {{ tableRowBindingView }}</pre> -->
<!-- <pre style="height: 200px; overflow-y: scroll;">binding:
    {{ binding }}
</pre> -->
<!-- <pre v-if="elementsCurrentComponent">{{ elementsCurrentComponent }}</pre> -->
<!-- <pre v-if="selectedUiElement">{{ selectedUiElement?.id }}</pre> -->
<!-- <pre v-if="externalApiHeaders">{{ externalApiHeaders }}</pre> -->
<!-- <pre v-if="modalData">{{ modalData }}</pre> -->
<!-- <pre>showModalPropertyDetails: {{ showModalPropertyDetails }}</pre> -->
<draggable 
    v-if="editMode || (selectedElementId && !editMode)" 
    v-model="elementsCurrentComponent" 
    ghost-class="ghost" 
    animation="300" 
    filter=".js-no-drag"
    :disabled="!editMode" 
    @change="handleReorder" 
    class="single-item-view"
    
>
    <transition-group type="transition" name="flip-list">
        
        <div v-for="element in elementsCurrentComponent" :key="element.id"
            @click="handleItemSelection($event, element)"
            :class="'single-item-view__item js-single-item-view-item' +
                (element.id === selectedUiElement?.id ? ' js-single-item-view-item-selected' : ' js-no-drag')" 
                ref="contentTable"
            >
            <div class="single-item-view__item__name" ref="indexCell">
                <div :title="element?.description">[{{ element?.id }}] {{ element?.display_name || element?.property_path }}</div></div>
            <div class="single-item-view__item__value" ref="valueCell">
                <div :value="value = getPropertyValue(element, null, this.externalApiData)" 
                    class="single-item-view__item__value__content">

                    <div v-if="!loading">
                        
                        <div v-if="element?.validation_rule?.format === 'image-list' && value.length > 0" class="image-gallery">
                            <div v-for="(imageUrl, index) in value" :key="index" class="image-gallery__item">
                                <img :src="imageUrl" alt="" @click="openModalPropertyDetails('image-gallery', element?.display_name, getPropertyValue(element, null, externalApiData), index)">
                            </div>
                            <div>
                                <span @click="openModalPropertyDetails('object-list', element?.display_name, getPropertyValue(element, 'return-objects', externalApiData))" class="link-open-modal">All {{ value.length }} items</span>
                            </div>
                        </div>

                        <div v-else-if="Array.isArray(value)">
                            <span v-if="value.length" @click="openModalPropertyDetails('object-list', element, getPropData(element, externalApiData,'return-objects'))" class="link-open-modal"> 
                                {{ value.length }} {{ value.length === 1 ? 'item' : 'items' }}
                            </span>
                            <span v-else class="data-table__cell--empty">-</span>
                        </div>
                        
                        <div v-else-if="element.data_type === 'object-array'" :get-values="valuesArray = getPropertyTypeArrayValue(externalApiData, element.property_path) ">
                            <span v-if="Array.isArray(valuesArray) && valuesArray.length && areAllValuesInArrayNotEmpty(valuesArray)" 
                                :get-preceded-value = "preceededValue = element?.validation_rule?.preceded_property_path ? getPropertyTypeArrayValue(externalApiData, element?.validation_rule?.preceded_property_path) : null"
                                >
                                <span v-for="(valueLevel1, index) in valuesArray" :key="index">
                                    <span v-if="preceededValue" class="data-table__cell--preced-value">
                                            {{ Array.isArray(preceededValue) ? preceededValue[index] + ': ' : '' }}
                                    </span>
                                    <span v-if="Array.isArray(valueLevel1)">
                                        <span v-for="(valueLevel2, index) in valueLevel1" :key="index" :get-value = "value = getHtmlElement(element?.validation_rule, valueLevel2)">
                                            <span v-if="value" v-html="value"></span>
                                            <span v-if="value && index < valueLevel1.length - 1" v-html="getDisplayListSeparator(element)"></span>
                                        </span><br />
                                    </span>
                                    <span v-else :get-value = "value = getHtmlElement(element?.validation_rule, valueLevel1)">
                                        <span v-if="value && element?.validation_rule?.format === 'image'" 
                                                @click="openModalPropertyDetails('image-gallery', element, getPropertyTypeArrayValue(externalApiData, element.property_path), index)" 
                                                v-html="value" 
                                                style="display:inline-block;"></span>
                                        <span v-else-if="shouldDisplayValue(value)" v-html="value" style="display:inline-block;"></span>
                                        <span v-if="value && index < valuesArray.length - 1" v-html="getDisplayListSeparator(element)"></span>
                                    </span>
                                </span>
                            </span>
                            <span v-else class="data-table__cell--empty">-</span>
                        </div>

                        <div v-else-if="element?.data_type === 'object' && value && Object.keys(value).length">
                            <div v-for="(value, key, index) in value" 
                                :key="key" 
                                class="single-item-view__item__value__content__object"
                                :get-property-value="propertyValue = getPropertyValueFromObject(element, key)"
                                >
                                <span v-if="name = propertyValue?.name" class="data-table__cell--index-value">{{ name }}: </span> 
                                <span v-if="value" :get-html-value="htmlValue = shouldDisplayValue(value) ? getHtmlElement(propertyValue, value) : '-'">
                                    <span v-if="propertyValue?.format === 'image'" 
                                        @click="openModalPropertyDetails('image-gallery', element, value)" 
                                        v-html="htmlValue"></span>
                                    <span v-else v-html="htmlValue"></span>
                                </span>
                                <span v-else class="data-table__cell--empty">-</span>
                                <span v-if="index < Object.keys(value).length - 1"><br /></span>
                            </div>
                        </div>

                        <div v-else-if="shouldDisplayValue(value)">
                            <div :get-html-value="htmlValue = getHtmlElement(element?.validation_rule, value)">
                                <span v-if="element?.validation_rule?.format === 'image'" 
                                    @click="openModalPropertyDetails('image-gallery', element, getPropertyValue(element, null, externalApiData))" 
                                    v-html="htmlValue"></span>
                                <span v-else v-html="htmlValue"></span>
                            </div>
                        </div>

                       <div v-else class="data-table__cell--empty">-</div>
                        <!-- <div v-else-if="shouldDisplayValue(value)" v-html="getHtmlElement(element?.validation_rule, value)"></div>
                        <div v-else class="data-table__cell--empty">-</div> -->
                    </div>
                    <div v-else>
                        <span class="loader-dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </div>

                </div>
            </div>
        </div>

    </transition-group>
</draggable>

<div v-if="!selectedElementId && !editMode && componentData?.linked_component_id" class="card__empty">
    <div class="card__empty__first-row"> 
        <span>Click on a row in</span>
        <b v-if="sourceComponentTitle">{{ sourceComponentTitle }}</b>
        <span>to view entry details here</span>
    </div>
</div>
        
<ModalPropertyDetails v-if="showModalPropertyDetails && showModalPropertyDetails[modalData?.element?.id]" 
    @closeModalPropertyDetails="this.$store.commit('setShowModalPropertyDetails', {elementId: modalData?.element?.id, stateValue: false} );" 
    :modal-data="modalData" 
/>

</template>

<script>
import { mapState } from 'vuex'
import { VueDraggableNext } from 'vue-draggable-next'
import axios from 'axios'
import ModalPropertyDetails from '@/components/PageContent/modals/PropertyDetailsModal.vue'
import FormUtilsMixin from '@/mixins/formUtilsMixin.js'
import TextFormattingMixin from '@/mixins/textFormattingMixin.js'


export default {
    name: 'SingleItemView',
    mixins: [FormUtilsMixin, TextFormattingMixin],
    components: {
        draggable: VueDraggableNext,
        ModalPropertyDetails,
    },
    props: {
        componentData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            showModal: false,
            modalData: {
                title: null,
                contentType: null,
                currItemIndex: null,
                data: null,
                element: null,
                component: null
            },
            selectedElementId: null,
            externalApiData: null,
            lastSelectedElementIdUpdate: null
        }
    },
    watch: {
        editMode: {
            immediate: true,
            handler: function (newVal) {
                if (!newVal) {
                    this.$store.commit('setSelectedUiElement', null);
                    document.removeEventListener('keydown', this.handleDeleteKeyDown);
                    document.removeEventListener('click', this.handleOutsideClick);
                }
            }
        },
        elementsCurrentComponent(newVal) {
            if (newVal.length > 0) {
                this.fetchExternalApiData();
            }
        },
        externalApiData(newVal) {
            // console.log('newVal', newVal)
            if (newVal) {

                this.loading = false;
            }
        },
        binding: {
            immediate: true,
            handler: function () {
                // console.log('SingleItemView: tableRowBindingView changed')
                if (this.binding) {
                    // Assiging temporary data ableit incomplete from binding until external api data is fetched
                    this.externalApiData = this.binding.linkedElementData;
                    // console.log('SingleItemView: tableRowBindingView changed: binding found')
                    // console.log('this.externalApiData', this.externalApiData)
                    
                    this.selectedElementId = this.binding.linkedElementData[this.linkingPropPath];
                    // console.log('this.selectedElementId', this.selectedElementId)
                    
                } 
                // else {
                //     this.selectedElementId = null;
                //     this.externalApiData = null;
                // }
            },
            deep: true
        },
        selectedElementId() {
            this.lastSelectedElementIdUpdate = Date.now();
            // Wait for 1 second before fetching external API data
            setTimeout(() => {
            // Only fetch external API data if the selectedElementId hasn't been updated in the last 1 second
                if (this.lastSelectedElementIdUpdate && Date.now() - this.lastSelectedElementIdUpdate >= 1000) {
                    this.fetchExternalApiData();
                }
            }, 1000);
        }
    },
    computed: {
        ...mapState([
            'userUiElements',
            'selectedUiElement',
            'tableRowBindingView',
            'userUiComponents',
            'userApiProperties',
            'editMode',
            'showModalPropertyDetails'
        ]),
        elementsCurrentComponent() {
            return this.userUiElements[this.componentData.id] || [];
        },
        binding() {
            return this.tableRowBindingView.find(component => 
            component.sourceComponentId == this.componentData?.linked_component_id
            && component.operation === 'view'
            );
        },
        linkingPropPath() {
            return this.componentData?.link_with_property_path;
        },
        sourceComponentTitle() {
            const component = this.userUiComponents?.find(component => component.id === this.componentData?.linked_component_id);
            return component?.title;
        },
    },
    methods: {
        openModalPropertyDetails(contentType, element, data, currItemIndex) {
            // console.log('contentType', contentType)
            // console.log('element', element)
            // console.log('data', data)
            // console.log('currItemIndex', currItemIndex)
            // console.log('this.componentData', this.componentData)
            this.$store.commit('setShowModalPropertyDetails', {elementId: element.id, stateValue: true} );
            // this.showModal = true;
            this.modalData.contentType = contentType;
            this.modalData.title = element.display_name;
            this.modalData.data = data;
            this.modalData.currItemIndex = currItemIndex || null;
            this.modalData.element = element;
            this.modalData.component = this.componentData;
        },
        handleItemSelection(event, item) {
            if (!this.$store.state.editMode) {
                return;
            }
            this.$store.commit('setSelectedUiElement', item);
            //set focus on the element
            const element = event.target.closest('.js-single-item-view-item');
            element.setAttribute('tabindex', '0');
            element.focus();
            // set focus again after a short delay to ensure focus is set
            setTimeout(() => {
                element.focus();
            }, 10);
            // console.log(document.activeElement)
            document.addEventListener('keydown', this.handleDeleteKeyDown);
        },
        openModalToConfirmDeletion() {
            if (this.selectedUiElement) {
                this.$store.commit('showModalDeleteUserUiElement', true);
            }
        },
        handleDeleteKeyDown(event) {
            const keys = ['Backspace', 'Delete'];
            const rightSidebar = event.target.closest('.right-sidebar');
            if (keys.includes(event.key) && !rightSidebar) {
                this.openModalToConfirmDeletion();
            }
        },
        handleOutsideClick(event) {
            if (!this.editMode) {
                return;
            }
            const element = event.target.closest('.js-single-item-view-item');
            const container = event.target.closest('.js-card-single-item-view');

            if(!element && container) {
                this.$store.commit('setSelectedUiElement', null);
            }
            
            if (!element) {
                document.removeEventListener('keydown', this.handleDeleteKeyDown);
            }
        },
        handleReorder(event) {
            let position = event.moved.newIndex + 1
            this.$store.dispatch('updateUiElement', { position: position});
        },
        async fetchExternalApiData() {
            // console.log('this.componentData', this.componentData)
            // console.log('this.selectedElementId', this.selectedElementId)
            if (!this.componentData || !this.selectedElementId) {
                return;
            }

            // Build a list of item ids to fetch from the external API
            let itemIds = '';
            let itemVariables = [...(this.binding?.parentItemVariables || [])]; // Create a copy of parentItemVariables
            itemVariables.push(this.selectedElementId);
            itemIds = itemVariables.join(',');


            const apiUrl = `/api_data.php?resource_id=${this.resourceId}&component_id=${this.componentData.id}&item_id=${itemIds}`;
            // console.log('SingleItemView: fetch data from apiUrl', apiUrl);
            await axios.get(apiUrl)
                .then(response => {
                    if (response.status === 200) {
                        // console.log("SingleItemView: externalApiData response", response.data.body);
                        
                        if (Array.isArray(response.data.body)) {
                            this.externalApiData = response.data.body[0];
                            } else {
                            // Handle the case where response.data.body is not an array or is empty
                            this.externalApiData = response.data.body;
                        }
                    // console.log('this.externalApiData', this.externalApiData)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response.data);
                    } else {
                        console.error(error);
                    }
                })

        }
    },
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);

        if (this.componentData) {
            this.$store.dispatch('fetchUserUiElements', { componentId: this.componentData.id });
        }

    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }
}

</script>

<style scoped>

.image-gallery {
    align-items: center;
}

.image-gallery__item img {
    object-fit: contain;
}

.link-open-modal {
    margin-left:2px;
}


</style>


