import { createStore } from 'vuex'
import axios from 'axios';

const store = createStore({
  state: {
    editMode: false,
    appPages: null,
    me: null,
    apiServices: null,
    userApiResources: null,
    userApiResourcesFiltered: null,
    userApiProperties: null,
    userUiComponents: null,
    userUiElements: {}, // was an array
    selectedPage: null,
    selectedUiComponentId: null,
    selectedUiComponent: null, // may not need this
    selectedUiElement: null,
    selectedApiService: null,
    selectedUserApiResource: null,
    refreshExternalDataInComponent: {},
    isDeletingExternalApiEntry: {},
    tableRowBindingView: [],
    showContextMenu: false,
    dropdowns: {},
    notifications: [],
    showModalDeletePage: false,
    showModalDeleteUserUiComponent: false,
    showModalDeleteUserUiElement: false,
    showModalDeleteExternalApiEntry: false,
    showModalAddRenamePage: false,
    showModalPropertyDetails: {},
    modalComponentData: null,
    formData: {},
    modalTargetPageId: null,
    keyListenerComponentId: null,
    navWidth: 200
  },
  mutations: {
    updateEditMode (state, value) {
      state.editMode = value;
    },
    setPages(state, pages) {
      state.appPages = pages;
    },
    setMe(state, me) {
      state.me = me;
    },
    setSelectedPage(state, page) {
      state.selectedPage = page;
      // console.log('setSelectedPage.id', page.id)
      // history.pushState({}, '', 'pages/' + page.slug);
    },
    setSelectedUiComponentId(state, id) {
      state.selectedUiComponentId = id;
    },
    setSelectedUiComponent(state, payload) {
      state.selectedUiComponent = payload;
    },
    setSelectedUiElement(state, payload) {
      // console.log('setSelectedUiElement', payload)
      state.selectedUiElement = payload;
    },
    setUserUiComponents(state, userUiComponents) {
      state.userUiComponents = userUiComponents;
    },
    setUserUiElements(state, { componentId, data }) {
      state.userUiElements[componentId] = data;
    },
    removeUserUiElements(state, componentId) {
      delete state.userUiElements[componentId];
    },
    setTableRowBindingView(state, { sourceComponentId, parentItemVariables, operation, linkedElementData }) {
      const index = state.tableRowBindingView.findIndex(binding => binding.sourceComponentId == sourceComponentId);
      if (index !== -1) {
        state.tableRowBindingView[index].linkedElementData = linkedElementData;
        state.tableRowBindingView[index].operation = operation;
        state.tableRowBindingView[index].sourceComponentId = sourceComponentId;
        state.tableRowBindingView[index].parentItemVariables = parentItemVariables;
      } else {
        state.tableRowBindingView.push({ sourceComponentId, parentItemVariables, operation, linkedElementData });
      }
    },
    removeTableRowBindingView(state, sourceComponentId) {
      const index = state.tableRowBindingView.findIndex(binding => binding.sourceComponentId == sourceComponentId);
      if (index !== -1) {
        state.tableRowBindingView.splice(index, 1);
      }
    },
    resetTableRowBindingView(state) {
      state.tableRowBindingView = [];
    },
    // setTableRowBindingView(state, { componentId, elementId }) {
    //   state.tableRowBindingView[componentId] = elementId;
    // },
    setSelectedApiService(state, payload) {
      state.selectedApiService = payload;
    },
    setSelectedUserApiResource(state, payload) {
      state.selectedUserApiResource = payload;
    },
    showContextMenu (state, payload) {
      state.showContextMenu = payload
    },
    showModalDeletePage (state, pageId) {
      state.showModalDeletePage = pageId
    },
    showModalDeleteUserUiComponent (state, id) {
      state.showModalDeleteUserUiComponent = id
    },
    showModalDeleteUserUiElement (state, id) {
      state.showModalDeleteUserUiElement = id
    },
    showModalDeleteExternalApiEntry (state, id) {
      state.showModalDeleteExternalApiEntry = id
    },
    showModalConfirmDelete(state, { componentId, id }) {
      state.isDeletingExternalApiEntry[componentId] = id;
    },
    showModalAddRenamePage (state, pageId) {
      state.showModalAddRenamePage = pageId
    },
    setShowModalPropertyDetails(state, { elementId, stateValue }) {
      if (stateValue === false) {
        delete state.showModalPropertyDetails[elementId];
      } else {
        state.showModalPropertyDetails = {
          ...state.showModalPropertyDetails,
          [elementId]: stateValue
        };
      }
    },
    setModalComponentData(state, component) {
      state.modalComponentData = component
    },
    setDropdownState(state, { id, value }) {
      state.dropdowns = {
        ...state.dropdowns,
        [id]: value
      }
    },
    addNotification(state, { componentId, type, title, message }) {
      const id = state.notifications.length + 1;
      state.notifications.push({
        id: state.notifications.length + 1,
        componentId,
        type,
        title,
        message,
      });
    
      if (type === 'success') {
        setTimeout(() => {
          this.commit('removeNotification', id);
        }, 5000);
      }
    },
    removeNotification(state, id) {
      state.notifications = state.notifications.filter(notification => notification.id !== id);
    },
    setApiServices(state, payload) {
      state.apiServices = payload;
    },
    setUserApiResources(state, payload) {
      state.userApiResources = payload;
    },
    setUserApiResourcesFiltered(state, payload) {
      state.userApiResourcesFiltered = payload;
    },
    setUserApiProperties(state, payload) {
      state.userApiProperties = payload;
    },
    setFormData(state, payload) {
      state.formData = payload;
    },
    modalTargetPageId (state, id) {
      state.modalTargetPageId = id
    },
    setKeyListenerComponentId (state, id) {
      state.keyListenerComponentId = id
    },
    setNavWidth (state, width) {
      state.navWidth = width
    },
    requestRefreshExternalDataInComponent(state, { componentId, value }) {
      // state.refreshExternalDataInComponent = componentId
      state.refreshExternalDataInComponent = {
        ...state.refreshExternalDataInComponent,
        [componentId]: value
      }
    },
    setIsDeletingExternalApiEntry(state, { componentId, value }) {
      // state.refreshExternalDataInComponent = componentId
      state.isDeletingExternalApiEntry = {
        ...state.isDeletingExternalApiEntry,
        [componentId]: value
      }
    }
  },
  actions: {
    async fetchPages({ commit, state }) {
      if (localStorage.getItem('token') === null) {
        return;
      }
      await axios.get('/pages.php')
        .then(response => {
          // console.log('Pages list', response.data);
          commit('setPages', response.data);
          if (response.data.length > 0) {
            // check if selectedPage is null or does not exist then set it to the first page
            if (state.selectedPage === null || !state.appPages.find(p => p.id === state.selectedPage.id)) {
              commit('setSelectedPage', response.data[0]);
            }
          }
          
        })
        .catch(error => {
          console.error(error.response);
        });
    },
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/me.php');
        // fetch pages
        commit('setMe', response.data);
      } catch (error) {
        console.error(error.response);
      }
    },    
    async loginAsUser({ dispatch }, userId) {
      try {
        await axios.get('/login_as.php', {
          params: {
            id: userId
          }
        });
        // console.log('Logged in as user with token:', localStorage.getItem('token'));
        // console.log('response', response);
        dispatch('fetchUser');
        dispatch('fetchPages');
      } catch (error) {
        console.error(error.response);
      }
    },    
    // Extract userName from token
    async fetchUserUiComponents({ commit, state }) {
      // console.log('requesting UI components for page ' + state.selectedPage.id + '...')
      const selectedPageId = state.selectedPage ? state.selectedPage.id : null;
      await axios.get('/components.php?page_id=' + parseInt(selectedPageId))
        .then(async response => {
          if (response.data.length > 0) {
            // console.log('userUiComponents', response.data);
            commit('setUserUiComponents', response.data);
            // await dispatch('fetchUserUiElements');
          } else {
            commit('setUserUiComponents', []);
          }
        })
        .catch(error => {
          console.error(error.response.data.message);
        });
    },
    async getUiComponentData({ commit, state }) {
      console.log('requesting UI component data');
      await axios.get('/components.php?id=' + state.selectedUiComponentId)
      .then(response => {
          if (response.status === 200) {
              console.log('selectedUiComponent', response.data);
              commit('selectedUiComponent', response.data);
              }
      })
      .catch(error => {
          if (error.response) {
              console.error(error.response.data);
          } else {
              console.error(error);
          }
      })
  },
    async deleteUserUiComponent({ commit, state, dispatch }) {
      if(state.selectedUiComponentId === null) {
        return;
      }
      // console.log('requesting deletion of UI component with id ' + state.selectedUiComponentId + '...');
        // Find the index of the item with the selectedUiComponentId
      const component = state.userUiComponents.find(component => component.id === state.selectedUiComponentId);
      const index = state.userUiComponents.findIndex(component => component.id === state.selectedUiComponentId);

      if (index > -1) {
        // Remove the item from the array
        state.userUiComponents.splice(index, 1);
      }
      await axios.delete('/components.php?id=' + state.selectedUiComponentId + '&page_id=' + state.selectedPage.id)
        .then(response => {
          if (response.status === 204) {
            // console.log('UI component deleted');
            // console.log(response);
            commit('setSelectedUiComponent', null);
            commit('setSelectedUiComponentId', null);
            dispatch('fetchUserUiComponents');
            commit('showModalDeleteUserUiComponent', null);
            commit('addNotification', { 
              componentId: component.id,
              type: 'success',
              title: `Component "${component.title}" deleted`, 
          });
          } 
        })
        .catch(error => {
          console.error(error.response.data.message);
          // const message = error.response.data?.message || error.response.data;
          commit('addNotification', { 
            componentId: component.id,
            type: 'error',
            title: 'Component deletion failed'
            // message: message
        });
        });
    },
    async fetchUserUiElements({ commit }, { componentId }) {
      // console.log('Requesting UI elements for component_id:', componentId)
      try {
        const response = await axios.get('/elements.php?component_id=' + componentId);
        if (response.data.length > 0) {
          // console.log('userUiElements', response.data);
          const data = response.data;

          commit('setUserUiElements', { componentId, data });

          // console.log('current payload', state.userUiElements);
        } else {
          commit('setUserUiElements', []);
        }
      } catch (error) {
        console.error('An error occurred while fetching UI elements:', error);
        commit('setUserUiElements', []);
      }
    },    
    async deleteUserUiElement({ commit, state, dispatch }) {
      if(state.selectedUiElement === null) {
        return;
      }
      // const selectedComponentId = state.selectedUiComponentId;
      // const selectedElementId = state.selectedUiElement.id ;
      const elementName = state.selectedUiElement.display_name;
      // console.log('requesting deletion of UI Element with id ' + state.selectedUiElement.id + ' and component ID ' + state.selectedUiComponentId);
        // Find the index of the item with the userUiElement.id
      const index = state.userUiElements[state.selectedUiComponentId].findIndex(element => element.id === state.selectedUiElement.id);
      // const updatedUiElements = state.userUiElements[selectedComponentId].filter(item => item.id !== selectedElementId);
      // commit('setUserUiElements', {selectedComponentId, userUiElements: updatedUiElements});

      if (index > -1) {
        // Remove the item from the array
        // console.log('index', index)
        state.userUiElements[state.selectedUiComponentId].splice(index, 1);
      }
      await axios.delete('/elements.php?id=' + state.selectedUiElement.id)
        .then(response => {
          if (response.status === 204) {
            // console.log('UI Element deleted');
            // console.log(response);
            if (index === 0) {
              // console.log('before removal', state.userUiElements)
              commit('removeUserUiElements', state.selectedUiComponentId);
              // console.log('removed component', state.userUiElements)
            }
            commit('setSelectedUiElement', null);
            // dispatch('fetchUserUiElements');
            dispatch('fetchUserUiElements', { componentId: state.selectedUiComponentId });
            commit('showModalDeleteUserUiElement', null);
            commit('addNotification', { 
              componentId: state.selectedUiComponentId,
              type: 'success',
              title: `Element "${elementName}" removed`, 
          });

          } 
        })
        .catch(error => {
          console.error(error.response.data.message);
          // const message = error.response.data?.message || error.response.data;
          commit('addNotification', { 
            componentId: state.selectedUiComponentId,
            type: 'error',
            title: 'Element deletion failed' 
            // message: message
        });
        });
      },
      async updateUiElement({ state, dispatch }, {position, userDisplayFormat = null }) {
        try {
            const response = await axios.put('/elements.php', {
                id: state.selectedUiElement?.id,
                position: position || state.selectedUiElement?.position,
                user_display_format: userDisplayFormat
            });
            if (response.status === 200) {
                // console.log(response);
                await dispatch('fetchUserUiElements', { componentId: state.selectedUiComponentId });
            } else {
                throw new Error('Error: response status is not 200');
            }
        } catch (error) {
            console.log(error.response);
            throw error;
        }
      },
  },
  getters: {
    selectedPageId: state => {
      return state.selectedPage ? state.selectedPage.id : null;
    }
  }
})

export default store

