
import axios from 'axios'
// import Vue from 'vue';
// import VueRouter from 'vue-router';

// Vue.use(VueRouter);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

function handleError(error) {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
    //   this.$router.push({ name: 'Login' })
        // window.location.href = '/login';
    }
  }

axios.interceptors.response.use(response => {
    let token = response.headers.authorization;
    if (token) {
        localStorage.setItem('token', token.replace("Bearer ", ""));
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
    return response;
  }, error => {
    handleError(error, this);
    return Promise.reject(error);
  });

if (localStorage.getItem('token')) {
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  }

export default axios;




