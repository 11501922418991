<template>
    <!-- <pre style="height: 200px; overflow-y: scroll;">Length: {{ infoPropertiesNotInComponent?.length }} {{ infoPropertiesNotInComponent }}</pre> -->
    <div class="right-sidebar__section-item">
        <div class="right-sidebar__section-subtitle">Attributes</div>

        <div class="textfield textfield-large textfield-filled textfield-search">
            <input v-model="searchTerm" @click="selectInputText($event)" placeholder="Search" type="text">
        </div>

        <div class="right-sidebar__section-block">
            <div class="right-sidebar__section-block--name">Properties</div>
            <div class="draggable-attribute--container">

            <div v-if="infoPropertiesNotInComponent?.length">
                <li v-for="item in infoPropertiesNotInComponent" :key="item.id" draggable="true" :data-index="item.id"
                    @dragstart="handleDragStart" class="draggable-attribute__item"
                    :class="'draggable-attribute__item--level-' + countItemsInPath(item.property_path) + ' ' + (isParent(item, index) ? 'draggable-attribute__item--parent' : '')">
                    <span class="draggable-attribute__item--title">
                        {{ item.display_name ? item.display_name : getLastItemInPath(item.property_path) }}
                    </span>
                    <span v-if="item.description" class="draggable-attribute__item--line-2"></span>
                    <span v-if="item.example_value" class="draggable-attribute__item--line-3"></span>
                </li>
            </div>
            <div v-else class="right-sidebar__empty-state">
                <span v-if="searchTerm">No results</span>
                <span v-else>All properties in use</span>
            </div>
       
            </div>
        </div>

    
    <div v-if="filterProperties.length > 0" class="right-sidebar__section-block">
        <div class="right-sidebar__section-block--name">Filters</div>
        <div class="draggable-attribute--container">

            <div v-if="filterPropertiesNotInComponent.length > 0">
                <li v-for="item in filterPropertiesNotInComponent" :key="item.id" draggable="true" :data-index="item.id"
                    @dragstart="handleDragStart" class="draggable-attribute__item"
                    :class="'draggable-attribute__item--level-' + countItemsInPath(item.property_path) + ' ' + (isParent(item, index) ? 'draggable-attribute__item--parent' : '')">
                    <span class="draggable-attribute__item--title">
                        {{ item.display_name ? item.display_name : getLastItemInPath(item.property_path) }}
                    </span>
                    <span v-if="item.description" class="draggable-attribute__item--line-2"></span>
                    <span v-if="item.example_value" class="draggable-attribute__item--line-3"></span>
                </li>
            </div>
            <div v-else class="right-sidebar__empty-state">
                <span v-if="searchTerm">No results</span>
                <span v-else>All filters in use</span>
            </div>
            
        </div>
    </div>

    <div v-if="paginationProperties.length > 0" class="right-sidebar__section-block">
        <div class="right-sidebar__section-block--name">Pagination</div>
        <div class="draggable-attribute--container">

            <div v-if="paginationPropertiesNotInComponent.length > 0">
                <li v-for="item in paginationPropertiesNotInComponent" :key="item.id" draggable="true" :data-index="item.id"
                    @dragstart="handleDragStart" class="draggable-attribute__item"
                    :class="'draggable-attribute__item--level-' + countItemsInPath(item.property_path) + ' ' + (isParent(item, index) ? 'draggable-attribute__item--parent' : '')">
                    <span class="draggable-attribute__item--title">
                        {{ item.display_name ? item.display_name : getLastItemInPath(item.property_path) }}
                    </span>
                    <span v-if="item.description" class="draggable-attribute__item--line-2"></span>
                    <span v-if="item.example_value" class="draggable-attribute__item--line-3"></span>
                </li>
            </div>
            <div v-else class="right-sidebar__empty-state">
                <span v-if="searchTerm">No results</span>
                <span v-else>All pagination controls in use</span>
            </div>
        </div>
    </div>

</div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

const RESOURCES_PROPERTIES_URL = '/properties.php'

export default {
    name: 'userApiProperties',
    data() {
        return {
            searchTerm: '',
        }
    },
    computed: {
        ...mapState([
            'userApiProperties', 
            'selectedUserApiResource', 
            'selectedApiService', 
            'selectedUiComponentId',
            'userUiElements'
        ]),
        propsAlreadyInComponent() {
            return this.userUiElements[this.selectedUiComponentId] 
        },
        infoProperties() {
            if (this.userApiProperties?.length) {
                return this.userApiProperties.filter(item => item.purpose === 'item_info' && item.display_name.toLowerCase().includes(this.searchTerm.toLowerCase()))
            }
            return []
        },
        infoPropertiesNotInComponent() {
            let propsInComponent = []
            if (this.propsAlreadyInComponent?.length) {
                propsInComponent = this.propsAlreadyInComponent
                    .filter(item => item.purpose === 'item_info')
                    .map(item => item.property_id);
            }

            if (this.infoProperties?.length) {
                return this.infoProperties
                    .filter(item => !propsInComponent.includes(item.id) && item.display_name.toLowerCase().includes(this.searchTerm.toLowerCase()));
            }
            return []
        },
        filterProperties() {
            if (this.userApiProperties && this.userApiProperties.length > 0 ) {
                return this.userApiProperties
                    .filter(item => item.purpose === 'filter')
            }
            return []
        },
        filterPropertiesNotInComponent() {
            let propsInComponent = []
            if (this.propsAlreadyInComponent && this.propsAlreadyInComponent.length > 0) {
                propsInComponent = this.propsAlreadyInComponent
                    .filter(item => item.purpose === 'filter')
                    .map(item => item.property_id);
            }

            if (this.filterProperties && this.filterProperties.length > 0 ) {
                return this.filterProperties
                    .filter(item => !propsInComponent.includes(item.id) && item.display_name.toLowerCase().includes(this.searchTerm.toLowerCase()));
            }
            return []
        },
        paginationProperties() {
            if (this.userApiProperties && this.userApiProperties.length > 0 ) {
                return this.userApiProperties
                    .filter(item => item.purpose === 'pagination')
            }
            return []
        },
        paginationPropertiesNotInComponent() {
            let propsInComponent = []
            if (this.propsAlreadyInComponent && this.propsAlreadyInComponent.length > 0) {
                propsInComponent = this.propsAlreadyInComponent
                    .filter(item => item.purpose === 'pagination')
                    .map(item => item.property_id);
            }

            if (this.paginationProperties && this.paginationProperties.length > 0 ) {
                return this.paginationProperties
                    .filter(item => !propsInComponent.includes(item.id) && item.display_name.toLowerCase().includes(this.searchTerm.toLowerCase()));
            }
            return []
        },
    },
    watch: {
        selectedUserApiResource: {
            immediate: true, // Run on component initialization
            handler() {
                if (this.selectedApiService && this.selectedUserApiResource) {
                    this.fetchUserApiProperties()
                }
            }
        },
        selectedUiComponentId: {
            immediate: true, // Run on component initialization
            handler() {
                this.searchTerm = ''
            }
        },
    },
    methods: {
        selectInputText(event) {
            event.target.select();
        },
        handleDragStart(event) {
            event.dataTransfer.setData('text/plain', event.target.dataset.index);
            event.dataTransfer.setData('element-type', 'api-property');
        },
        getLastItemInPath(path) {
            const pathArray = path.split(',')
            return pathArray[pathArray.length - 1]
        },
        countItemsInPath(path) {
            const pathArray = path.split(',')
            return pathArray.length
        },
        isParent(item, index) {
            if (this.$store.state.userApiProperties[index + 1]) {
                if (this.$store.state.userApiProperties[index + 1].property_path.split(',').length >
                    item.property_path.split(',').length) {
                    return true
                }
            }
            return false
        },
        async fetchUserApiProperties() {
            if (!this.selectedApiService && !this.selectedUserApiResource) {
                return
            }
            try {
                const response = await axios.get(RESOURCES_PROPERTIES_URL, {
                    params: {
                        connection_id: this.selectedApiService.connection_id,
                        resource_id: this.selectedUserApiResource.id
                    }
                });
                if (response.status === 200) {
                    // console.log('Properties list', response)
                    this.$store.commit('setUserApiProperties', response.data)
                }
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data)
                } else {
                    console.error(error)
                }
            }
        },
    },
    mounted() {
        // this.fetchUserApiProperties()
    }
}

</script>