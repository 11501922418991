import axios from 'axios'
import { DateTime } from 'luxon'
import { words } from 'lodash'
import validator from 'validator'
import { vMaska } from "maska"
import TextFormattingMixin from '@/mixins/textFormattingMixin.js'

const USER_API_RESOURCES_URL = '/resources.php'
const RESOURCES_PROPERTIES_URL = '/properties.php'

export default {
    mixins: [TextFormattingMixin],
    directives: { maska: vMaska },
    data() {
      return {
        formData: {},
        formNewValues: {},
        formValidationErrors: {},
        multiselectFilterSearchTerm: {},
        multiselectDisplaySelectedOnly: {},
        multiselectDrodpownOpen: null,
        externalApiLookupData: {},
        isLoadingExternalApiLookup: {},
        userApiResources: null,
        userApiProperties: null
      }
    },
    computed: {
        elementsCurrentComponent() {
            return this.userUiElements[this.componentData?.id] || [];
        },
        connectionId() {
            if (!this.elementsCurrentComponent?.length) return;
            return this.elementsCurrentComponent[0].connection_id;
        },
        resourceId() {
            if (!this.elementsCurrentComponent?.length) return;
            return this.elementsCurrentComponent[0].resource_id;
        },
        formType() {
            if(this.elementsCurrentComponent.length === 0) return null; 
            let output;
            switch(this.elementsCurrentComponent[0].resource_method) {
                case 'post':
                    output = 'create';
                    break;
                case ('put' || 'patch'):
                    output = 'edit';
                    break;
                case 'get':
                    output = 'filter';
                    break;
                default:
                    output = null;
                }
            return output;
            },
        },
    watch: {
        elementsCurrentComponent: {
            immediate: false,
            async handler(elements) {
                const apiElements = elements.filter(
                    element => element.data_type === 'api' 
                    && element?.validation_rule?.display_format !== 'item-count'
                    && element?.purpose !== 'filter'
                    );

                    // Group elements with the same lookup resource ID for calling the API only once
                    const groupedElements = apiElements.reduce((acc, element) => {
                    const lookupResourceId = element.validation_rule?.lookup_resource_id;
                    if (!lookupResourceId) {
                        return acc;
                    }
                    if (!acc[lookupResourceId]) {
                        acc[lookupResourceId] = [];
                    }
                    acc[lookupResourceId].push(element);
                    return acc;
                }, {});

                // console.log('groupedElements', groupedElements)

                // Populate the externalApiLookupData object with the data from the API
                if(Object.keys(groupedElements).length) {
                    for (let key in groupedElements) {
                        await this.fetchExternalApiLookup(groupedElements[key][0]);
                        const externalApiLookupData = this.externalApiLookupData[groupedElements[key][0].id];
                        groupedElements[key].forEach(element => {
                            this.externalApiLookupData[element.id] = externalApiLookupData;
                        })

                    }
                }

            }
        },
          
    },
    methods: {
        async fetchUserApiResources() {
            if(!this.connectionId) return;
            // console.log('fetching User Api Resources for connection_id', this.selectedApiService?.connection_id)
            try {
                const response = await axios.get(USER_API_RESOURCES_URL, {
                    params: {
                        connection_id: this.connectionId
                    }
                });
                if (response.status === 200 && this.connectionId === response.data[0].connection_id) {
                    // console.log('fetchUserApiResources response', response);
                    this.userApiResources = response.data;
                    this.fetchUserApiProperties();
                }
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data)
                }
                // else {
                //     console.error(error)
                // }
            }
        },
        async fetchUserApiProperties() {
            if (!this.connectionId && !this.resourceId) {
                return
            }
            try {
                const response = await axios.get(RESOURCES_PROPERTIES_URL, {
                    params: {
                        connection_id: this.connectionId,
                        resource_id: this.resourceId
                    }
                });
                if (response.status === 200) {
                    // console.log('Properties list', response)
                    this.userApiProperties = response.data
                }
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data)
                } else {
                    console.error(error)
                }
            }
        },
        setFormValue(element) {
            const dataType = element.data_type;
            const operationType = element.operation;
            const validationRule = element.validation_rule || {};

            let savedValue = this.getPropertyValue(element);
            let userValue = this.formNewValues[element.id] ?? null;
            let defaultValue = validationRule.default_value;
            
            let shouldUseSaved = this.formType === 'edit'
                && !userValue
                && !(["", false, 0].includes(userValue))
                && savedValue;

            let shouldUseDefault = this.formType === 'create'
                && !userValue
                && !(["", false, 0].includes(userValue))
                && defaultValue;
             
            let output = userValue ?? (shouldUseSaved ? savedValue : (shouldUseDefault ? defaultValue : null)) 

                if(operationType === 'input-date') {

                    const userInputFormat = validationRule.user_input_format?.replace(/'/g, '') || 'yyyy-MM-dd';
                    const submitFormat = validationRule.submit_format?.replace(/'/g, '') || 'yyyy-MM-ddTHH:mm:ss';
                    const inputDate = userValue ?? (shouldUseSaved ? savedValue : (shouldUseDefault ? defaultValue : null))

                    if (!inputDate) {
                        return;
                    } 
                    const inputFormat = userValue ? userInputFormat : submitFormat;
                    const inputIsIsoDate = this.isIsoDate(inputDate);

                    let luxonDate;

                    // Add support of relative date calculated from current time. Example of values:  {"days": 60, "hours": 12} or {"months": -1} or {"seconds": 0} (results in current day and time) 
                    if(typeof inputDate === 'object') {
                        // luxonDate = DateTime.local().plus(inputDate);
                        luxonDate =  this.calculateLuxonDate(inputDate);
                    } else if(inputIsIsoDate) {
                        luxonDate = DateTime.fromISO(inputDate, { zone: 'utc' });
                    } else {
                        luxonDate = DateTime.fromFormat(inputDate, inputFormat);
                    }

                    if (!luxonDate || !luxonDate.isValid) {
                        console.error('Invalid date format');
                        return output;
                    }

                    output = luxonDate.toFormat(userInputFormat);

                    if(shouldUseDefault) {
                        defaultValue = output;
                    }
                }

                if(operationType === 'input-number') {
                    const submitAsString = element.validation_rule?.submit_as_string;
                    defaultValue = submitAsString ? String(defaultValue) : parseFloat(defaultValue);
                }

                if(dataType === 'boolean' && defaultValue == undefined && !userValue && element?.user_display_format === 'switch' && this.formType === 'create') {
                    defaultValue = false;
                    shouldUseDefault = true;
                }

                if(shouldUseDefault) {
                    this.updateFormValues(defaultValue, element);
                }

                // if (userValue) {
                //     this.updateFormValues(userValue, element);
                //     this.getPropertyValue(element);
                //     return output;
                // } else if (savedValue) {
                //     this.updateFormValues(savedValue, element);
                //     return output;
                // } else if (defaultValue) {
                //     this.updateFormValues(defaultValue, element);
                //     return output;
                // } else {
                //     this.removeElementFromFormValues(element);
                // }

            return output ?? this.getPropertyValue(element);
        },
        areAllValuesInObjectEmpty(obj) {
            for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
                return false;
              }
            }
            return true;
        },
        areAllValuesInArrayNotEmpty(array) {
            return array.every(value => value !== null && value !== undefined && value !== '');
        },    
        populateApiLookupData() {
            // Handles the element types 'api' for which data is not retrieved via API point.
            // The fetchExternalApiLookup() method is used for the element types 'api' for which data is retrieved via API point.
            const elements = this.elementsCurrentComponent;
            const apiElements = elements.filter(element => element.data_type === 'api' && !element.validation_rule.lookup_resource_id);

            if (apiElements.length) {
              apiElements.forEach(element => {
                this.externalApiLookupData[element.id] = this.getPropertyValue(element) || [];

                // The calling of bellow method is a hack to force the update formNewValues. Otherwise, mysteriously externalApiLookupData gets updated on every unselect of list option.
                this.selectAllOptionsMultiselect(element);
              });
            }
        },
        async fetchExternalApiLookup(element, batchIdList = null) {
            const validationRule = element.validation_rule;
            const lookupTableResourceId = validationRule?.lookup_resource_id;

            if(!lookupTableResourceId) {
                return;
            }

            this.isLoadingExternalApiLookup[element.id] = true;

            // console.log('element', element)
        
            // Assign component based on whether the component is linked to another component or not. If linked, means the table is a child table
            const component = this.selectedElementId && element.purpose !== 'filter'
            ? this.userUiComponents.find(item => item.id == this.componentData?.linked_component_id) 
            : this.componentData;

            const requiredParameters = validationRule?.required_params;
            const additionalParams = Object.entries(requiredParameters || {})
                .filter(([key]) => Object.prototype.hasOwnProperty.call(requiredParameters, key))
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');
        
            const itemsPerPage = validationRule?.multi_page_preload?.items_per_page || Infinity;
            const maxPages = validationRule?.multi_page_preload?.max_pages || 1;
            const queryDelay = validationRule?.multi_page_preload?.query_delay || 0;
            const paginationParam = validationRule?.multi_page_preload?.pagination_param || "page";
            const batchRequestParams = (validationRule?.batch_request && batchIdList) ? this.getBatchRequestParams(element, batchIdList) : '';

            let urlParams = '';
            
            if(this.selectedElementId) {
                urlParams += '&item_id=' + this.selectedElementId;
                // this.externalApiData = [];
                this.isLoadingExternalData = !this.isLoadingExternalApiLookup[element.id];
                // console.log('fetching external data for element', element.display_name)
            }

            let apiUrl = `/api_data.php?component_id=${component.id}&lookup_resource_id=${lookupTableResourceId}${urlParams.toString()}`;

            if (batchRequestParams.length) {
                apiUrl += `&${batchRequestParams}`;
              }

            apiUrl += `&${additionalParams}`;

            let currentPage = 1;
        
            while (currentPage <= maxPages) {
                try {
                    const response = await axios.get(`${apiUrl}&${paginationParam}=${currentPage}`);

                    // console.log(`request for element ${element.id}: ${apiUrl}&${paginationParam}=${currentPage}`)
        
                    if (response.status === 200) {
                        if (!this.externalApiLookupData[element.id]) {
                            this.externalApiLookupData[element.id] = [];
                        }
                        this.externalApiLookupData[element.id] = this.externalApiLookupData[element.id].concat(response.data.body);
                        this.isLoadingExternalApiLookup[element.id] = false;
                        this.isLoadingExternalData = false;
        
                        if (response.data.body.length < itemsPerPage || currentPage >= maxPages) {
                            // Reached the last page, no need to send more requests
                            break;
                        }

                        if(this.selectedElementId) {
                            this.isLoadingExternalData = false;
                        }
        
                        currentPage++;
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        console.error(error.response.data);
                        // const message = error.response.data?.message || error.response.data;
                        let message = null;

                        if (typeof error.response.data.message === 'string' && error.response.data.message) {
                            message = error.response.data.message;
                        } 
                        this.$store.commit('addNotification', {
                            componentId: component.id,
                            type: 'error',
                            title: `Unable to load data in "${element.display_name}" field from "${component.title}" component`,
                            message: message
                        });
                    } else {
                        console.error(error);
                    }
                    break; // Stop fetching on error
                }
                await new Promise(resolve => setTimeout(resolve, queryDelay));
            }
        }, 
        getBatchRequestParams(element, batchIdList) {
            const batchRequest = element.validation_rule?.batch_request;
            let batchRequestParams = '';

            if (batchIdList && batchRequest) {
                const type = batchRequest.type;
                let parameter = batchRequest.parameter;
              
                if (!type) {
                  console.error('Missing type for batch request in element with ID ', element.id);
                  return;
                }
              
                if (!parameter) {
                  console.error('Missing parameter for batch request in element with ID ', element.id);
                  return;
                }
              
                switch (type) {
                  case 'comma-separated':
                    batchRequestParams = `${parameter}=${batchIdList.join(',')}`;
                    break;
              
                  case 'pipe-separated':
                    batchRequestParams = `${parameter}=${batchIdList.join('|')}`;
                    break;
              
                  case 'semicolon-separated':
                    batchRequestParams = `${parameter}=${batchIdList.join(';')}`;
                    break;
              
                  case 'array':
                    batchRequestParams = batchIdList.map((id) => `${parameter}[]=${id}`).join('&');
                    break;
              
                  case 'bracket-notation':
                    batchRequestParams = batchIdList.map((id, index) => `${parameter}[${index}]=${id}`).join('&');
                    break;
              
                  case 'multiple-parameters':
                    batchRequestParams = batchIdList.map((id) => `${parameter}=${id}`).join('&');
                    break;
              
                  default:
                    console.error('Invalid type for batch request in element with ID ', element.id);
                    return;
                }
              }

              return batchRequestParams;
        },               
        toggleMultiselectDropdown($event, element) {
            if (this.multiselectDrodpownOpen === element.id) {
                this.multiselectDrodpownOpen = null;
            } else {
                this.multiselectDrodpownOpen = element.id;
                if (!this.externalApiLookupData[element.id]) {
                    this.fetchExternalApiLookup(element);
                }
                document.addEventListener('click', this.handleOutsideMultiselectClick);
                document.addEventListener('keydown', this.handleEscapeKey);
            }
        },
        handleOutsideMultiselectClick(event) {
            if (this.multiselectDrodpownOpen && !event.target.closest('.multiselect__select-box, .multiselect__menu')) {
                this.closeDropdown();
            }
        },
        closeDropdown() {
            this.multiselectDrodpownOpen = null;
            this.multiselectFilterSearchTerm = {};
            this.currentPath = '';
            document.removeEventListener('click', this.handleOutsideMultiselectClick);
            document.removeEventListener('keydown', this.handleEscapeKey);
        },
        handleEscapeKey(event) {
            if (event.key === 'Escape') {
                this.closeDropdown();
            }
        },
        shouldDisplaySearchBar(element) {
            const lookupData = this.externalApiLookupData[element.id];
            const search = element.validation_rule?.search;
            const displayFromItemsCount = search?.display_from_items_count;
          
            return Boolean(search && displayFromItemsCount && lookupData?.length >= displayFromItemsCount);
        },
        getMultiselectPlaceholder(element, currValues) {
            if((element.data_type === 'array' && element.validation_rule?.enum)
               || (element.data_type === 'api' && !element.validation_rule?.lookup_resource_id) 
               || (element.id in this.externalApiLookupData)) {
                return this.getMultiselectDisplayValues(element, currValues);
            }
          
            return element.purpose !== 'filter' ? 'Loading...' : 'Select';
        },        
        getMultiselectDisplayValues(element, currValues) {
            const placeholder = element.validation_rule?.none_option?.name || 'Select';
            const validationRule = element.validation_rule;
            const arrayEnum = validationRule?.enum;
            const dataType = element.data_type;

            if (dataType === 'array' && arrayEnum) {
                const selectedProperties = this.formNewValues[element.id] || [];
                const defaultValue = validationRule?.default_value; 
                const noneOption = validationRule.none_option?.name;

                if(defaultValue && !this.formNewValues[element.id]) {
                    return arrayEnum[defaultValue] || placeholder;
                }

                if(noneOption && !this.formNewValues[element.id]) {
                    return noneOption || placeholder;
                }

                if(element.validation_rule?.max_items == 1) {
                    return arrayEnum[selectedProperties] || placeholder;
                }
                
                return selectedProperties.length > 0 
                    ? selectedProperties.map(item => arrayEnum[item]).join("; ")
                    : placeholder;
            }

            const itemDisplayProperty = element.validation_rule?.item_display_property;
            const itemKeyProperty = element.validation_rule?.key_property;
            const externalApiLookup = this.externalApiLookupData[element.id];

            if (!itemDisplayProperty || !itemKeyProperty) {
                console.error(`Missing ${!itemDisplayProperty ? 'item_display_property' : !itemKeyProperty ? 'key_property' : ''} for element with ID '${element.id}'`);
                return placeholder;
            }

            if(!externalApiLookup) {
                return placeholder;
            }

            if (Array.isArray(currValues) && currValues.length > 0 && validationRule?.submit_format) {
                const output = currValues
                .map(entry => {
                    const lookUpItem = externalApiLookup.find(item => item[itemKeyProperty] == entry[itemKeyProperty]);
                    const displayValue = lookUpItem ? lookUpItem[itemDisplayProperty] : null;
                    return displayValue;
                })
                .filter(value => value !== null)
                .join('; ');

                if (output.length > 0) {
                return this.convertHtmlEntities(output);
                }
            }

            if (Array.isArray(currValues) && currValues.length > 0) {
                const output = currValues
                .map(entry => {
                    const lookUpItem = externalApiLookup.find(item => item[itemKeyProperty] == entry);
                    return this.substitutePlaceholders(lookUpItem, itemDisplayProperty) || null;
                })
                .filter(value => value !== null)
                .join('; ');

                if (output.length > 0) {
                return this.convertHtmlEntities(output);
                }
            }

            if (dataType === 'api' 
                && validationRule?.max_items <= 1 
                && currValues && !Array.isArray(currValues)
                ) {
                const lookUpItem = externalApiLookup.find(item => item[itemKeyProperty] == currValues);    
                const displayValue = this.substitutePlaceholders(lookUpItem, itemDisplayProperty) || null;
                
                return this.convertHtmlEntities(displayValue) || placeholder;
                
            }

            return placeholder;
        },
        checkIsValueSelected(item, index, element, currValues) {
            const validationRule = element.validation_rule;
            const defaultValue = validationRule?.default_value;

            if(element.data_type === 'array' && validationRule?.max_items === 1) {

                if(defaultValue && !this.formNewValues[element.id]) {
                    return index == defaultValue;
                }
                return index == currValues;
            }

            if(element.data_type === 'array') {
                const value = validationRule.enum ? index : item;
                return ((Array.isArray(this.formNewValues[element.id]) && this.formNewValues[element.id].includes(value)
                        || Array.isArray(currValues) && currValues.includes(item))
                )
            }

            if(element.data_type === 'api' && currValues && !Array.isArray(currValues)) {
                const keyProperty = validationRule?.key_property;
                return item[keyProperty] == currValues;
            }

            if(element.data_type === 'api') {

                

                const keyProperty = validationRule?.key_property;
                if(!keyProperty) {
                    console.error('Missing key property for element with ID ', element.id);
                    return false;
                }
                return (Array.isArray(this.formNewValues[element.id]) 
                            && (this.formNewValues[element.id].some(entry => entry[keyProperty] == item[keyProperty])
                            || this.formNewValues[element.id].some(entry => entry == item[keyProperty]))
                        ) 
                        || (Array.isArray(currValues) && currValues.some(entry => entry[keyProperty] == item[keyProperty]))
            }
        },
        removeElementFromFormValues(element) {
            if (this.formNewValues[element.id]) {
                delete this.formNewValues[element.id];
              }
        },     
        filterLookupItems(element, currValues = null) {
            const elementId = element.id;
            const elementDataType = element.data_type;
            const propertyPath = element.property_path;
            const validationRule = element.validation_rule;
            const itemKeyProperty = validationRule?.key_property;
            const itemDisplayProperty = validationRule?.item_display_property;
            const hierarchical = validationRule?.hierarchical;
            const searchTerm = this.multiselectFilterSearchTerm[elementId] ? this.multiselectFilterSearchTerm[elementId].toLowerCase() : '';

            // console.log('this.formData[propertyPath]', this.formData[propertyPath], 'for element', element.display_name)
            // console.log('validationRule?.max_items', validationRule?.max_items, 'for element', element.display_name)
            // console.log('currValues', currValues, 'for element', element.display_name)

            const fetchedValue = this.formType === 'create' 
                                 ? (this.getPropertyValue(element) || []) 
                                 : (this.formData[propertyPath] 
                                    ? (validationRule?.max_items === 1 ? '' : [])
                                    : currValues
                                    );
            
            // console.log('fetchedValue', fetchedValue, 'for element', element.display_name)
            // console.log('currValues', currValues, 'for element', element.display_name)
                                    
            this.formNewValues[elementId] = this.formNewValues[elementId] || fetchedValue;

            const selectedItems = this.formNewValues[elementId] || [];

            const lookupData = (elementDataType === 'api' 
                                ? this.externalApiLookupData[elementId]
                                : validationRule?.enum) || [];

            let filteredData = lookupData;
            // console.log('filteredData', filteredData, 'for element', element.display_name)
            const isDisplaySelectedOnly = this.multiselectDisplaySelectedOnly[elementId];

            // Check if the lookup data is hierarchical and build the hierarchy
            if(hierarchical) {
                const propertyParent = hierarchical.property_parent;
                const propertyParentValue = hierarchical.property_parent_value;
                if(!propertyParent || !propertyParentValue) {
                    console.error('Missing hierarchical property parent or property parent value for element with ID ', element.id);
                    return filteredData;
                    
                }

                filteredData = this.buildHierarchy(filteredData, propertyParent, propertyParentValue);
                // console.log('hierarchy', filteredData)

            }


            
            if (isDisplaySelectedOnly) {
                const submitAsObject = validationRule?.submit_format && Object.keys(validationRule.submit_format).length > 0;
                filteredData = lookupData.filter(item => {
                    return selectedItems.some(selectedItem => 
                        (submitAsObject ? selectedItem[itemKeyProperty] : selectedItem)
                        == item[itemKeyProperty]);
                });
            }

            if (searchTerm.length && itemDisplayProperty) {
                filteredData = filteredData.filter(item => {
                    const substitutedPlaholders = this.substitutePlaceholders(item, itemDisplayProperty);
                    return substitutedPlaholders.toLowerCase().includes(searchTerm);
                });
            }



            return filteredData;

            // return lookupData.filter(item =>
            // this.getDisplayFormat(element, item).toLowerCase().includes(searchTerm)
            // );
        },
        buildHierarchy(jsonData, propertyParent, propertyParentValue, parentId = 0, depth = 0) {
            const hierarchy = [];
          
            for (const obj of jsonData) {
                if (obj[propertyParent] === parentId) {
                const newObj = { ...obj, depth };
                hierarchy.push(newObj);
                const children = this.buildHierarchy(jsonData, propertyParent, propertyParentValue, obj[propertyParentValue], depth + 1);
                hierarchy.push(...children);
              }
            }
          
            return hierarchy;
          },
    
        // getDisplayFormat(element, item) {
        //     if(element.data_type === 'array' && element.validation_rule?.enum) {
        //         return item;
        //     }

        //     const listOptionsDisplayFormat = element.validation_rule?.list_options_display_format;
        //     if(!listOptionsDisplayFormat) {
        //         console.error('Missing list options display format for element with ID ', element.id);
        //         return '';
        //     }
        //     const placeholders = listOptionsDisplayFormat.match(/%(\w+)%/g);
        //     let formattedDisplay = listOptionsDisplayFormat;

        //     if (placeholders) {
        //         placeholders.forEach((placeholder) => {
        //         const prop = placeholder.replace(/%/g, '');
        //         const value = item[prop];
        //         formattedDisplay = formattedDisplay.replace(placeholder, value);
        //         formattedDisplay = this.convertHtmlEntities(formattedDisplay);
        //         });
        //     }

        //     return formattedDisplay;
        // },
        getDisplayFormat(element, item) {
            if(element.data_type === 'array' && element.validation_rule?.enum) {
                return item;
            }

            const listOptionsDisplayFormat = element.validation_rule?.list_options_display_format;
            if(!listOptionsDisplayFormat) {
                console.error('Missing list options display format for element with ID ', element.id);
                return '';
            }

            return this.substitutePlaceholders(item, listOptionsDisplayFormat)

        },
        // substitutePlaceholders(item, itemDisplayProperty) {

        //     //  console.log('item', item, 'itemDisplayProperty', itemDisplayProperty)

        //     // Check if the item display property contains placeholders
        //     const placeholders = itemDisplayProperty.match(/%(\w+)%/g);
            
        //     if(!placeholders) {
        //         return item[itemDisplayProperty] || null;
        //     }
        //     let substitutedPlaholders = itemDisplayProperty;

        //     if (placeholders) {
        //         placeholders.forEach((placeholder) => {
        //         const prop = placeholder.replace(/%/g, '');
        //         const value = item[prop];
                
        //         //Check if value is an array of objects
        //         // if(Array.isArray(value) && value.length > 0 && typeof value[0] === 'object') {
        //         //     const valueDisplayProperty = placeholder.match(/%(\w+)%/)[1];
        //         //     const valueDisplayFormat = itemDisplayProperty.match(new RegExp(`${placeholder}(.*)`))[1];
        //         //     const valueDisplayFormatPlaceholders = valueDisplayFormat.match(/%(\w+)%/g);
        //         //     let valueDisplayFormatSubstituted = valueDisplayFormat;
        //         // }

        //         substitutedPlaholders = substitutedPlaholders.replace(placeholder, value);
        //         substitutedPlaholders = this.convertHtmlEntities(substitutedPlaholders);
        //         });
        //     }

        //     return substitutedPlaholders;
        // },

        // substitutePlaceholders(item, itemDisplayProperty) {
        //     // Check if the item display property contains placeholders
        //     const placeholders = itemDisplayProperty.match(/%\w+(?:\.\w+)*%/g);
          
        //     if (!placeholders) {
        //       return item[itemDisplayProperty] || null;
        //     }
          
        //     let substitutedPlaceholders = itemDisplayProperty;
          
        //     placeholders.forEach((placeholder) => {
        //       const propPath = placeholder.replace(/%/g, '').split('.');
        //       let value = item;
          
        //       for (const prop of propPath) {
        //         if (prop in value) {
        //           value = value[prop];
        //         } else {
        //           value = placeholder; // Placeholder is not found, use the original placeholder string
        //           break;
        //         }
        //       }
          
        //       if (typeof value === 'object' && value !== null) {
        //         value = this.substitutePlaceholders(value, placeholder); // Recursively substitute placeholders for nested objects
        //       }
          
        //       substitutedPlaceholders = substitutedPlaceholders.replace(placeholder, value);
        //       substitutedPlaceholders = this.convertHtmlEntities(substitutedPlaceholders);
        //     });
          
        //     return substitutedPlaceholders;
        //   },  
        
        substitutePlaceholders(item, itemDisplayProperty) {
            // Check if the item display property contains placeholders
            const placeholders = itemDisplayProperty.match(/%([^%]+)%/g);

            // console.log('placeholders', placeholders)
            // console.log('item', item)
            // console.log('itemDisplayProperty', itemDisplayProperty)

            if(!item) return null;

            
            if (!placeholders) {
              return item[itemDisplayProperty] ?? null;
            }
            
            let substitutedPlaceholders = itemDisplayProperty;

            // console.log('placeholders', placeholders)
          
            placeholders.forEach((placeholder) => {
              let prop = placeholder.replace(/%/g, '');
              // If prop contains a dot, it means that the value is nested in the object. Split the prop into an array and loop through each property
              const propPath = prop.split('.');

              

              if(propPath.length > 1) {
                prop = propPath[0];
              }

            //   console.log('prop', prop)

              let value = item[prop];

            //   console.log('propPath', propPath)

              

            //   console.log('value', value)
          
              if (Array.isArray(value)) {
                // If the property is an array, iterate over each object and substitute the placeholders
                value = value.map((obj) => {
                    // console.log('obj', obj)

                    const objectProps = propPath[1].match(/[^:]+/g);

                    // console.log('objectProps', objectProps)

                    if(objectProps.length > 1) {
                        return objectProps.map(prop => {
                            // console.log('property', prop)
                            const objDisplayProperty = this.substitutePlaceholders(obj, prop);
                            // console.log('objDisplayProperty', objDisplayProperty)
                            return objDisplayProperty;
                        }).join(': ');
                    }

                  const objDisplayProperty = this.substitutePlaceholders(obj, propPath[1]);
                  return objDisplayProperty;
                }).join(' · ');
              }

            //   console.log('value at the end', value)

              substitutedPlaceholders = substitutedPlaceholders.replace(placeholder, value);


            //   console.log('substitutedPlaceholders', substitutedPlaceholders)
              substitutedPlaceholders = this.convertHtmlEntities(substitutedPlaceholders);
            });
          
            return substitutedPlaceholders;
        },          
        getDataMaska(element) {
            const operationType = element.operation;
            const validationRule = element.validation_rule;
            
            let output = '';

            if (!validationRule) {
                console.error('Missing validation rule for element with ID ', element.id);
                return output;
            }

            if(operationType === 'input-number') {
                output = '0';

                if (validationRule.decimal) {
                const precision = parseInt(validationRule.decimal_precision) + 1 || 8; // use 7 decimal places by default
                const separator = validationRule.decimal_separator || "."; // use 7 decimal places by default
                output += separator.padEnd(precision, '9');
            }

                if(validationRule.negative) {
                    output = '-' + output;
                } 
            }

            if(operationType === 'input-date') {
                const userInputFormat = validationRule.user_input_format || 'yyyy-MM-dd';
                let maskedString = userInputFormat?.replace(/[a-zA-Z]/g, "#") || '';
                // console.log('maskedString', maskedString,'for element.id', element.id)
                output = maskedString;
            }

            // console.log('output', output)

            return output;
        },
        clearAllOptionsMultiselect(element) {
            delete this.formNewValues[element.id];
            if(this.formType === 'filter') {
                delete this.filterParams[element.property_path];
                delete this.filterParams[element.property_path + '[]'];
                delete this.formValidationErrors[element.id];
            }
            this.updateFormData([], element);
            this.multiselectDisplaySelectedOnly[element.id] = false
            this.validateFormInput(element);
        },
        selectAllOptionsMultiselect(element) {
            const { id, validation_rule } = element;
            const allOptions = this.externalApiLookupData[id];
            const keyProperty = validation_rule?.key_property;

            this.clearAllOptionsMultiselect(element)

            allOptions.forEach(item => {
                const entry = item[keyProperty]; 
                this.updateFormValues(entry, element, item);
            });
            this.validateFormInput(element)
        },
        updateFormData(value, element) {
            // console.log(value, element)

            const requiredDataType = element.data_type;
            const requiredOperationType = element.operation;
            const validationRule = element.validation_rule;

            if(requiredOperationType === 'input-date' && value !== '') {
                const userInputFormat = validationRule?.user_input_format || 'yyyy-MM-dd';
                const date = DateTime.fromFormat(value, userInputFormat);
                // console.log('date', date)
                if (!date.isValid) {
                    // console.error('Invalid date format');
                    return;
                }
                const submitFormat = validationRule?.submit_format || 'yyyy-MM-dd HH:mm:ss';
                const formattedDate = date.toFormat(submitFormat);

                // console.log('formattedDate', formattedDate)
                value = formattedDate;
            }

            if (requiredOperationType === 'input-number' && value !== '') {
                const validationRule = element.validation_rule;
                if (validationRule && validationRule.decimal_separator && validationRule.decimal_separator !== '.') {
                    value = value.replace(validationRule.decimal_separator, '.');
                }
                value = validationRule.submit_as_string ? String(value) : parseFloat(value);
            }

            // If the value is a string and data_type is boolean, convert the value to a boolean
            if (requiredDataType === 'boolean' && typeof value === 'string') {
                value = value === 'true';
            }

            if (requiredDataType === 'string' && requiredOperationType === 'input-date' && requiredOperationType === 'input-number') {
                value = value.trim();
            }

            const propertyPathArray = element.property_path.split(',');
            let formDataObj = this.formData;
            
            // Loop through each property path in the array except the last one
            for (let i = 0; i < propertyPathArray.length - 1; i++) {
                const propertyPath = propertyPathArray[i];
                // If the property doesn't exist in formData, create a new empty object
                if (!formDataObj[propertyPath]) {
                formDataObj[propertyPath] = {};
                }
                // Update formDataObj to be the nested object at the current property path
                formDataObj = formDataObj[propertyPath];
            }
            
            // Set the final property value to the new value
            formDataObj[propertyPathArray[propertyPathArray.length - 1]] = value;
        },
        multiselectHasNoResults(element) {

            const results = this.filterLookupItems(element);
          
            if (!results) {
              return true;
            }
          
            if (Array.isArray(results)) {
              return results.length === 0;
            }
          
            if (typeof results === 'object') {
              return Object.keys(results).length === 0;
            }
          
            return false;
        },          
        getMultiselectItemValue(item, index, element) {
            return element.data_type === 'array' ? index : item[element.validation_rule?.key_property];
        },
        updateFormValues(value, element, item = null) {
            const requiredDataType = element.data_type;
            const validationRule = element.validation_rule;
            const submitFormat = validationRule?.submit_format;

            // console.log('value', value, 'for element', element.display_name)

            if(value !== '' && submitFormat?.submit_values_as_integer) {
                value = parseInt(value);
            }
            
            if ((requiredDataType === 'array') && (validationRule?.min_items > 1 || validationRule?.max_items >= 1)) {
                let output;

                  // If the value is already an array (e.g., when set as a default value in validation rule) and comes from setFormValue() method,
                  // then use the value as it is.
                if ((value && Array.isArray(value)) || validationRule?.max_items <= 1) {
                    output = value;
                }
                else {
                     // Otherwise, form an array of selected items from the form when the default value got modified or is not set.

                    output = [];
                    let currValues = this.getPropertyValue(element);
                    currValues = currValues && Array.isArray(currValues) ? currValues : [];
                    const selectedItems = this.formNewValues[element.id] || currValues;
                    
                    const itemIndex = selectedItems.indexOf(value);
                    
                    if (itemIndex === -1) {
                        selectedItems.push(value);
                    } else {
                        selectedItems.splice(itemIndex, 1);
                    }

                    output = selectedItems;
                    
                }
                
                this.formNewValues[element.id] = output;
                this.updateFormData(this.formNewValues[element.id], element);
                
                return;    
            }

            if (requiredDataType === 'api') {
                let output = [];

                  // If the value is already an array (e.g., when set as a default value in validation rule) and comes from setFormValue() method,
                  // then use the value as it is.

                  // Also, if the multiselect is set to accept max one value, hence will be displayed as list of radio inputs, then use the value as it or convert to integer if submit format is set to 'integer'.

                if((value && Array.isArray(value)) || validationRule?.max_items <= 1) {
                    output = validationRule?.submit_values_as_integer ? parseInt(value) : value;
                } else if(item && Array.isArray(item)) {
                    output = item;
                } 
                else {
                     // Otherwise, form an array of selected items from the form when the default value got modified or is not set.
                    let currValues = this.getPropertyValue(element);
                    // const keyProperty = element.validation_rule?.key_property;
                    currValues = currValues && Array.isArray(currValues) ? currValues : [];

                    const selectedItems = this.formNewValues[element.id] || currValues;
                    const itemKeyProperty = validationRule?.key_property;

                    const formattedItem = {};
                    if (submitFormat && Object.keys(submitFormat).length) {
                        
                        for (const key in submitFormat) {
                            const format = submitFormat[key];
                            formattedItem[key] = format.replace(/%([^%]+)%/g, (_, prop) => {
                                return item[prop]
                            }
                            );
                        }
                    }
                    let option = Object.keys(formattedItem).length ? formattedItem : value;

                    if(option && validationRule?.submit_values_as_integer) {
                        option = parseInt(option);
                    }
                  
                    const itemIndex = Object.keys(formattedItem).length 
                                      ? selectedItems.findIndex(item => item[itemKeyProperty] == option[itemKeyProperty])
                                      : selectedItems.indexOf(option);

                    if (itemIndex === -1) {
                        selectedItems.push(option);
                    } else {
                        selectedItems.splice(itemIndex, 1);
                    }

                    // *** TEST to make sure the bellow line is redundant *** //
                    // this.externalApiLookupData[element.id] = this.getPropertyValue(element);

                    output = selectedItems;   
                    
                }
                
                this.formNewValues[element.id] = output;
                this.updateFormData(this.formNewValues[element.id], element);
                
                return;    
            }

            // console.log('value', value, 'for element', element.display_name)

            this.formNewValues[element.id] = value;
            this.updateFormData(value, element);
        },
        getPropertyTypeArrayValue(data, propertyPath) {
            if (!data) {
                return;
            }

            const pathArray = propertyPath?.split(',');
            // if(propertyPath == 'attributes')  console.log('pathArray.length', pathArray.length)
            // if(element.id == '3020' && data['id'] === 3011)  console.log('pathArray.length', pathArray.length)
           
            let result = data;
            let output = [];
            try {
                for (let i = 0; i < pathArray.length - 1; i++) {
                    result = result[pathArray[i]];
                    if (!result) {
                        break;
                    }

                    if(Array.isArray(result) && result?.length) {
                        const propertyPath = pathArray[pathArray.length - 1]
                        for(let j = 0; j < result.length; j++) {
                            output.push(result[j][propertyPath]);
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }

            return output.length ? output : null;
        },       
       
        validateFormInput(element) {
            const elementId = element.id;
            const oldValue = this.getPropertyValue(element) || null;
            const oldValueChanged = elementId in this.formNewValues;
            const newValue = this.formNewValues[elementId];

            const isRequired = element.required;
            const dataType = element.data_type;
            const operationType = element.operation;
            const displayName = element.display_name;
            const validationRule = element.validation_rule;

            if(!oldValueChanged && !isRequired) return;

            // console.log('---------------------------------')
            // console.log('display name: ', element.display_name);
            // console.log('oldValue: ', oldValue);
            // console.log('oldValueChanged:', oldValueChanged);
            // console.log('newValue: ', newValue)
            // console.log('isRequired: ', isRequired)

            // Validate required fields

            if((newValue === undefined || newValue === '') 
                && ((oldValue && oldValueChanged && isRequired) || (!oldValue && isRequired))) {

                this.formValidationErrors[elementId] = `'${displayName}' is required`;
                return;
            } else {
                delete this.formValidationErrors[elementId];
            }

            if(!validationRule || (oldValue && !oldValueChanged)) {
                return;
            }

            // Validate string type data format
            if(dataType === 'string' && operationType !== 'input-number' && operationType !== 'input-date') {
                            
                const format  = validationRule.format;
                const minChars = validationRule.min_chars;
                const maxChars = validationRule.max_chars;
                const minWords = validationRule.min_words;
                const maxWords = validationRule.max_words;

                // Validate alpha
                if (format === 'alpha' && !validator.isAlpha(newValue)) {
                    this.formValidationErrors[elementId] = `'${displayName}' must contain only letters`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                // Validate alphanumeric
                if (format === 'alphanumeric' && !validator.isAlphanumeric(newValue)) {
                    this.formValidationErrors[elementId] = `'${displayName}' must contain only letters and numbers`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                // Validate email
                if (format === 'email' && !validator.isEmail(newValue)) {
                    this.formValidationErrors[elementId] = `'${displayName}' must be a valid email`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                // Validate URL
                if (format === 'url' && !validator.isURL(newValue)) {
                    this.formValidationErrors[elementId] = `'${displayName}' must be a valid URL`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                // Validate min and max chars
                let countChars = 0;
                if(minChars || maxChars) {
                    countChars = newValue.length;
                }

                if (minChars && countChars < minChars) {
                    this.formValidationErrors[elementId] = `'${displayName}' must contain at least ${minChars} character${minChars > 1 ? 's' : ''}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                if (maxChars && countChars > maxChars) {
                    this.formValidationErrors[elementId] = `'${displayName}' must not exceed ${maxChars} character${maxChars > 1 ? 's' : ''}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                // Validate min and max words
                let countWords = 0;
                if(minWords || maxWords) {
                    countWords = words(newValue).length;
                }

                if (minWords && countWords < minWords) {

                    this.formValidationErrors[elementId] = `'${displayName}' must contain at least ${minWords} word${minWords > 1 ? 's' : ''}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }
                
                if (maxWords && countWords > maxWords) {
                    this.formValidationErrors[elementId] = `'${displayName}' must not exceed ${maxWords} word${maxWords > 1 ? 's' : ''}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

            }

            // Validate number type data format
            if(operationType === 'input-number') {

                const maxValue = validationRule.maximum;
                const minValue = validationRule.minimum;
                const inclusiveMinimum = validationRule.inclusive_minimum;
                const inclusiveMaximum = validationRule.inclusive_maximum;

                // Validate min and max values
                if (minValue && newValue < minValue && inclusiveMinimum) {
                    this.formValidationErrors[elementId] = `'${displayName}' must be greater than or equal to ${minValue}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                if (minValue && newValue <= minValue && !inclusiveMinimum) {
                    this.formValidationErrors[elementId] = `'${displayName}' must be greater than ${minValue}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                if (maxValue && newValue > maxValue && inclusiveMaximum) {
                    this.formValidationErrors[elementId] = `'${displayName}' must be lower than or equal to ${maxValue}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }
                
                if (maxValue && newValue >= maxValue && !inclusiveMaximum) {
                    this.formValidationErrors[elementId] = `'${displayName}' must be lower than ${maxValue}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }
            }

            // Validate number type data format
            if(operationType === 'input-date') {

                const userInputFormat = validationRule?.user_input_format || 'yyyy-MM-dd';
                const userInputDate = DateTime.fromISO((DateTime.fromFormat(newValue, userInputFormat, { zone: 'utc' })));

                if (!userInputDate.isValid && newValue !== '') {
                    this.formValidationErrors[elementId] = `'${displayName}' must be a valid date in format ${userInputFormat}`;
                    return;
                } else {
                    delete this.formValidationErrors[elementId];
                }

                // Validate min date and max date limits
                if(validationRule.limits && Object.keys(validationRule.limits).length) {
                    const limits = validationRule.limits;

                    // Validate min date
                    const minDate = limits?.min_date;

                    if(minDate) {
                        const minDateCalculated = this.calculateLuxonDate(minDate);
                        const inclusiveMinimum = limits?.inclusive_minimum;
                        const minDateInputFormat = minDateCalculated.toFormat(userInputFormat);

                        if (minDateCalculated && userInputDate < minDateCalculated && inclusiveMinimum) {
                            this.formValidationErrors[elementId] = `'${displayName}' must be after or equal to ${minDateInputFormat} (${minDateCalculated.zoneName})`;
                            return;
                        } else {
                            delete this.formValidationErrors[elementId];
                        }

                        if (minDateCalculated && userInputDate <= minDateCalculated && !inclusiveMinimum) {
                            this.formValidationErrors[elementId] = `'${displayName}' must be after ${minDateInputFormat} (${minDateCalculated.zoneName})`;
                            return;
                        } else {
                            delete this.formValidationErrors[elementId];
                        }
                    }

                    // Validate max date
                    const maxDate = limits.max_date || null;

                    if(maxDate) {
                        const maxDateCalculated = this.calculateLuxonDate(maxDate);

                        const inclusiveMaximum = limits?.inclusive_maximum;
                        const maxDateInputFormat = maxDateCalculated.toFormat(userInputFormat);

                        if (maxDateCalculated && userInputDate > maxDateCalculated && inclusiveMaximum) {
                            this.formValidationErrors[elementId] = `'${displayName}' must be before or equal to ${maxDateInputFormat} (${maxDateCalculated.zoneName})`;
                            return;
                        } else {
                            delete this.formValidationErrors[elementId];
                        }

                        if (maxDateCalculated && userInputDate >= maxDateCalculated && !inclusiveMaximum) {
                            this.formValidationErrors[elementId] = `'${displayName}' must be before ${maxDateInputFormat} (${maxDateCalculated.zoneName})`;
                            return;
                        } else {
                            delete this.formValidationErrors[elementId];
                        }
                    }

                } // end if limits
            }

            // Validate array type data format where multiple values are accepted
            if(((dataType === 'array' || dataType === 'api')) && (validationRule?.min_items > 0 || validationRule?.max_items > 0)) {

                const acceptedValues = validationRule.enum || {};
                const minItems = validationRule.min_items;
                const maxItems = validationRule.max_items;

                // Validate min and max items
                let countItems = 0;
                if((minItems || maxItems) && newValue) {
                    // console.log('newValue type for element name', displayName, ' is of type ', typeof newValue, ':', newValue)
                    countItems = Array.isArray(newValue) ? newValue.length : 1;
                    // console.log('countItems', countItems)

                    if (maxItems && countItems > maxItems) {
                        this.formValidationErrors[elementId] = `'${displayName}' must not exceed ${maxItems} item${maxItems > 1 ? 's' : ''}`;
                        return;
                    } else {
                        delete this.formValidationErrors[elementId];
                    }

                    if (minItems && countItems < minItems) {
                        this.formValidationErrors[elementId] = `'${displayName}' must contain at least ${minItems} item${minItems > 1 ? 's' : ''}`;
                        return;
                    } else {
                        delete this.formValidationErrors[elementId];
                    }
                }

                // Validate accepted values
                if (Object.keys(acceptedValues).length) {
                    const invalidValues = Array.isArray(newValue)
                        ? newValue.filter(item => !Object.keys(acceptedValues).includes(item))
                        : !Object.keys(acceptedValues).includes(newValue);
                    if (invalidValues.length) {
                        this.formValidationErrors[elementId] = `Invalid values: ${invalidValues.join(', ')}. '${displayName}' must contain only accepted values.`;
                        return;
                    } else {
                        delete this.formValidationErrors[elementId];
                    }
                }

            }

        },

        delayedCall(callback, delay) {
            setTimeout(callback, delay);
          },

        getPath(item, element, jsonData, path) {
            // console.log('item', item, 'element', element, 'jsonData', jsonData, 'path', path)
            const validationRule = element.validation_rule;
            const propertyParent = validationRule?.hierarchical?.property_parent;
            const propertyParentValue = validationRule?.hierarchical?.property_parent_value;
            const displayProperty = validationRule?.item_display_property;
            if(!propertyParent || !propertyParentValue || !displayProperty) {
                console.error('Missing hierarchical property parent, property parent value or display property for element with ID ', element.id);
                return;
            }

            if(!path) {
                path = item[displayProperty];
            }

            this.currentPath = this.convertHtmlEntities(path);
            if (!item || !jsonData) {
              return;
            }
          
            // Check if the current item is the root item (depth = 0)
            if (item.depth === 0) {
                // this.currentPath = item.name;
              return;
            }

            // Find the parent item recursively
            const parentItem = jsonData.find((data) => data[propertyParentValue] === item[propertyParent]);

            if (parentItem) {
              // Create a subset of jsonData containing only parent items
              const parentItems = jsonData.filter((data) => data.depth <= parentItem.depth);
          
              // Prepend the parent item's name to the path
              path = `${parentItem.name} › ${path}`;
              path = this.convertHtmlEntities(path);
          
              // Recursively call getPath with the parent item and parentItems subset
              this.getPath(parentItem, element, parentItems, path);
            }
        },
    },
    mounted() {
    },
    updated() {
    }
  }
  