<template>
    <!-- <pre style="max-height: 200px; overflow-y: scroll;">showModalPropertyDetails: {{ showModalPropertyDetails }}</pre>
    <pre style="max-height: 200px; overflow-y: scroll;">this.selectedUiElement: {{ selectedUiElement }}</pre> -->
    <!-- <pre style="max-height: 200px; overflow-y: scroll;">userUiElements: {{ userUiElements }}</pre> -->
    <!-- <pre style="max-height: 200px; overflow-y: scroll;">userUiComponents: {{ userUiComponents }}</pre> -->
    <!-- <pre style="max-height: 200px; overflow-y: scroll;">filteredUserUiComponents: {{ filteredUserUiComponents?.length }}</pre> -->
    <!-- <pre>componentsWithModalViewEnabled: {{ componentsWithModalViewEnabled }}</pre> -->
    <draggable 
        v-model="userUiComponents"
        ghost-class="ghost"
        animation="300" 
        filter=".js-no-drag" 
        :disabled="!editMode || Object.keys(showModalPropertyDetails).length"
        @change="handleReorder" 
        class="card-container"
    >
      <transition-group type="transition" name="flip-list">
        <div 
            v-for="component in userUiComponents" 
            :key="component.id" 
            :ref="'uiComponent-' + component.id"
            :class="cardClasses[component.id]"
            @click="handleCardSelection($event, component.id)" 
            @keydown.enter="handleCardSelection($event, component.id)"
            @dragover="handleDragOver" 
            @drop="handleDrop"
        >
          <button 
              v-if="component.type === 'data-table'" 
              @click="requestExternalDataRefresh(component.id)"
              type="button"
              :id="'btnRefresh'"
              class="btn-refresh"
              :class="'js-btn-refresh-' + component.id"
              :disabled="refreshExternalDataInComponent[component.id] || isDeletingExternalApiEntry[component.id]"
            >
            <i :class="'icon show icon-' + (refreshExternalDataInComponent[component.id] || isDeletingExternalApiEntry[component.id]  ? 'loader-circle' :'rotate' )"></i>
          </button>
          <div
              v-if="component.type === 'form-item' && refreshExternalDataInComponent[component.id]" 

              class="btn-refresh" 
              :class="'js-btn-refresh-' + component.id"

          >
            <i class="icon icon-loader-circle"></i>
          </div>
          <div class="card__label__container">
            <div class="card__label card__label--component-type">{{ getComponentName(component.type) }}: {{ component.id }}</div>
            <div v-if="componentsWithModalViewEnabled.includes(component.id)" class="card__label card__label--display-type">View in modal</div>
          </div>
          <div class="card__content">
            <div class="card__header" :class="{'card__header--data-table': component.type === 'data-table'}">
              <h1>{{ component.title }}</h1>
              <button v-if="component.type == 'data-table' && componentsWithModalViewEnabled?.includes(getLinkedComponentFormPost(component)?.id)"
                      @click="$store.commit('setModalComponentData', getLinkedComponentFormPost(component))" 
                      type="button" 
                      class="btn btn-small btn-text-primary">
                    <i class="icon icon-plus"></i>
                    <span>New entry</span>
              </button>
              <div v-if="component.link_with_resource_id" class="card__header--meta">
                <span v-if="component.linked_component_id" class="card__header--meta">
                  {{ getSubheaderText(component.id) }}
                  <span class="card__header--meta-title">
                    <span v-if="editMode">[{{ component.linked_component_id }}] </span>
                    "{{ getSourceComponentTitle(component.linked_component_id)}}"
                  </span>
                </span>
                <span v-else 
                    class="card__header--meta error" 
                    v-html="getMessageComponentNotLinked(component)"
                    >
                </span>
              </div>
            </div>
            <div v-if="!userUiElements[component.id]?.length" class="card__empty">
                  <h2 class="card__empty__title">No elements yet</h2>
                  <p>Add UI elements by dragging attributes from the Data panel.</p>
            </div>

              <DataTable v-if="component.type === 'data-table'" :component-data="component" />
              <SingleItemView v-if="component.type === 'view-entry'" :component-data="component" />
              <Form v-if="component.type === 'form-item'" :component-data="component" />



              <div v-if="(getComponentOperationType(component.id) === 'edit' || getComponentOperationType(component.id) === 'view')
                         && component.type != 'data-table' 
                         && !component.linked_component_id 
                         && !editMode"
                    class="card__empty"
              >
                  <p>Component not linked</p>
              </div>
              
              <!-- <CardForm v-if="component.type === 'form-item'" :component-data="component" /> -->
          </div>
        </div>
      </transition-group>
    
    </draggable>

    <ModalConfirmDelete v-if="showModalDeleteUserUiComponent || showModalDeleteUserUiElement" />

</template>
    
<script>
import { mapState } from 'vuex'
import axios from 'axios';
import { VueDraggableNext } from 'vue-draggable-next'
import GenericMixin from '@/mixins/genericMixin.js'
import ModalConfirmDelete from '@/components/PageContent/modals/ConfirmDeleteModal.vue'
import DataTable from '@/components/PageContent/card-types/DataTable.vue'
import SingleItemView from '@/components/PageContent/card-types/SingleItemView.vue'
import Form from '@/components/PageContent/card-types/Form.vue'

export default {
    name: 'Card',
    mixins: [GenericMixin],
    components: {
        draggable: VueDraggableNext,
        ModalConfirmDelete,
        DataTable,
        SingleItemView,
        Form,
    },
    data() {
    return {
        uiComponents: [
        {
          name: 'Data table',
          type: 'data-table',
          icon: 'icon-data-table'
        },
        {
          name: 'Form',
          type: 'form-item',
          icon: 'icon-form'
        },
        {
          name: 'Single item view',
          type: 'view-entry',
          icon: 'icon-single-item-view'
        }
      ]
    }
    },
    computed: {
    ...mapState([
        'selectedApiService',
        'selectedPage',  
        'selectedUserApiResource', 
        'userUiComponents',
        'userUiElements',
        'userApiProperties',
        'selectedUiComponentId',
        'selectedUiElement',
        'showModalDeleteUserUiComponent',
        'refreshExternalDataInComponent',
        'isDeletingExternalApiEntry',
        'showModalDeleteUserUiElement',
        'modalComponentData',
        'editMode',
        'showModalPropertyDetails',
    ]),
    componentsWithModalViewEnabled() {
      const components = this.userUiComponents?.filter(component => {
        if (!component.settings) {
          return false; // exclude components without settings
        }
        if (component.settings) {
          const settings = JSON.parse(component.settings);
          return settings?.modalViewEnabled; // include components where modalViewEnabled is true
        }
        return false;
      });
      // return an array of id values from the filtered components
      return components ? components.map(component => component.id) : [];
    },
    selectedComponentData() {
      return this.userUiComponents?.find(component => component.id === this.selectedUiComponentId) || {};
    },
    cardClasses() {
      const classes = {};
      this.userUiComponents.forEach((item) => {
        const { id, size, type } = item;
        const componentSettings = this.getComponentSettings(item);
        classes[id] = [
          'card',
          `${(componentSettings?.modalViewEnabled ? 'modal' : 'card')}-width-${size}`,
          'js-card',
          this.getClassByComponentType(type),
          this.selectedUiComponentId === id && 'js-card-selected',
          this.selectedUiElement?.id && 'js-no-drag',
          this.componentsWithModalViewEnabled.includes(id) && !this.editMode && 'card-hide'
        ].filter(Boolean).join(' ');
      });
      return classes;
    }
   },
    methods: {
      getMessageComponentNotLinked(component) {
        const operationType = this.getComponentOperationType(component.id);
        if (!operationType) {
          return;
        }
        const resourceName = component.link_with_resource_name || '[undefined resource]';
        return operationType === 'edit'
          ? `Link this component to <b>${resourceName}</b> data table from this page to enable editing of entries here`
          : `Link this component to <b>${resourceName}</b> data table from this page to enable viewing of entries here`;
      },
      getComponentSettings(component) {
        if (!component.settings) return null;
        return JSON.parse(component.settings);
      },
      getLinkedComponentFormPost(component) {
            if (this.userUiComponents?.length === 0 || !this.userUiElements || !Object.keys(this.userUiElements).length === 0 || !this.userUiElements[component.id]?.length) return null;
            const linkedFormTypeComponents = this.userUiComponents?.filter(item => item.linked_component_id == component.id && item.type === 'form-item');
            for (const component of linkedFormTypeComponents) {
                const elementsInLinkedComponent = this.userUiElements[component.id] || [];
                const firstElement = elementsInLinkedComponent[0] || null;
                // console.log('component.id: ', component.id, ', firstElement', firstElement)
                if (firstElement && firstElement.resource_method === 'post') {
                let settings = this.getComponentSettings(component);
                return {...component, settings}
                }
            }
                return null;
        },
      emulateClick() {
        const btn = document.getElementById('btnRefresh');
        console.log('btn', btn)
        btn?.click();
      },
    getUserUiElements(componentId) {
      return this.userUiElements[componentId] || null;
    },
    getSubheaderText(componentId) {
      const operationType = this.getComponentOperationType(componentId); // call from GenericMixin
      if (!operationType) {
        return;
      }
      switch (operationType) {
        case 'view':
          return 'View entry from';
        case 'edit':
          return 'Edit entry from';
        case 'create':
          return 'Add entry to';
        default:
          return null;
      }
    },
    requestExternalDataRefresh(componentId) {
      this.$store.commit('requestRefreshExternalDataInComponent', { componentId, value: true });
    },
    getSourceComponentTitle(componentId) {
      const component = this.userUiComponents.find(component => component.id === componentId);
      return component.title;
    },
    getClassByComponentType(type) {
      switch (type) {
        case 'data-table':
          return 'card--data-table js-card-data-table';
        case 'form-item':
          return 'card--form-components js-card-form-components';
        case 'view-entry':
          return 'card--single-item-view js-card-single-item-view';
        default:
          return '';
        }
      },
    handleDragEnter(event) {
        event.preventDefault();
        // event.stopPropagation();
        // console.log('drag enter')
        if(event.dataTransfer.types.includes('api-property')) {
            event.dataTransfer.dropEffect = 'copy';
        }
    },
    handleDragLeave(event) {
        event.preventDefault();
        // event.stopPropagation();
        // console.log('drag leave')
    },
    handleDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        const card = event.target.closest('.card');
        const dataTypes = event.dataTransfer.types;
        if(card && card.classList.contains('js-card-selected') && dataTypes.includes('element-type')) {
            event.dataTransfer.dropEffect = 'copy';
        } else {
            event.dataTransfer.dropEffect = 'move';
        }
    },
    handleDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        const id = event.dataTransfer.getData('text/plain');
        const type = event.dataTransfer.getData('element-type');
        event.dataTransfer.clearData();
        if (type !== 'api-property') {
            return;
        }
        // const property = this.userApiProperties[id];
        //find property in userApiProperties by id
        const property = this.userApiProperties?.find(prop => prop.id == id);
        // console.log('dropped item of type = ' + type + ':', property);
        // console.log('this.selectedUserApiResource', this.selectedUserApiResource);

        // this.$store.dispatch('addUserUiElement', property);
        if(property.resource_id === this.selectedUserApiResource?.id) {
            this.addUserUiElement(property);
          }
        else {
          this.$store.commit('addNotification', { 
                        componentId: this.selectedUiComponentId,
                        type: 'error',
                        title: 'Incompatible property',
                        message: `Property <b>${property.display_name}</b> is non compatible with <b>${this.selectedUserApiResource.name}</b> component. Select same-resource property.`
          });
          console.error(`Dropped element resource_id=${property.resource_id} not matching selected component resource.id=${this.selectedUserApiResource?.id}`);
          return;
        }

    },
    openModalDeleteUserUiComponent() {
      // console.log('Request openModalDeleteUserUiComponent')
      if (this.selectedUiComponentId) {
        this.$store.commit('showModalDeleteUserUiComponent', true);
      }
    },
    handleDeleteKeyDown(event) {
      const keys = ['Backspace', 'Delete'];
      const rightSidebar = event.target.closest('.right-sidebar');
      const elementInFocus = document.activeElement;
       // Check if the element in focus has the class
      if (elementInFocus.classList.contains('.right-sidebar')) return;
      // Check if the element in focus is a form element
      if (elementInFocus.tagName == "INPUT" 
          || elementInFocus.tagName == "SELECT" 
          || elementInFocus.tagName == "TEXTAREA") return;

      // console.log('rightSidebar', rightSidebar)
      if (keys.includes(event.key) && !rightSidebar && !this.selectedUiElement) {
        // console.log('delete key pressed')
        this.openModalDeleteUserUiComponent();
      }
    },
    async handleReorder(event) {
      // reposition the page immediately without waiting for the response from the API
      this.userUiComponents.splice(event.moved.newIndex, 0, this.userUiComponents.splice(event.moved.oldIndex, 1)[0])

      let position = event.moved.newIndex + 1
    //   this.updateUiComponent(position)
    try {
        await this.updateUiComponent(position)
        // console.log('reordered')
    } catch (error) {
        console.error(error)
    }
    },
    handleCardSelection(event, itemId) {
      if (!this.editMode) {
        return;
      }
      this.$store.commit('setSelectedUiComponentId', itemId);
      // set focus on the card
      // const element = event.target.closest('.card');
      //       element.setAttribute('tabindex', '0');
      //       element.focus();
      //       // set focus again after a short delay to ensure focus is set
      //       setTimeout(() => {
      //           element.focus();
      //       }, 10);
      // console.log(document.activeElement);
      document.addEventListener('keydown', this.handleDeleteKeyDown);
    },
    getComponentName(type) {
      const component = this.uiComponents.find(component => component.type === type);
      const name = component ? component.name : '';
      return name;
    },
    async updateUiComponent(position) {
      // console.log(`updateUiComponent with id: ${this.selectedUiComponentId}`);
      try {
        const response = await axios.put('/components.php', {
          id: this.selectedUiComponentId,
          page_id: this.selectedPage.id,
          position
        });
        if (response.status === 200) {
          console.log(response);
          await this.$store.dispatch('fetchUserUiComponents');
        } else {
          throw new Error('Error: response status is not 200');
        }
      } catch (error) {
        console.log(error.response);
        // console.log('Error: API call failed');
        throw error;
      }
    },
    async addUserUiElement(property) {
        // const { title, size } = component;
        // console.log('Requesting adding of Element with connection_id: '
        //             , this.selectedApiService?.connection_id
        //             , ', component_id: ', this.selectedPage.id
        //             , ', resource_id: ', this.selectedUserApiResource?.id
        //             , ', property_id: ', property.id
        //             );
        await axios.post('/elements.php', {
            connection_id: this.selectedApiService?.connection_id,
            component_id: this.selectedUiComponentId,
            resource_id: this.selectedUserApiResource?.id,
            property_id: property.id 
        })
            .then(response => {
            // console.log('Added UI element', response);
            // this.$store.dispatch('fetchUserUiElements');
            if (response.status === 201) {
              this.$store.dispatch('fetchUserUiElements', { componentId: this.selectedUiComponentId });
            } else {
                throw new Error('Error: response status is not 200');
            }
           
            })
            .catch(error => {
            console.log(error);
            });
    },
  }
}

</script>

<style scoped>

.card-hide {
    display: none;
}

/* .flip-list-move {
    transition: transform 0.3s;
} */
/* .sortable-drag {
    opacity: 0.1;
} */
</style>