<template>
  <div class="main-container" :class="{ 'js-edit-mode': $store.state.editMode }">
    <Header />
    <div class="main-content">
      <Navigation />
      <PageContent />
      <RightSidebar v-if="$store.state.editMode" />
    </div>
  </div>
</template>

<script>

import Header from '@/components/Header/Header.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import PageContent from '@/components/PageContent/PageContent.vue';
import RightSidebar from '@/components/RightSidebar/RightSidebar.vue';
import logoutMixin from '@/mixins/logoutMixin.js';
import { mapState } from 'vuex'

const EXPIRY_TIME = 60 * 60; // 60 minutes
const USER_ACTIVITY_EVENTS = ["mousemove", "click", "scroll", "keydown"];

export default {
  name: 'Home',
  mixins: [logoutMixin],
  data() {
    return {
      response: '',
      timer: null,
      interval: null,
      remainingTime: EXPIRY_TIME
    }
  },
  watch: {
    selectedUiComponentId(newValue) {
      if (newValue === null) {
        this.$store.commit('setSelectedUiElement', null);
      }
    }
  },
  components: {
    Header,
    Navigation,
    PageContent,
    RightSidebar
  },
  ...mapState([
            'selectedUiComponentId',
        ]),
  methods: {
    startTimer() {
      this.timer = setTimeout(() => {
        this.handleLogout(); // call logout mixin
      }, EXPIRY_TIME * 1000);
      this.interval = setInterval(() => {
        this.remainingTime -= 1;
      }, 1000);
    },
    resetTimer() {
      clearTimeout(this.timer);
      clearInterval(this.interval);
      this.remainingTime = EXPIRY_TIME;
      this.startTimer();
    }
  },
  mounted() {
    this.$store.dispatch('fetchUser'); // load user data
    this.$store.dispatch('fetchPages'); // load pages in navigation panel
    this.startTimer();
    USER_ACTIVITY_EVENTS.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    });
  },
  beforeUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.interval);
    USER_ACTIVITY_EVENTS.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    });
  }
}

</script>

<style>

</style>
