import { DateTime } from 'luxon'
import he from 'he';

export default {
    methods: {
        convertHtmlEntities(value) {
            if (typeof value === "number") {
                return value.toString();
              }
            if (typeof value !== 'string') {
            return value;
            }
            // Use he library to convert HTML entities to regular characters
            return he.decode(value);
            // const htmlEntities = {
            // '&amp;': '&',
            // '&lt;': '<',
            // '&gt;': '>',
            // '&quot;': '"',
            // '&#039;': "'",
            // '&bull;': '•'
            // // Add other HTML entities as needed
            // };
            // return str.replace(/&[\w\d#]+;/g, entity => {
            // return htmlEntities[entity] || entity;
            // });
        },
        selectAllInputText(event) {
            event.target.select();
        },
        isIsoDate(dateString) {
            const dateParsed = DateTime.fromISO(dateString);
            return dateParsed.isValid;
        },
        calculateLuxonDate(dateInput, originalFormat = null) {
            // if(!dateInput || !inputFormat) return;
            if(!dateInput) return;

            if (typeof dateInput === 'string') {
                let dateCalculated;
                const isIsoDate = this.isIsoDate(dateInput);

                if(isIsoDate) {
                    dateCalculated = DateTime.fromISO(dateInput, { zone: 'utc' });
                } else {
                    dateCalculated = DateTime.fromFormat(dateInput, originalFormat, { zone: 'utc' });
                }

                return dateCalculated;
            } else if (typeof dateInput === 'object' && dateInput !== null) {
                const dateCalculated = DateTime.local().plus(dateInput);
                return dateCalculated;
            } else {
                console.error('Invalid date input');
            }
        },
        convertDateFromLuxon(luxonDate, outputFormat) {
            if(!luxonDate) return;
            if(!outputFormat) {
                console.error('Missing output format');
                return;
            }
            if(!this.isValidLuxonDate(luxonDate)) {
                console.error('Invalid luxon date');
                return;
            }
            return luxonDate.toFormat(outputFormat);

        },
        isValidLuxonDate(luxonDate) {
            try {
              DateTime.fromISO(luxonDate);
              return true;
            } catch (error) {
              return false;
            }
        },
        removeHtmlTagSpaces(htmlString) {
        // Regular expression to match spaces between HTML tags
        const tagSpacesRegex = />\s+</g;
        
        // Check if the string contains HTML code
        if (/<[a-z][\s\S]*>/i.test(htmlString)) {
            // Replace spaces between HTML tags with no spaces
            htmlString = htmlString.replace(tagSpacesRegex, '><');
        }
        
        return htmlString;
        },
        removeEmptyTags(value) {
            const emptyTagRegex = /^<p><br><\/p>*$/;
            if (emptyTagRegex.test(value)) {
                return "";
            }
            return value;
        },
        getHtmlElement(validationRule, value) {
            const valueIsString = typeof value === 'string';
            const valueIsBoolean = typeof value === 'boolean';
            const valueIsObject = typeof value === 'object';

            //  If value is JSON object, display as preformatted text (temporary solution until implemented proper rendering of objects)
            if (valueIsObject) {
                return `<pre>${JSON.stringify(value, null, 2)}</pre>`;
            } 

            if (!valueIsString && !valueIsBoolean) {
                return value;
            }

            // const validationRule = element?.validation_rule;
            const format = validationRule?.format;
            const originalFormat = validationRule?.original_format;
            const displayFormat = validationRule?.display_format;

            if(valueIsBoolean) {
                if(value === true || value === 'true') {
                    return 'Yes';
                } else if (value === false || value === 'false') {
                    return 'No';
                }
            }

            if(valueIsString && format === 'date-time') {
                const luxonDate = this.calculateLuxonDate(value, originalFormat);

                if(this.isValidLuxonDate(luxonDate)) {
                    return luxonDate.toFormat(displayFormat);
                } else {
                    return value;
                }
            }

            // If value is an image URL, render it as an image
            // if (/^https?:\/\/.*\.(png|jpg|jpeg|gif|svg)$/i.test(value) || validationRule?.format === 'image') {
            if (format === 'image') {
                return `<img src="${value}" />`;
            }
            else if (format === 'email') {
                return `<a href="mailto:${value}" target="_blank">${value}</a>`;
            }
            else if (format === 'phone') {
                return `<a href="tel:${value}" target="_blank">${value}</a>`;
            }
            //  else if (value.match(/^https?:\/\//)) {
             else if (format === 'url') {
                return `<a href="${value}" target="_blank">${value}</a>`;
            } 
            // If value is plain text, display it as is
             else {
                // return this.capitalizeFirstLetter(value);
                return value;
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        isImgElement(value) {
            const imgRegex = /<img\b[^>]*>/i;
            return imgRegex.test(value);
        },
        getDisplayListSeparator(element) {
            const separator = element?.validation_rule?.display_list_separator;
            switch(separator) {
                case 'comma': return ', ';
                case 'semicolon': return '; ';
                case 'colon': return ': ';
                case 'dash': return ' - ';
                case 'dot': return ' · ';
                case 'pipe': return ' | ';
                case 'slash': return ' / ';
                case 'backslash': return ' \\ ';
                case 'newline': return '<br />';
                case 'space': return ' ';
                case 'nbsp': return '&nbsp;';
                case 'nbsp2': return '&nbsp;&nbsp;';
                case 'none': return;
                default: return ' · ';
            }

        },
        getPropData(element, item, outputType = null) {
            let result = item[element.property_path];
            try {
                // If the value is an array of objects, it will be displaed as list of objects in the modal
                if(Array.isArray(result) && outputType === 'return-objects') {
                    // console.log('return-objects', result)
                    return result;
                }
               // If value is a string, display it as HTML element
                else return this.getHtmlElement(element?.validation_rule, result)

            } catch (error) {
                console.error(error);
            } finally {
                // this.loading = false;
            }
        },
        getPropertyValue(element, outputType = null, item) {

            const data = item || this.externalApiData;
            // if(element.id == '3717') console.log('item', data)
            // this.loading = true;

            // if(element.id == '2585') console.log('item', data)
            // if(element.id == '2311') console.log('item', data)

            if (data === null || data === undefined) {
                return;
            }

            const pathArray = element.property_path.split(',');
            let result = data;

            try {
                for (let i = 0; i < pathArray.length; i++) {
                    result = result[pathArray[i]];
                    if (!result) {
                        break;
                    }
                }

                // if(element.id == '3717') console.log('result', result)

                // If the value is an array of objects, it will be displaed as list of objects in the modal
                if (Array.isArray(result) && outputType === 'return-objects') {
                    return result;
                }

                const validationRule = element?.validation_rule;
                const operationType = element?.operation; 
                const dataType = element?.data_type; 
                const lookupData = this.externalApiLookupData;


                if(dataType === 'api' && lookupData?.[element.id] && validationRule.display_format === 'single-item') {
                    const array = lookupData[element.id];
                   
                    const keyProperty = validationRule.key_property;
                    const displayValues = validationRule.item_display_property;
                    const item = array.find(item => item[keyProperty] == result);
                    const output = item ? this.substitutePlaceholders(item, displayValues) : result;
                    return output;
                }

                if(operationType === 'select' && validationRule?.enum) {
                    return result;
                }

                if(dataType === 'boolean' && operationType === 'set-boolean') {
                    return result;
                }

                if(operationType === 'input-number' && validationRule?.decimal_separator && validationRule?.decimal_separator === ',') {
                    result = result.replace(".", ',');
                }

                if(operationType === 'get_item_property_info' && validationRule?.enum) {
                    result = validationRule.enum[result] || result;
                }


                // If the value is an array of type 'image-list', it will be displaed as image slider in the modal
                if (element?.validation_rule?.format === 'image-list' &&
                    element?.validation_rule?.key_property &&
                    Array.isArray(result)
                    ) {
                        result = result.map(item => item[element.validation_rule.key_property]);
                        return result;
                    }
                // If value is a string, display it as HTML element
                else { 

                    result =  this.removeHtmlTagSpaces(result);
                    result = this.convertHtmlEntities(result);
                    // result = this.getHtmlElement(element?.validation_rule, result);
                    if(element.id == '3717') console.log('result', result)
                    return result;
                }

            } catch (error) {
                console.error(error);
            } finally {
                // this.loading = false;
            }
        },
        getPropertyValueFromObject(element, key) {
            const validationRule = element?.validation_rule;
            if(validationRule?.enum && validationRule?.enum[key]) {
                return validationRule.enum[key];
            } 
            // else {
            //     return key; 
            // }
        },
        shouldDisplayValue(value) {
            return value !== null && value !== '' && value !== undefined;
          },
        
    }
}