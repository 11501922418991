<template>
  <!-- <pre>userUiComponents: {{ userUiComponents }}</pre> -->
  <!-- <pre>componentOperationType: {{ componentOperationType }}</pre> -->
  <div
    class="page-content" 
    :style="pageContentStyles" 
    @dragover="handleDragOver" 
    @drop="handleDrop"
    @dragenter="handleDragEnter" 
    @dragleave="handleDragLeave"
  >

  <div v-if="userUiComponents?.length === 0" class="page-content__empty">
      <h2 class="page-content__empty-title">No components yet</h2>
      <p>Drag here UI components from the right panel to build your page.</p>
  </div>

    <Card />
    <Notification />

    <ModalDataShell v-if="modalComponentData" @closeModal="closeModal" :component-data="modalComponentData" />

    <ModalConfirmAction 
    v-if="showModalConfirmAction" 
    :modal-content=" {
       'title': 'Discard ' + (componentOperationType === 'create' ?  'new entry' : 'changes') +  '?',
        // 'text': 'Are you sure you want to delete this entry?',
        'confirmText': 'Discard',
        'cancelText': 'Cancel'
    }" 
    @confirm-action="(answer) => confirmAction(answer)" 
/>



  </div>



</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import GenericMixin from '@/mixins/genericMixin.js'
import Card from '@/components/PageContent/Card.vue'
import Notification from '@/components/PageContent/Notification.vue'
import ModalDataShell from '@/components/PageContent/modals/DataShellModal.vue'
import ModalConfirmAction from '@/components/PageContent/modals/ConfirmActionModal.vue'

export default {
  name: 'PageContent',
  mixins: [GenericMixin],
  components: {
    Card,
    Notification,
    ModalDataShell,
    ModalConfirmAction
  },
  data() {
    return {
      uiComponents: [
        {
          name: 'Data table',
          type: 'data-table',
          icon: 'icon-data-table'
        },
        {
          name: 'Form',
          type: 'form-item',
          icon: 'icon-form'
        },
        {
          name: 'Single item view',
          type: 'view-entry',
          icon: 'icon-single-item-view'
        }
      ],
      showModalConfirmAction: false,
    }
  },
  computed: {
    ...mapGetters(['selectedPageId']),
    ...mapState([
      'userUiComponents', 
      'selectedUiComponentId',
      'modalComponentData',
      'formData'
    ]),
    pageContentStyles() {
      const styles = {};
      if (this.$store.state.navWidth) {
        const navWidth = this.$store.state.navWidth;
        styles.marginLeft = `${navWidth}px`;
        styles.width = `calc(100% - ${navWidth}px`;
        styles.width += this.$store.state.editMode ? ' - 264px)' : ')';
      }
      return styles;
    },
    componentOperationType() {
      return this.getComponentOperationType(this.modalComponentData?.id); // calling for GenericMixin
    },
  },
  watch: {
    selectedPageId(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        // console.log('selectedPageId changed from: ', oldValue, ' to: ', newValue)
        this.$store.dispatch('fetchUserUiComponents');
      }
    },
  },
  methods: {
    confirmAction(answer) {
      if(answer) {
        this.$store.commit('setModalComponentData', null);
        this.showModalConfirmAction = false;
      } else {
        this.showModalConfirmAction = false;
      }
    },
    closeModal() {
          if(this.modalComponentData?.type === 'form-item' && Object.keys(this.formData).length) this.showModalConfirmAction = true;
          else this.$store.commit('setModalComponentData', null);
    },
    handleDragEnter(event) {
        event.preventDefault();
    },
    handleDragLeave(event) {
        event.preventDefault();
    },
    handleDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      const dataTypes = event.dataTransfer.types;
      if (dataTypes.includes('element-type')) {
        event.dataTransfer.dropEffect = 'none';
      }
    },
    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const index = event.dataTransfer.getData('text/plain');
      event.dataTransfer.clearData();
      const component = this.uiComponents[index];
      this.addUiComponent(component);
    },
    handleOutsideClick(event) {
      if (!this.$store.state.editMode) {
        return;
      }

      const selectedCardClassName = 'js-card-selected';
      const selectedElement = document.querySelector('.' + selectedCardClassName);

      if (selectedElement) {
        // const rightSidebar = event.target.closest('.js-right-sidebar__section--selected-component');
        const sectionSelectedComponent = event.target.closest('.js-right-sidebar');
        const modal = event.target.closest('.modal');
        if (!selectedElement.contains(event.target) && !sectionSelectedComponent && !modal) {
          this.$store.commit('setSelectedUiComponentId', null);
          document.removeEventListener('keydown', this.handleDeleteKeyDown);
        }
      }
    },
    getComponentName(type) {
      const component = this.uiComponents.find(component => component.type === type);
      const name = component ? component.name : '';
      return name;
    },
    async addUiComponent(component) {
      // console.log('addUiComponent with page_id: ', this.selectedPageId)
      try {
        const response = await axios.post('/components.php', {
          page_id: this.selectedPageId,
          title: this.getComponentName(component.type),
          type: component.type
        }
        );

        if (response.status === 201) {
          // console.log(response)
          await this.$store.dispatch('fetchUserUiComponents')
          this.setSelectedPage(response.data)
        } else {
          // console.log(response)
          console.error('Error: response status is not 201');
        }

      } catch (error) {
        this.apiErrorMessage = error.response
      }
    },
  },
  created() {
    if (this.selectedPageId) {
      this.$store.dispatch('fetchUserUiComponents');
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  updated() {
    // document.removeEventListener('keydown', this.handleDeleteKeyDown);
    if (!this.$store.state.editMode) {
      this.$store.commit('setSelectedUiComponentId', null);
    }

  },
}
</script>

<style>

</style>