<template>
    <div class="modal" tabindex="0" @keydown.enter="deleteItem">
        <div class="modal__content modal-width" ref="modalContent">
            <div class="modal__header">
                <h2 class="modal__title">{{ getModalTitle() }}</h2>
                <!-- <button @click="closeModal" type="button" class="btn btn-toggle">
                    <i class="icon icon-close"></i>
                </button> -->
            </div>

            <!-- <div class="modal__body modal__text-prompt">
                <p>Deleting the component will result in removal of its elements. External data used by the component will remain unaffected.</p>
            </div> -->

            <div class="modal__action-buttons">
                <button @click="closeModal" type="button" class="btn btn-large btn-text-subtle">
                    <span>Cancel</span>
                </button>
                <button @click="deleteItem" type="submit" class="btn btn-large btn-text-primary">
                    <span>{{ getModalActionBtnText() }} </span>
                </button>
            </div>

        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import modalMixin from '@/mixins/modalMixin.js'

export default {
    name: 'ConfirmDelete',
    mixins: [modalMixin],
    data() {
        return {
            showAlert: false,
        };
    },
    computed: {
    ...mapState([
        'showModalDeleteUserUiComponent',
        'showModalDeleteUserUiElement'
    ]),
    },
    methods: {
        keydownHandler(event) {
            switch (event.key) {
                case "Escape":
                    this.closeModal();
                    break;
                case "Enter":
                    // if(this.showModalDeleteUserUiElement)
                    this.deleteItem();
                    break;
            }
        },
        closeModal() {
            this.$store.commit('showModalDeleteUserUiComponent', false);
            this.$store.commit('showModalDeleteUserUiElement', false);
            this.showAlert = false
        },
        deleteItem() {
            // console.log('confirming deleteUiComponent...')
            if (this.showModalDeleteUserUiComponent && this.$store.state.selectedUiComponentId) {
                // console.log('requesting deleteUiComponent...')
                this.$store.dispatch('deleteUserUiComponent');
            }
            if (this.showModalDeleteUserUiElement && this.$store.state.selectedUiElement) {
                // console.log('requesting deleteUiElement...')
                this.$store.dispatch('deleteUserUiElement');
            }
        },
        getModalTitle() {
            if (this.showModalDeleteUserUiComponent) {
                return 'Delete component?'
            }
            if (this.showModalDeleteUserUiElement) {
                return 'Remove element?'
            }
        },
        getModalActionBtnText() {
            if (this.showModalDeleteUserUiComponent) {
                return 'Delete'
            }
            if (this.showModalDeleteUserUiElement) {
                return 'Remove'
            }
        },
    },
    mounted() {
        this.centerModal();
        window.addEventListener('resize', this.handleResize);  // call mixin method
        window.addEventListener("keydown", this.keydownHandler);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);  // call mixin method
        window.removeEventListener("keydown", this.keydownHandler);
    }
};

</script>

<style scoped>
.modal-width {
    width: 360px;
}
.modal__body {
    min-width: 320px;
}
.error-msg {
    display: block;
}
.alert-error {
    margin-top:24px;
    margin-bottom: 24px;
}
</style>